let breakpoints = {}

breakpoints.desktop = { start: 960 }
breakpoints.desktopMq = '(min-width:' + breakpoints.desktop.start + 'px)'
breakpoints.desktopNav = { start: 1320 }
breakpoints.desktopNavMq = '(min-width:' + breakpoints.desktopNav.start + 'px)'
breakpoints.tablet = { start: 550, finish: 959 }
breakpoints.tabletMq =
  '(min-width: ' +
  breakpoints.tablet.start +
  'px) and (max-width: ' +
  breakpoints.tablet.finish +
  'px)'
breakpoints.tabletNav = { start: 550, finish: 700 }
breakpoints.tabletNavMq =
  '(min-width: ' +
  breakpoints.tabletNav.start +
  'px) and (max-width: ' +
  breakpoints.tabletNav.finish +
  'px)'
breakpoints.mobile = { start: 0, finish: 549 }
breakpoints.mobileMq =
  '(min-width: ' +
  breakpoints.mobile.start +
  'px) and (max-width: ' +
  breakpoints.mobile.finish +
  'px)'

breakpoints.stickyNavMq = '(min-width: ' + breakpoints.tablet.start + 'px)'
export default breakpoints

breakpoints.actionListMq = '(max-width: ' + breakpoints.tablet.finish + 'px)'
