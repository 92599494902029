const platforms = {
  youtube: {
    name: 'YouTube Video',
    link: (id) => `https://www.youtube.com/watch?v=${id}`,
    embedUrl: (id) => `https://www.youtube-nocookie.com/embed/${id}`,
    isPlaylist: false,
  },
  youtubePlaylist: {
    name: 'YouTube Playlist',
    link: (id) => `https://www.youtube.com/playlist?list=${id}`,
    embedUrl: (id, autoPlay) =>
      `https://www.youtube-nocookie.com/embed/${id}${autoPlay ? '?autoplay=1' : ''}`,
    isPlaylist: true,
  },
  facebook: {
    name: 'Facebook Video',
    link: (id) => `https://www.facebook.com/watch?v=${id}`,
    embedUrl: (id) =>
      `https://www.facebook.com/v2.12/plugins/video.php?app_id=113869198637480&channel=https%3A%2F%2Fstaticxx.facebook.com%2Fconnect%2Fxd_arbiter%2Fr%2F2VRzCA39w_9.js%3Fversion%3D42%23cb%3Df123cae09000c3%26domain%3Ddevelopers.facebook.com%26origin%3Dhttps%253A%252F%252Fdevelopers.facebook.com%252Ffbedb85431a7a%26relation%3Dparent.parent&container_width=734&href=https%3A%2F%2Fwww.facebook.com%2Ffacebook%2Fvideos%2F${id}%2F&locale=en_US&sdk=joey&show_text=false&width=500`,
    isPlaylist: false,
  },
}

module.exports = platforms
