import React from 'react'

const HamburgerSvg = () => (
  <svg viewBox="0 0 24 12">
    <path
      d="M0 12h24v-2H0v2zm0-5h24V5H0v2zm0-7v2h24V0H0z"
      fill="#FFF"
      fillRule="evenodd"
    />
  </svg>
)

export default HamburgerSvg
