import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Svgs from '../../svgs/component/Svgs'
import Icon from '../../icon/component/Icon'

const LoadingSpinner = ({ hasDarkBackground, width, height, isSpinning }) => (
  <span
    className={classNames(
      'loading-spinner',
      { 'loading-spinner--dark-bg': hasDarkBackground },
      { 'is-spinning': isSpinning }
    )}
  >
    <Icon width={width} height={height}>
      <Svgs.Ball />
    </Icon>
  </span>
)

LoadingSpinner.propTypes = {
  hasDarkBackground: PropTypes.bool,
  isSpinning: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
}

export default LoadingSpinner
