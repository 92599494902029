import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
const baseClass = 'section-heading'

const SectionHeading = ({
  inverse,
  children,
  bottomMargin,
  bar,
  element = 'span',
  className,
}) => {
  const headingClassNames = classNames(
    baseClass,
    className,
    { 'section-heading--bottom-margin': bottomMargin },
    { 'section-heading--inverse': inverse },
    { 'section-heading--bar': bar }
  )

  const El = element

  return <El className={headingClassNames}>{children}</El>
}

SectionHeading.propTypes = {
  bottomMargin: PropTypes.bool,
  inverse: PropTypes.bool,
  bar: PropTypes.bool,
  element: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default SectionHeading
