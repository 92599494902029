import React from 'react'
import PropTypes from 'prop-types'
import Field from '../../primitive/field/component/Field'

const optionMap = (item) => ({ text: item.response, value: item.response })

const PollQuestion = (props) => {
  const { question, onChange, data = {}, error = {}, id } = props
  const hasResponses = question.responses && question.responses.length > 0
  const options = hasResponses
    ? question.responses.map((response) => optionMap(response))
    : null
  return (
    <Field
      onChange={onChange}
      name={`question-${id}`}
      value={data[`question-${id}`] || ''}
      error={error[`question-${id}`]}
      type={question.inputType}
      label={question.question}
      options={
        question.inputType === 'select'
          ? [ { text: '--- Please Select ---', value: '' }, ...options ]
          : options
      }
    />
  )
}

PollQuestion.propTypes = {
  question: PropTypes.object,
  onChange: PropTypes.func,
  data: PropTypes.object,
  error: PropTypes.object,
  id: PropTypes.number,
}

export default PollQuestion
