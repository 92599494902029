import React from 'react'

const SinBinSvg = () => (
  <svg data-name="Layer 1" width="17" height="17" viewBox="0 0 10 14">
    <path
      vectorEffect="non-scaling-stroke"
      d="M7.96 8.23v-7A1.31 1.31 0 0 0 6.64-.09H1.32A1.32 1.32 0 0 0 0 1.23v9.8a1.31 1.31 0 0 0 1.32 1.32h4a2.63 2.63 0 1 0 2.64-4.12zm-6.64 3.41a.56.56 0 0 1-.57-.57V1.31a.57.57 0 0 1 .57-.57h5.32a.57.57 0 0 1 .57.57v6.92a2.62 2.62 0 0 0-2.32 3.47H1.32zm6.05 1.28a2.132 2.132 0 0 1-.16-4.26h.16a2.155 2.155 0 0 1 0 4.31zm-.25-1.54a.25.25 0 0 1-.25-.25V9.49h.5v1.23l1-.67.27.42-1.41.92a.25.25 0 0 1-.11.04z"
      fill="#1e1e1e"
    />
  </svg>
)

export default SinBinSvg
