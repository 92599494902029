import React, { Fragment } from 'react'

const debug = require('debug')('form-submission-salesforce')

const create = ({
  Component,
  schema,
  formRef,
  data = {},
  props,
  onSuccess,
}) => {
  class Container extends React.Component {
    constructor(props) {
      super(props)
      this.onChange = this.onChange.bind(this)
      this.onSubmit = this.onSubmit.bind(this)
      this.setValues = this.setValues.bind(this)
      this.onSuccess = onSuccess
      this.state = {
        waiting: false,
        isValid: false,
        error: {},
        data: schema.makeDefault(data),
      }
    }

    getCheckBoxValues(e) {
      let values = this.state.data[e.currentTarget.name]
      if (!values) values = new Set()
      const newValue = e.currentTarget.value
      values.has(newValue) ? values.delete(newValue) : values.add(newValue)
      return values.size > 0 ? values : null
    }

    onChange(e) {
      debug('onchange', {
        ...this.state.data,
        [e.currentTarget.name]: e.currentTarget.value,
      })
      let targetValue
      e.target.type === 'checkbox'
        ? (targetValue = this.getCheckBoxValues(e))
        : (targetValue = e.currentTarget.value)

      this.setState(
        {
          data: { ...this.state.data, [e.currentTarget.name]: targetValue },
        },
        this.isValid
      )
    }

    validate(cb) {
      schema.validate(this.state.data, cb)
    }

    onSubmit(e) {
      if (!this.state.isValid) {
        e.preventDefault()
        this.validate((err, errors) => {
          if (err) {
            return alert(err)
          }
          if (Object.keys(errors).length > 0) {
            return this.setState({ waiting: false, error: errors })
          }
        })
      }
      // DO NOT DO ANYTHING THAT COULD RE-RENDER THE COMPONENT HERE e.g state updates
      // IT WILL BREAK RE-CAPTCHA
      return true
    }

    isValid() {
      this.validate((err, errors) => {
        if (err) {
          return alert(err)
        }
        this.setState({ isValid: Object.keys(errors).length === 0 })
      })
    }

    setValues(values) {
      this.setState(
        {
          data: { ...this.state.data, ...values },
        },
        this.isValid
      )
    }

    render() {
      return (
        <Fragment>
          <Component
            onChange={this.onChange}
            onSubmit={this.onSubmit}
            setValues={this.setValues}
            {...props}
            {...this.state}
          />
        </Fragment>
      )
    }
  }

  return Container
}

export default create
