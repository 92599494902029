import React from 'react'

const TwitterSvg = () => (
  <svg width="14" height="14" viewBox="0 0 14 14">
    <path
      d="M8.14856 5.925L13.2457 0H12.0378L7.612 5.14459L4.07709 0H0L5.34547 7.77954L0 13.9928H1.20792L5.88172 8.55994L9.61484 13.9928H13.6919L8.14826 5.925H8.14856ZM6.49414 7.84808L5.95253 7.07341L1.64316 0.909309H3.49846L6.97618 5.88394L7.51778 6.6586L12.0384 13.1249H10.1831L6.49414 7.84837V7.84808Z"
      fill="white"
    />
  </svg>
)

export default TwitterSvg
