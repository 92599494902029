import React from 'react'
import PropTypes from 'prop-types'
import ArticleHeader from '../../article-header/component/ArticleHeader'
import FormattedDate from '../../primitive/formatted-date/component/FormattedDate'
import Field from '../../primitive/field/component/Field'
import Button from '../../primitive/button/component/Button'
import LoadingSpinner from '../../primitive/loading-spinner/component/LoadingSpinner'
import GoogleMapReact from 'google-map-react'
import { fitBounds } from 'google-map-react/utils'
import Svgs from '../../primitive/svgs/component/Svgs'
const debug = require('debug')('rugby-camp-finder')

const RugbyCampList = ({ onRowClick, rugbyCamps = [], ...props }) => {
  if (rugbyCamps.length === 0)
    return <p>No rugby camps found that match your search.</p>
  return (
    <div className="rugby-camp-list">
      {rugbyCamps.map((rugbyCamp, i) => (
        <RugbyCampListItem
          index={i + 1}
          onClick={() => onRowClick && onRowClick(rugbyCamp)}
          key={`rugby-camp-row-${i}`}
          {...rugbyCamp}
        />
      ))}
    </div>
  )
}

const RugbyCampListItem = ({
  onClick,
  location,
  ageRange,
  addressLine1,
  postcode,
  startDate,
  endDate,
  cost,
  days,
  bookingLink,
  ctaLabel,
  index,
  description,
  campType
}) => (
  <div className="rugby-camp-list__item" data-index={index} onClick={onClick}>
    <ul className="list--unstyled">
      {(startDate && endDate) && (
        <li className="rugby-camp-list__date">
          <FormattedDate date={startDate} dateFormat="S" /> -{' '}
          <FormattedDate date={endDate} dateFormat="L" />
        </li>
      )}
      <li className="rugby-camp-list__location">{location}</li>
      <li>
        {addressLine1}, {postcode}
      </li>
      {(ageRange && days) && (
        <li>
          <ul className="list--unstyled list--inline list--pipe">
            <li>{ageRange}</li>
            <li>{days}</li>
          </ul>
        </li>
      )}
      {cost && <li className="rugby-camp-list__cost">{cost}</li>}
      <li>{description}</li>
      <li>{campType}</li>
    </ul>
    <div className="rugby-camp-list__book">
      {bookingLink ? (
        <Button
          isExternal
          ctaText={ctaLabel || 'More Info'}
          ctaLink={bookingLink}
          modifiers={[ 'primary' ]}
        />
      ) : (
        <p>Not available</p>
      )}
    </div>
  </div>
)

RugbyCampListItem.propTypes = {
  location: PropTypes.string.isRequired,
  ageRange: PropTypes.string.isRequired,
  addressLine1: PropTypes.string.isRequired,
  postcode: PropTypes.string.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  cost: PropTypes.string.isRequired,
  days: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  bookingLink: PropTypes.string,
  index: PropTypes.number,
  description: PropTypes.string,
  campType: PropTypes.string,
  ctaLabel: PropTypes.string
}

RugbyCampList.propTypes = {
  rugbyCamps: PropTypes.arrayOf(PropTypes.shape(RugbyCampListItem.propTypes))
    .isRequired,
  onRowClick: PropTypes.func,
}

const onSubmit = (e) => e.preventDefault()

const markerStyle = (scale) => ({
  transform: `translate(-40%, -100%) scale(${scale})`,
})

const findBounds = (markers) => {
  const ne = markers.reduce(
    (coord, marker) => {
      coord.lat = Math.max(coord.lat, marker.latitude)
      coord.lng = Math.max(coord.lng, marker.longitude)
      return coord
    },
    { lat: -Infinity, lng: -Infinity }
  )
  const nw = markers.reduce(
    (coord, marker) => {
      coord.lat = Math.max(coord.lat, marker.latitude)
      coord.lng = Math.min(coord.lng, marker.longitude)
      return coord
    },
    { lat: -Infinity, lng: Infinity }
  )
  const se = markers.reduce(
    (coord, marker) => {
      coord.lat = Math.min(coord.lat, marker.latitude)
      coord.lng = Math.max(coord.lng, marker.longitude)
      return coord
    },
    { lat: Infinity, lng: -Infinity }
  )
  const sw = markers.reduce(
    (coord, marker) => {
      coord.lat = Math.min(coord.lat, marker.latitude)
      coord.lng = Math.min(coord.lng, marker.longitude)
      return ne
    },
    { lat: Infinity, lng: Infinity }
  )
  return { ne, nw, se, sw }
}

const isSelected = (selected, marker) =>
  selected &&
  marker &&
  selected.ageRange === marker.ageRange &&
  selected.location === marker.location &&
  selected.latitude === marker.latitude &&
  selected.longitude === marker.longitude

class RugbyCampFinder extends React.Component {
  constructor(...props) {
    super(...props)

    this.onRowClick = this.props.onRowClick && this.props.onRowClick.bind(this)
    this.onChange = this.props.onChange && this.props.onChange.bind(this)
    this.state = {
      mapBounds: this.props.mapBounds,
    }
  }

  componentDidMount() {
    if (typeof document === 'undefined' || !document.querySelector) return
    // We can't see two markers in the same place to remove all on the same spot.
    this.markers = this.props.selected
      ? this.filteredRugbyCamps.filter(
          (camp) =>
            camp.latitude !== this.props.selected.latitude &&
            camp.latitude !== this.props.selected.latitude
        )
      : this.filteredRugbyCamps
    if (this.props.selected) this.markers.push(this.props.selected)
    const bounds = findBounds(this.markers)

    const width = document.querySelector('.rugby-camp-finder__map').clientWidth
    const height = document.querySelector('.rugby-camp-finder__map')
      .clientHeight
    debug(width, height)
    const mapBounds = fitBounds(bounds, { width, height })
    if (this.markers.length > 0 && mapBounds.zoom === 1) {
      mapBounds.zoom = 9
      mapBounds.center = {
        lat: this.markers[0].latitude,
        lng: this.markers[0].longitude,
      }
    }

    this.setState({ mapBounds })
  }

  render() {
    const { rugbyCamps = [], ageRange = 'All', finderType } = this.props
    this.filteredRugbyCamps =
      ageRange && ageRange !== 'All'
        ? rugbyCamps.filter((rugbyCamp) => rugbyCamp.ageRange === ageRange)
        : rugbyCamps
    return (
      <div className="rugby-camp-finder">
        <div className="rugby-camp-finder__map">
          {this.state.mapBounds && (
            <GoogleMapReact
              center={this.state.mapBounds.center}
              zoom={this.state.mapBounds.zoom - 1}
              bootstrapURLKeys={{
                key: 'AIzaSyDY4IwKGTJ5YuuJIPhUCciad-Ry-YYeHDM',
              }}
            >
              {this.markers.map((marker, i) => (
                <Marker
                  key={`camp-${i}`}
                  onClick={this.onRowClick}
                  selected={isSelected(this.props.selected, marker)}
                  lat={marker.latitude}
                  lng={marker.longitude}
                  rugbyCamp={marker}
                />
              ))}
            </GoogleMapReact>
          )}
        </div>
        <div className="rugby-camp-finder__form">
          <div className="rugby-camp-finder__form-header">
            <ArticleHeader category="Community" title={this.props.title} />
            {/* <p>Saints Rugby Camps are designed for players of all abilities between the ages of seven and sixteen.</p>
            <p>The camps offer an exciting opportunity for all young rugby players across the East of England to enjoy some fun, skills, drills and competitions – with coaching led by Northampton Saints community coaches and a selection of Saints first team and Academy players (when available).</p> */}
            <form
              className="form content-gutter"
              action=""
              method="get"
              onSubmit={onSubmit}
            >
              <Field
                name="postcode"
                type="text"
                label="Search for your closest location"
                placeholder="Postcode"
                autoComplete="off"
                onChange={this.onChange}
              />
              {finderType === 'camp' ? 
                (this.props.ageRanges && 
                  <Field
                    name="ageRange"
                    type="select"
                    label="Age Range"
                    options={this.props.ageRanges}
                    onChange={this.onChange}
                  />
                ) : (
                  <p>If you would like your club listed on our Club Finder, please contact <a href="mailto:community@northamptonsaints.co.uk">community@northamptonsaints.co.uk</a>.</p>
                )
              }
            </form>
          </div>
          <div className="rugby-camp-finder__results-summary">
            {this.props.waiting && (
              <p>
                <LoadingSpinner isSpinning width={20} height={20} />
              </p>
            )}
            {this.props.error && <p>{this.props.error}.</p>}
          </div>
          <RugbyCampList
            rugbyCamps={this.filteredRugbyCamps}
            onRowClick={this.onRowClick}
          />
        </div>
      </div>
    )
  }
}

const Marker = ({ onClick, selected, rugbyCamp }) => {
  const { addressLine1, postcode, location, ageRange, bookingLink } = rugbyCamp
  return (
    <div
      className="rugby-camp-finder__marker"
      onClick={() => onClick(rugbyCamp)}
    >
      <Svgs.Marker style={markerStyle(selected ? 1 : 0.7)} />
      {selected && location && (
        <div className="rugby-camp-finder__marker-text" style={{ zIndex: 1 }}>
          <ul className="list--unstyled">
            <li>{location}</li>
            <li>{ageRange}</li>
            <li>{addressLine1},</li>
            <li>{postcode}</li>
          </ul>
          <Button
            ctaText="More Info"
            isExternal
            ctaLink={bookingLink}
            modifiers={[ 'primary', 'block' ]}
          />
        </div>
      )}
    </div>
  )
}

Marker.propTypes = {
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  rugbyCamp: PropTypes.object.isRequired,
}

RugbyCampFinder.propTypes = {
  selected: PropTypes.object,
  onRowClick: PropTypes.func,
  onChange: PropTypes.func,
  rugbyCamps: PropTypes.arrayOf(PropTypes.shape(RugbyCampListItem.propTypes))
    .isRequired,
  mapBounds: PropTypes.object,
  ageRanges: PropTypes.array,
  ageRange: PropTypes.string,
  error: PropTypes.string,
  waiting: PropTypes.bool,
  title: PropTypes.string,
  finderType: PropTypes.string
}

export default RugbyCampFinder
