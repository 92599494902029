import React from 'react'
import PropTypes from 'prop-types'
import LoadingSpinner from '../../primitive/loading-spinner/component/LoadingSpinner'
import FormBuilderQuestion from './FormBuilderQuestion'

const FormBuilder = ({
  onSubmit,
  onChange,
  data = {},
  error = {},
  waiting,
  successMessage,
  errorMessage,
  form,
}) => {
  const { questions, ctaText, description } = form

  return (
    <div className="form-builder content-center content-center--narrow">
      <h2 className="form-builder__heading">{form.title}</h2>
      {successMessage ? (
        <div
          className="notification notification--success"
          dangerouslySetInnerHTML={{ __html: successMessage }}
        />
      ) : (
        <form
          className="form"
          action="/api/form-builder"
          method="post"
          onSubmit={onSubmit}
        >
          {description && (
            <div dangerouslySetInnerHTML={{ __html: description }} />
          )}
          {error && Object.keys(error).length > 0 && (
            <div className="notification notification--error">
              <h3 className="notification__title">
                There was a problem with your submission.
              </h3>
              {errorMessage && <p>{errorMessage}</p>}
              <p>Please check below and try again.</p>
            </div>
          )}
          {questions &&
            questions.map((question, i) => (
              <FormBuilderQuestion
                key={`form-builder-${i}`}
                question={question}
                error={error}
                data={data}
                id={i}
                onChange={onChange}
              />
            ))}
          {waiting ? (
            <LoadingSpinner />
          ) : (
            <div className="auto-justify auto-justify--align-right">
              <button type="submit" className="button button--primary">
                {ctaText}
              </button>
            </div>
          )}
        </form>
      )}
    </div>
  )
}

FormBuilder.propTypes = {
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  data: PropTypes.object,
  waiting: PropTypes.bool,
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  error: PropTypes.object,
  form: PropTypes.object,
}

export default FormBuilder
