import { join } from 'path'
import fs from 'fs'

const baseUrl = '/assets/img/content/crests/'

const getCrestPath = (team, hasDarkBG) => {
  const crestUrl = hasDarkBG ? `${team}--dark-bg.svg` : `${team}.svg`
  if (!global.window) {
    return fs.existsSync(join(__dirname, '../../../../site', baseUrl, crestUrl))
      ? `${baseUrl}${crestUrl}`
      : `${baseUrl}blank.svg`
  }
  return `${baseUrl}${crestUrl}`
}

export default getCrestPath
