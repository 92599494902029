import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import CurrencyInput from 'react-currency-input-field'

import Control from '../../control/Control'

const StandardCurrencyField = ({
  showRequired = true,
  required,
  assistance,
  className,
  controlClassName,
  error,
  id,
  label,
  modifiers,
  name,
  onChange,
  ...other
}) => {
  const fieldId = `field--${id || name}`
  const controlId = id || `control--${name}`
  const fieldClasses = classNames(
    'field',
    'field--standard-text',
    modifiers && modifiers.map((modifierClass) => `field--${modifierClass}`),
    className,
    { 'field--error': error }
  )

  return (
    <div className={fieldClasses} id={fieldId}>
      {label && (
        <div className="field__question">
          <label htmlFor={controlId}>
            {label}
            {showRequired && required && (
              <span>
                &nbsp;<abbr title="This field is required">*</abbr>
              </span>
            )}
          </label>
        </div>
      )}
      <div className="field__answer">
        {/* <Control
          className={controlClassName}
          error={error}
          id={controlId}
          modifiers={modifiers}
          name={name}
          required={required}
          {...other}
        /> */}
        <CurrencyInput
          className={classNames(controlClassName, 'control--text', error && 'control--error')}
          id={controlId}
          prefix="£"
          name={name}
          required={required}
          onValueChange={(value) => {onChange({ currentTarget: { name: 'bidAmount', value: value }, target: {} } ) }}
          {...other}
        />
      </div>
      {assistance ? (
        <div className="field__assistance">{assistance}</div>
      ) : null}
      {error ? <div className="field__feedback">{error}</div> : null}
    </div>
  )
}

StandardCurrencyField.propTypes = {
  showRequired: PropTypes.bool,
  required: PropTypes.bool,
  assistance: PropTypes.string,
  className: PropTypes.string,
  controlClassName: PropTypes.string,
  error: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  modifiers: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
}

export default StandardCurrencyField
