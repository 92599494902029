import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const CalendarEvent = (props) => {
  const event = props.event

  const commonAttributes = {
    className: classNames('rbc-event-inner', `is-${event.type}`),
  }

  if (!event.href) {
    return <div {...commonAttributes}>{event.title}</div>
  }

  return (
    <a href={event.href} {...commonAttributes}>
      {event.title}
    </a>
  )
}

CalendarEvent.propTypes = {
  event: PropTypes.object,
}

export default CalendarEvent
