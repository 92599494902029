import debug from 'debug'
import qs from 'qs'
import redirectTo from '../lib/redirector'
import updateQueryParam from '../lib/query-parameter-updater'
const log = debug('search:log')

export default () => {
  log('Init search')

  const eventFilter = document.querySelector('.widget--search')
  if (!eventFilter) return false

  const sortSelect = getSelect(eventFilter, 'sort')

  let search = window.location.search.substring(1)
  const query = qs.parse(search)

  sortSelect.addEventListener('change', () => {
    const url = updateQueryParam('sort', sortSelect.value)
    redirectTo(url)
  })
}

const getSelect = (form, id) => {
  return form.querySelector(`#control--${id}`)
}
