import React from 'react'

const HammerSvg = () => (
  <svg width="91" height="91" viewBox="0 0 91 91" fill="none">
    <g opacity=".08" fill="#161616">
      <path d="M2.968 91h35.615a2.968 2.968 0 0 0 2.967-2.968v-2.968a5.936 5.936 0 0 0-5.935-5.935H5.934A5.936 5.936 0 0 0 0 85.064v2.968A2.968 2.968 0 0 0 2.968 91zm32.251-51.226l4.194-4.175 4.215-4.214a2.948 2.948 0 0 1 4.194 0l40.502 40.502c3.463 3.462 3.463 9.12 0 12.583l-.02.02c-3.58 3.539-9.204 3.377-12.563-.02l-40.522-40.5a2.947 2.947 0 0 1-.851-2.098c0-.791.297-1.543.851-2.097z" />
      <path d="M34.368 41.871c0 .792.297 1.544.85 2.098L75.742 84.47c3.355 3.394 8.98 3.563 12.564.02L39.414 35.599l-4.195 4.175a2.948 2.948 0 0 0-.851 2.097z" />
      <path d="M28.933 53.235c.786 0 1.542-.313 2.098-.87l25.183-25.183a2.967 2.967 0 0 0 0-4.197l-12.59-12.592c-1.114-1.113-3.085-1.113-4.198 0L14.242 35.576a2.967 2.967 0 0 0 0 4.197l12.592 12.592c.557.557 1.312.87 2.099.87z" />
      <path d="M26.817 23.002l16.788 16.79-12.574 12.574a2.969 2.969 0 0 1-4.197 0L14.242 39.773a2.967 2.967 0 0 1 0-4.196l12.575-12.575zm38.053 4.444a5.935 5.935 0 0 0 0-8.395L47.555 1.738a5.935 5.935 0 0 0-8.393 0 5.935 5.935 0 0 0 0 8.395l17.314 17.314a5.935 5.935 0 0 0 8.394-.001z" />
      <path d="M31.294 61.022a5.935 5.935 0 0 0 0-8.395L13.981 35.314a5.935 5.935 0 1 0-8.393 8.393L22.9 61.021a5.934 5.934 0 0 0 8.393 0zM38.583 91H2.968A2.968 2.968 0 0 1 0 88.032v-2.968h41.55v2.968A2.968 2.968 0 0 1 38.584 91z" />
    </g>
  </svg>
)

export default HammerSvg
