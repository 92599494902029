import React from 'react'
import PropTypes from 'prop-types'

const Option = ({ option }) => {
  const { text, value, disabled, selected, squadLevel } = option

  return (
    <option
      data-squad-level={squadLevel}
      value={value || text}
      disabled={disabled}
      selected={selected}
    >
      {text}
    </option>
  )
}

Option.propTypes = {
  option: PropTypes.shape({
    text: PropTypes.string,
    value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    disabled: PropTypes.bool,
    selected: PropTypes.bool,
    squadLevel: PropTypes.any,
  }),
}

export default Option
