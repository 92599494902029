import debug from 'debug'
import qs from 'qs'
const log = debug('auctions-filter:log')

export default () => {
  log('Init auctions filter')

  const auctionFilter = document.querySelector('.js-auction--form')
  if (!auctionFilter) return false

  const stateSelect = getSelect(auctionFilter, 'state')
  const sortSelect = getSelect(auctionFilter, 'sort')

  let search = window.location.search.substring(1) // remove leading question mark
  const query = qs.parse(search) // create query object from query params

  // Populate all selects
  stateSelect.value = query.state || 'all'
  sortSelect.value = query.sort || 'soonest'

  stateSelect.addEventListener('change', () => {
    const url = updateQueryParam('state', stateSelect.value)
    redirectTo(url)
  })
  sortSelect.addEventListener('change', () => {
    const url = updateQueryParam('sort', sortSelect.value)
    redirectTo(url)
  })
}

const getSelect = (form, id) => {
  return form.querySelector(`#control--${id}`)
}

// Update the query param values, if 'all' is selected, remove from query param
const updateQueryParam = (property, value) => {
  const url = getCurrentUrl()
  if (value === 'all') {
    delete url.query[property]
  } else {
    url.query[property] = value
  }
  return url
}

// build url path and redirect page
const redirectTo = (url) => {
  let path = url.path.join('/')
  const query = qs.stringify(url.query)
  if (query) path = path.concat('?', query)
  window.location.href = path
}

// get the current pages url, used to build a location object
const getCurrentUrl = () => {
  const currentUrl = {}
  const search = window.location.search
  currentUrl.path = window.location.pathname.split('/')
  currentUrl.query = qs.parse(search.substring(1))

  return currentUrl
}