import Stickyfill from 'stickyfill'

export default (serviceLocator) => {
  const stickyfill = Stickyfill()
  const stickyEls = document.querySelectorAll('.js-sticky')

  stickyEls.forEach((el) => {
    stickyfill.add(el)
  })
}
