import React from 'react'
import PropTypes from 'prop-types'

import CustomSearchControl from './CustomSearch'
import TextControl from '../text/Text'

const SearchControl = ({ hasButton, ...other }) => {
  const SearchControlType = hasButton ? CustomSearchControl : TextControl

  return <SearchControlType {...other} />
}

SearchControl.propTypes = {
  hasButton: PropTypes.bool,
}

export default SearchControl
