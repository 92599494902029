import React from 'react'
import ReactDOM from 'react-dom'
import EmailSignup from '../../../site/layout/component/email-signup/component/EmailSignup'
import createFormSubmissionContainer from '../../lib/form-submission'
const debug = require('debug')('email-signup')
const schemata = require('schemata')
const required = require('@clocklimited/validity-required')
const schema = schemata({
  name: 'Email Signup',
  properties: {
    fullName: {
      type: String,
      validators: [ required ],
    },
    email: {
      type: String,
      validators: [ required ],
    },
    emailSignUpOptions: {
      type: String,
    },
  },
})

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    const appNode = document.getElementById('email-signup')
    if (!appNode) {
      return debug('No email sign up node found. Skipping.')
    }

    const emailSignUpTitle = appNode.dataset.emailSignUpTitle
    const emailSignUpText = appNode.dataset.emailSignUpText
    const emailSignUpOptions = appNode.dataset.emailOptions
    const optionsArray = emailSignUpOptions
      ? JSON.parse(emailSignUpOptions)
      : []

    const Component = (props) => (
      <EmailSignup
        {...props}
        emailSignUpTitle={emailSignUpTitle}
        emailSignUpText={emailSignUpText}
        emailSignUpOptions={optionsArray}
      />
    )

    const FormSubmissionContainer = createFormSubmissionContainer({
      Component: Component,
      schema: schema,
      apiUrl: '/api/email-signup',
      errorMessage:
        'An error has occurred. Please try again. If the problem persists, please get in touch via our <a href="/club/contact">contact page</a>.',
      successMessage: 'Thank you for signing up.',
    })

    ReactDOM.render(<FormSubmissionContainer />, appNode)
  })
}
