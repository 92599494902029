import React from 'react'
import ReactDOM from 'react-dom'
import FormBuilder from '../../../site/layout/component/form-builder/component/FormBuilder'
import createFormSubmissionContainer from '../../lib/form-submission'
import createSchema from './schema'

const debug = require('debug')('form-builder')

const buildSchema = (containerNode) => {
  const encodedQuestionsNode = containerNode.querySelector(
    '.js-encoded-questions'
  )
  if (!encodedQuestionsNode) return debug('No encoded questions')
  const questionsJson = encodedQuestionsNode.innerHTML
  return createSchema(JSON.parse(questionsJson))
}

const getFormNode = (containerNode) =>
  containerNode.querySelector('.js-encoded-form') || debug('No encoded form')

const getForm = (containerNode) => {
  const encodedFormNode = getFormNode(containerNode)
  if (!encodedFormNode) return false
  const formJson = encodedFormNode.innerHTML
  return JSON.parse(formJson)
}

const render = (containerNode) => {
  const form = getForm(containerNode)
  const FormSubmissionContainer = createFormSubmissionContainer({
    Component: (props) => <FormBuilder form={form} {...props} />,
    schema: buildSchema(containerNode),
    apiUrl: '/api/form-builder/' + getForm(containerNode)._id,
    errorMessage: 'Error. Please check your details and try again.',
    successMessage: form.thanksMessage || 'Thank you.',
    onSuccess: () => {
      const navigationElement = document.querySelector('.main-navigation')
      const offset = navigationElement ? navigationElement.clientHeight : 0
      window.scrollTo(0, containerNode.offsetTop - offset - 20)
    },
  })
  debug('Render new React FormSubmissionContainer', containerNode)
  return ReactDOM.render(<FormSubmissionContainer />, containerNode)
}

const initReact = () => {
  debug('Looking for forms')
  document.querySelectorAll('.js-form-builder').forEach((containerNode) => {
    containerNode.classList.remove('js-form-builder')
    render(containerNode)
  })
}

const init = () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', initReact, false)
  // app:NewDOMContent is a custom event emitted by our app
  document.addEventListener('app:NewDOMContent', initReact, false)
}

export { init, initReact }
