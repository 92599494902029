import React from 'react'
import PropTypes from 'prop-types'
import getCrestPath from '../../../../../../site/layout/lib/getCrestPath'
import {
  getDisplayName,
  getAbbreviation,
} from '../../../../../../service/squad/lib/squad-names-retriever'
import slug from 'slugg'
import versionPath from '../../../../../../../site/lib/version-path'

const TeamCrest = ({ team, hasDarkBG }) => {
  const displayName = getDisplayName(team)
  const abbreviation = getAbbreviation(team)
  const fileName = getCrestPath(slug(team), hasDarkBG)
  const isBlank = fileName.indexOf('blank') > 0

  return (
    <span className="team-crest">
      <img
        className="team-crest__image"
        src={versionPath(fileName)}
        alt={displayName}
        loading="lazy"
      />
      {isBlank && <p className="team-crest__text">{abbreviation}</p>}
    </span>
  )
}

TeamCrest.propTypes = {
  team: PropTypes.string.isRequired,
  hasDarkBG: PropTypes.bool,
}

export default TeamCrest
