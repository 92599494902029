import React from 'react'
import ReactDOM from 'react-dom'

import createDebug from 'debug'
import schemata from 'schemata'
import isEmail from '@clocklimited/validity-email'
import required from '@clocklimited/validity-required'
import validityNumber from 'validity-number'
import phoneNumber from 'validity-telephone-number'
import createFormSubmissionContainer from '../../lib/form-submission'
import BiddingForm from '../../../site/layout/component/bidding-form/component/BiddingForm'

const wholeNumberValidator = (propertyName, errorName, object, callback) => {
  const value = object[propertyName]
  let error

  if (Number(value) % 1 !== 0) {
    error = errorName + ' must be a whole number'
  }

  callback(null, error)
}

const debug = createDebug('auction-bidding-form')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    const appNode = document.getElementById('bidding-form')
    if (!appNode) {
      return debug('No bidding-form node found. Skipping.')
    }
    const auction = appNode.dataset.auction

    const thankYouMessage = appNode.dataset.thankyoumessage
    const tableNumber = appNode.dataset.tablenumber
    const hospitalityLounge = appNode.dataset.hospitalitylounge

    const schema = schemata({
      name: 'Bidding Form',
      properties: {
        fullName: {
          type: String,
          validators: [ required ],
        },
        dob: {
          type: Date,
          validators: [ required ],
        },
        email: {
          type: String,
          validators: [ required, isEmail ],
        },
        telephone: {
          type: String,
          validators: [ required, phoneNumber ],
        },
        bidAmount: {
          type: Number,
          validators: { all: [ validityNumber, required, wholeNumberValidator ] },
        },
        ...(tableNumber === 'true'
          ? { tableNumber: { type: String, validators: [ required ] } }
          : {}),
        ...(hospitalityLounge === 'true'
          ? { hospitalityLounge: { type: String, validators: [ required ] } }
          : {}),
        biddingForm: {
          type: String,
        },
        auction: {
          type: String,
        },
        captcha: {
          type: String,
          validators: [ required.setFailureMessage('Captcha must be checked') ],
        },
      },
    })

    const Component = (props) => (
      <BiddingForm
        auction={auction}
        thankYouMessage={thankYouMessage}
        tableNumber={tableNumber}
        hospitalityLounge={hospitalityLounge}
        {...props}
      />
    )

    const FormSubmissionContainer = createFormSubmissionContainer({
      Component: Component,
      schema: schema,
      apiUrl: '/submit-bid',
      errorMessage:
        'Please check the form details and try again. If the problem persists, refresh the page and check whether your bid is not below the current highest bid.',
      successMessage: 'Success',
    })

    ReactDOM.render(<FormSubmissionContainer />, appNode)
  })
}
