import React from 'react'
import PropTypes from 'prop-types'
import debug from 'debug'
import sortBy from 'lodash.sortby'
import uniqBy from 'lodash.uniqby'
import Commentary from '../../../components/site/widgets/match/commentary/component/Commentary'

const log = debug('matchday-live:commentary:log')

class CommentaryContainer extends React.Component {
  constructor(props) {
    super(props)
    this.commentary = this.props.commentary
    this.primus = this.props.primus
    this.state = {
      commentary: this.commentary,
    }
  }

  componentWillMount() {
    this.primus.on('newCommentary', (newCommentary) => {
      log('newCommentary', newCommentary)
      let commentary = sortBy(
        [ ...this.commentary, newCommentary ],
        'minute'
      ).reverse()
      commentary = uniqBy(commentary, '_id')
      this.commentary = commentary
      this.setState({ commentary })
    })

    this.primus.on('deleteCommentary', (oldCommentary) => {
      log('deleteCommentary', oldCommentary)
      let commentary = this.commentary.filter(
        (item) => item._id !== oldCommentary
      )
      commentary = uniqBy(commentary, '_id')
      this.commentary = commentary
      this.setState({ commentary })
    })

    this.primus.on('commentary', (commentary) => {
      log('commentary', commentary)
      this.commentary = uniqBy(commentary, '_id')
      this.setState({ commentary })
    })
  }

  render() {
    return <Commentary isLive commentary={this.state.commentary} />
  }
}

CommentaryContainer.propTypes = {
  commentary: PropTypes.array,
  primus: PropTypes.object,
}

export default CommentaryContainer
