import React from 'react'

const SentOffSvg = () => (
  <svg data-name="Layer 1" width="10" height="12" viewBox="0 0 11 17">
    <path
      vectorEffect="non-scaling-stroke"
      d="M10.67 3.94a1.31 1.31 0 0 0-.64-1.7L5.15.11a1.32 1.32 0 0 0-1.73.68l-.84 1.92H1.35A1.32 1.32 0 0 0 .03 4.02v9.8a1.31 1.31 0 0 0 1.32 1.32h4a2.63 2.63 0 1 0 2.67-4.16v-.79zM4.03 1.09a.57.57 0 0 1 .75-.3l4.91 2.15a.57.57 0 0 1 .3.75l-2 4.66V4.02A1.31 1.31 0 0 0 6.67 2.7H3.39zM1.28 14.4a.56.56 0 0 1-.57-.57V4.02a.57.57 0 0 1 .57-.57H6.6a.57.57 0 0 1 .57.57v6.92a2.62 2.62 0 0 0-2.32 3.47H1.28zm8.19-.85a2.13 2.13 0 1 1-2.29-2.12h.16a2.14 2.14 0 0 1 2.16 2.11z"
      fill="#ffffff"
    />
    <path
      vectorEffect="non-scaling-stroke"
      fill="#ffffff"
      d="M8.49 12.79l-.35-.36-.77.77-.77-.77-.35.36.77.76-.77.77.35.35.77-.77.77.77.35-.35-.77-.77.77-.76z"
    />
  </svg>
)

export default SentOffSvg
