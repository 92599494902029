import React from 'react'
import ReactDOM from 'react-dom'
import { DiscussionEmbed } from 'disqus-react'
const debug = require('debug')('countdown')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.js-disqus-widget').forEach((appNode) => {
      const disqusShortname = appNode.dataset.shortName
      const disqusConfig = JSON.parse(appNode.dataset.disqusConfig)

      ReactDOM.render(
        <DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />,
        appNode
      )
    })
  })
}
