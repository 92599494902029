import React from 'react'
import PropTypes from 'prop-types'
import TeamCrest from '../../primitive/team-crest/component/TeamCrest'
import Icon from '../../primitive/icon/component/Icon'
import Svgs from '../../primitive/svgs/component/Svgs'

const eventSvgs = {
  try: <Svgs.BallWhite />,
  conversion: <Svgs.BallWhite />,
  penalty: <Svgs.BallWhite />,
  dropGoal: <Svgs.BallWhite />,
  yellowCard: <Svgs.YellowCard />,
  redCard: <Svgs.RedCard />,
  substitution: <Svgs.Substitution />,
}

const eventText = {
  try: 'T',
  conversion: 'C',
  penalty: 'P',
  dropGoal: 'D',
}

const getText = (event) => (
  <span className="timeline__icon-text">{eventText[event]}</span>
)
const getSvg = (event) => (
  <Icon width={7} height={7}>
    {eventSvgs[event]}
  </Icon>
)
const minuteToWidth = (minute, prevTime) => {
  const width = minute - prevTime
  if (width === 0) return 2 // prevents width of icon being 0, which breaks the timeline layout
  return width * 1.25
}

const Timeline = ({ match, commentary }) => {
  const homeClub = match.__homeClub
  const awayClub = match.__awayClub
  const homeEvents = commentary
    .filter((event) => event.squad === 'home')
    .reverse()
  const awayEvents = commentary
    .filter((event) => event.squad === 'away')
    .reverse()

  return (
    <div className="timeline">
      <div className="timeline__row">
        <div className="timeline__crest">
          {homeClub.crestId && <TeamCrest team={homeClub.crestId} />}
        </div>
        <div className="timeline__icon-wrapper">
          {homeEvents &&
            homeEvents.map((event, i) => {
              const prevTime = homeEvents[i - 1] ? homeEvents[i - 1].minute : 0
              return (
                <div
                  key={`homeTimeline${i}`}
                  className="timeline__icon"
                  style={{ width: `${minuteToWidth(event.minute, prevTime)}%` }}
                >
                  <div className="timeline__icon-inner">
                    {getSvg(event.type)}
                    {getText(event.type)}
                  </div>
                </div>
              )
            })}
          <div className="timeline__spacer" />
        </div>
      </div>
      <div className="timeline__row">
        <span className="timeline__label">KO</span>
        <span className="timeline__divider">
          <span className="timeline__label">HT</span>
        </span>
        <span className="timeline__label">FT</span>
      </div>
      <div className="timeline__row">
        <div className="timeline__crest">
          {awayClub.crestId && <TeamCrest team={awayClub.crestId} />}
        </div>
        <div className="timeline__icon-wrapper">
          {awayEvents &&
            awayEvents.map((event, i) => {
              const prevTime = awayEvents[i - 1] ? awayEvents[i - 1].minute : 0
              return (
                <div
                  key={`awayTimeline${i}`}
                  className="timeline__icon"
                  style={{ width: `${minuteToWidth(event.minute, prevTime)}%` }}
                >
                  <div className="timeline__icon-inner">
                    <Icon width={7} height={7}>
                      {getSvg(event.type)}
                    </Icon>
                    <span className="timeline__icon-text">
                      {getText(event.type)}
                    </span>
                  </div>
                </div>
              )
            })}
          <div className="timeline__spacer" />
        </div>
      </div>
    </div>
  )
}

Timeline.propTypes = {
  match: PropTypes.object.isRequired,
  commentary: PropTypes.array.isRequired,
}

export default Timeline
