import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Motion, spring } from 'react-motion'

const inBrowser = typeof window !== 'undefined'

const PollResultsBar = ({
  isFilled,
  isSelected,
  isLeading,
  response,
  count,
  total,
  showCounts,
}) => {
  const percentage = Math.round((count / total) * 100)
  const percentageText = `${percentage || 0}%`
  const countText = `(${count} votes)`
  const fullResult = showCounts
    ? `${percentageText} ${countText}`
    : percentageText
  const cappedResult = percentageText

  return (
    <div className="poll-builder__result">
      <div
        className={classNames(
          'poll-builder__bar',
          { 'poll-builder__bar--leading': isLeading },
          { 'poll-builder__bar--selected': isSelected }
        )}
      >
        <Motion
          defaultStyle={{ x: inBrowser ? 0 : percentage }}
          style={{ x: spring(percentage, { stiffness: 200, damping: 30 }) }}
        >
          {(value) => {
            const clipPath = `inset(-1% ${
              total === 0 ? 100 : 100 - value.x
            }%  -1% -1%)`
            return (
              <span
                className="poll-builder__bar-fill"
                data-response={response}
                data-full-result={fullResult}
                data-capped-result={cappedResult}
                style={{ WebkitClipPath: clipPath, clipPath }}
              />
            )
          }}
        </Motion>
        <span
          className={classNames(
            'poll-builder__tick',
            !isSelected && 'poll-builder__tick--hidden'
          )}
        />
        <span className="poll-builder__response">{response}</span>
        <span className="poll-builder__count">{fullResult}</span>
      </div>
    </div>
  )
}

const PollResults = ({ results, selected, showFullResults }) => {
  const responses = Object.keys(results)
  const total = responses.reduce(
    (total, response) => total + results[response],
    0
  )

  const leadingValue = responses
    .map((response) => results[response])
    .reduce((a, b) => Math.max(a, b))

  return (
    <div>
      {responses.map((response, i) => (
        <PollResultsBar
          key={`poll-result-${i}`}
          isLeading={results[response] === leadingValue}
          isSelected={selected === response}
          count={results[response]}
          response={response}
          total={total}
          showCounts={showFullResults}
        />
      ))}
    </div>
  )
}

PollResults.propTypes = {
  results: PropTypes.object.isRequired,
  selected: PropTypes.string,
  showFullResults: PropTypes.bool,
}

PollResultsBar.propTypes = {
  isFilled: PropTypes.bool,
  isSelected: PropTypes.bool,
  isLeading: PropTypes.bool,
  count: PropTypes.number,
  total: PropTypes.number,
  response: PropTypes.string,
  showCounts: PropTypes.bool,
}

export default PollResults
