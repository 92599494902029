import React, { Fragment } from 'react'

import crypto from 'crypto'
import ReCAPTCHA from 'react-google-recaptcha'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Field from '../../primitive/field/component/Field'

class BiddingForm extends React.Component {
  constructor(props) {
    super(props)
    this.auction = this.props.auction
    this.checksum = crypto
      .createHmac('sha256', 'auction-salt')
      .update(this.auction)
      .digest('hex')
    this.onChange = this.props.onChange
    this.setValues = this.props.setValues
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    const defaults = {
      auction: this.auction,
      checksum: this.checksum,
    }

    this.setValues(defaults)
  }

  handleSubmit(e) {
    e.preventDefault()
    window.grecaptcha.reset()
    this.props.onSubmit(e)
  }

  render() {
    const {
      onSubmit,
      onChange,
      successMessage,
      error = {},
      errorMessage,
      thankYouMessage,
      tableNumber,
      hospitalityLounge,
    } = this.props
    // Function to set the reCaptcha value once clicked
    const verifyCallback = (response) => {
      this.setValues({
        captcha: response,
      })
    }
    
    const hospitalityLoungeSelection = [ {
      text: 'Hospitality Lounge Selection',
      disabled: true,
      selected: true,
    },
    { text: 'Tunnel Club', value: 'Tunnel Club' },
    { text: 'Directors Lounge', value: 'Directors Lounge' },
    { text: 'Executive Box', value: 'Executive Box' },
    { text: 'Warner’s Experience', value: 'Warner’s Experience' },
    { text: 'Michael Jones Jeweller Champions Suite', value: 'Michael Jones Jeweller Champions Suite' },
    { text: 'Legends Lounge', value: 'Legends Lounge' },
    { text: 'Heroes Restauran', value: 'Heroes Restauran' },
    { text: 'Captain’s Bar', value: 'Captain’s Club' } ]

    return (
      <div className="auction-form">
        <div className="content-center content-center--medium content-gutter">
          {error && Object.keys(error).length > 0 && (
            <div className="notification notification--error">
              <h3 className="notification__title">
                There was a problem with your submission.
              </h3>
              {errorMessage && (
                <p dangerouslySetInnerHTML={{ __html: errorMessage }} />
              )}
              {!errorMessage && <p>Please check below and try again.</p>}
            </div>
          )}
          {successMessage ? (
            <div className="content-center">
              <h3 className="auction-form__heading inverse">
                Thanks for your bid
              </h3>
              {thankYouMessage ? <div dangerouslySetInnerHTML={{ __html: thankYouMessage }} /> : 
              <p>
                We've received your bid and will contact you via the provided
                details.
              </p>}
              <ul className="list list--spaced list--inline">
                <li>
                  <a
                    onClick={() => window.location.reload()}
                    className="button button--primary auction--success--button"
                  >
                    Refresh lot page
                  </a>
                  <a
                    onClick={() => window.location.href = 'https://www.northamptonsaints.co.uk/auctions'}
                    className="button button--primary auction--success--button"
                  >
                    Return to auctions
                  </a>
                </li>
              </ul>
            </div>
          ) : (
            <div className="content-center">
              <div className="grid">
                <div className="grid__item">
                  <Fragment>
                    <h2 className="auction-form__heading">
                      Place Your Bid Below
                    </h2>
                    <form
                      className="form"
                      action="/auction"
                      method="post"
                      onSubmit={this.handleSubmit}
                    >
                      <input type="hidden" name="form" value="bidding-form" />
                      <div className="grid">
                        <div className="grid__item tablet-one-half">
                          <Field
                            name="fullName"
                            type="text"
                            placeholder="Full Name"
                            onChange={onChange}
                            error={error.fullName}
                          />
                        </div>
                        <div className="grid__item tablet-one-half">
                          <Field
                            name="dob"
                            type="date"
                            placeholder="DOB"
                            onChange={onChange}
                            error={error.dob}
                          />
                        </div>
                        <div className="grid__item tablet-one-half">
                          <Field
                            name="email"
                            type="text"
                            placeholder="Email"
                            error={error.email}
                            onChange={onChange}
                          />
                        </div>
                        <div className="grid__item tablet-one-half">
                          <Field
                            name="telephone"
                            type="text"
                            placeholder="Phone Number"
                            onChange={onChange}
                            error={error.telephone}
                          />
                        </div>
                        <div className="grid__item tablet-one-half">
                          <Field
                            name="bidAmount"
                            type="currency"
                            placeholder="£0"
                            onChange={onChange}
                            error={error.bidAmount}
                          />
                        </div>
                        {tableNumber === 'true' ? (
                          <div className="grid__item tablet-one-half" >
                            <Field
                              name="tableNumber"
                              type="text"
                              placeholder="Table Number"
                              onChange={onChange}
                              error={error.tableNumber}
                            />
                          </div>) : '' }
                        {hospitalityLounge === 'true' ? (
                          <div className="grid__item tablet-one-half">
                            <Field
                              name="hospitalityLounge"
                              type="select"
                              onChange={onChange}
                              options={hospitalityLoungeSelection}
                              className="hospitality__longue__select"
                              error={error.hospitalityLounge}
                            />
                          </div>) : ''}
                        <div className="grid__item">
                          <Field
                            name="nsaintsOptIn"
                            type="checkbox"
                            onChange={onChange}
                            options={[
                              {
                                text:
                                  'Please tick if you would like to be kept up-to-date on the latest news, offers and competitions from Northampton Saints.',
                                value: 'yes',
                              },
                            ]}
                          />
                        </div>
                        <div className="grid__item">
                          <ReCAPTCHA
                            sitekey="6Leas1wUAAAAAAiX2RwgzCybOxsiKK2z5RQqpMtL"
                            onChange={verifyCallback}
                            badge="inline"
                            className="recaptcha"
                          />
                          {/* Use an empty checkbox to display captcha validation. Once captcha selected, set captcha value */}
                          <Field
                            name="captcha"
                            type="checkbox"
                            options={[]}
                            error={error.captcha}
                          />
                        </div>
                        <div className="grid__item">
                          <button
                            type="submit"
                            className="button button--grey button--float-left"
                          >
                            Place Bid
                          </button>
                        </div>
                      </div>
                    </form>
                  </Fragment>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

BiddingForm.propTypes = {
  auction: PropTypes.string,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  setValues: PropTypes.func,
  error: PropTypes.object,
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  thankYouMessage: PropTypes.string,
  tableNumber: PropTypes.string,
  hospitalityLounge: PropTypes.string,
}

export default BiddingForm
