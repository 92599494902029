import React from 'react'

const PenaltySvg = () => (
  <svg width="21" height="22" viewBox="0 0 21 22">
    <path d="M13.4 3.7h1V20h-1V3.7zm5.6 0h1V20h-1V3.7z" />
    <path d="M13.8 16h6v1h-6v-1zM17 2.3l-.7-.3v-.2c.3 0 .6.3.8.5zm-2.3-.7h-1.5V1h1.6v.6zm-3 .7c-.3.3-1 .6-1.2 1l-.5-1 1.4-1 .4 1zm-2.3 2l-1 1.3-.7-.5 1-1 .7.3zM7.4 7l-1 1.3L6 8l1-1.5.4.5zM3 10.8c-1 1-2 3-2 4.6S1.7 19 2.7 20c.5.7 1 1 1.7 1 1 0 1.8-.6 2.6-1.8.7-1 1-2.4 1.2-3.5 0-1.6-.6-3.5-1.6-4.7-.6-.7-1.3-1-2-1-.4 0-1 .3-1.6.8zm1 3.5c0-1.2.3-2.5.5-3l.5-.8c.3 0 .7.4 1 .7.4.5.8 1 1 1.8.3.8.4 1.5.4 2.3 0 1.4-.7 2.3-1.2 3-.4.6-1 1-1 1-.3-.2-.5-.8-.5-.8L4 14.3zM3.6 11h.2c-.4.8-.5 3-.5 4v3.4c0 .2 0 .4-.2.3l-.4-.7c-.4-1-.7-2-.6-3 0-1.3.6-3 1.6-4z" />
  </svg>
)

export default PenaltySvg
