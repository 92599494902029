import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Share from '../../../../layout/component/share/component/Share'

const ArticleHeader = ({ category, title, meta, sponsors, shareUrls }) => {
  return (
    <Fragment>
      {shareUrls && <Share heading="Share" shareUrls={shareUrls} />}
      <header className="article-header">
        <div className="content-center content-center--small content-gutter">
          {category && <div className="category">{category}</div>}
          <h1 className="h1 article-title">{title}</h1>
          {meta && <div className="article-meta">{meta}</div>}
          {sponsors && sponsors.length > 0 && (
            <div className="article-sponsor-wrapper">
              {sponsors.map((sponsor) => (
                <div className="article-sponsor">
                  <a href={sponsor.url} target="_blank" rel="noopener nofollow">
                    <img
                      src={sponsor.imgUrl}
                      alt={sponsor.alt}
                      loading="lazy"
                    />
                    <span>Sponsored by {sponsor.alt}</span>
                  </a>
                </div>
              ))}
            </div>
          )}
        </div>
      </header>
    </Fragment>
  )
}

ArticleHeader.propTypes = {
  category: PropTypes.string,
  sponsors: PropTypes.array,
  title: PropTypes.string.isRequired,
  meta: PropTypes.string,
  shareUrls: PropTypes.object,
}

export default ArticleHeader
