import React from 'react'

const FabWinSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 427.94 427.94" fill="#fff">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path d="M96.21,201.84c2.6-16.93,5.24-33.85,7.74-50.79.41-2.78,1.35-3.93,4.39-3.81,6.65.26,13.34.36,20,0,3.88-.22,4.32,1.93,4.74,4.69q3.6,23.67,7.24,47.33c.24,1.58.09,3.29,1.81,5,2.14-15.29,4.36-30.15,6.26-45.06,1.5-11.79,1.33-11.81,13.34-11.81,5.49,0,11,.31,16.48-.1,4.44-.32,4.79,1.39,4.07,5.1-4.56,23.66-9,47.35-13.42,71-3.45,18.3-7,36.57-10.26,54.9-.53,2.94-1.43,3.84-4.37,3.76-7.16-.21-14.33-.25-21.48,0-3.11.11-3.85-1.16-4.34-3.88-3.32-18.14-6.81-36.24-10.91-54.47-1.7,8.63-3.44,17.26-5.08,25.9-1.8,9.47-3.56,18.95-5.22,28.44-.49,2.79-1.42,4.19-4.73,4a186.55,186.55,0,0,0-20.48,0c-3.8.23-4.68-1.37-5.27-4.58q-9-49.29-18.29-98.54c-1.76-9.47-3.44-19-5.41-28.4-.53-2.56,0-3.27,2.5-3.25q14.25.11,28.49,0c2.28,0,3.1.76,3.41,3.1,2.29,17.81,4.75,35.61,7.16,53.4a2.56,2.56,0,0,1,.1,2.58A34.56,34.56,0,0,0,96.21,201.84Z" fill="#fefefe" />
        <path d="M263.89,213.38c-1.14,2-.66,3.68-.66,5.24-.05,19.81-.09,39.62,0,59.43,0,3-.59,4.06-3.8,4-8.15-.24-16.31-.17-24.47,0-2.63.05-3.74-.41-3.74-3.44q.17-63.93,0-127.85c0-2.56.66-3.49,3.34-3.43,7.65.15,15.31.17,23,0,2.7-.07,3.7,1.31,4.8,3.37q14.89,27.92,29.93,55.74c.85,1.58,1.75,3.13,2.72,4.87,1.55-1.06.93-2.46.93-3.57,0-18.65.12-37.29,0-55.94,0-3.44.88-4.6,4.43-4.49,8,.25,16,.18,24,0,2.87-.06,3.68.94,3.68,3.74q-.11,63.68,0,127.34c0,2.83-.79,3.73-3.64,3.65-6.65-.19-13.32-.23-20,0-3,.11-4.35-1.06-5.66-3.55C288,258.21,277.18,238,266.37,217.82,265.63,216.43,264.84,215.07,263.89,213.38Z" fill="#fefefe" />
        <path d="M218.22,214.62c0,20.79-.08,41.59.07,62.38,0,3.65-.53,5.25-4.75,5.05-8.3-.38-16.63-.22-24.95-.05-2.66.05-3.3-.81-3.3-3.37q.12-63.87,0-127.75c0-2.32.22-3.68,3.14-3.62,8.81.17,17.64.21,26.45,0,3.77-.1,3.33,2.13,3.33,4.49Q218.2,183.18,218.22,214.62Z" fill="#fefefe" />
        <path d="M357.43,147.32c5,0,10,.32,15-.1,4.4-.36,5.18,1.28,4.87,5.31-1.29,16.89-2.27,33.81-3.39,50.71-.54,8.12-1.26,16.22-1.65,24.35-.12,2.55-.85,3.47-3.47,3.42-7.49-.14-15-.19-22.45,0-2.84.08-3.23-1.14-3.38-3.49-1.12-17.23-2.34-34.46-3.54-51.68-.56-8.12-1-16.24-1.77-24.34-.29-3.18.38-4.47,3.86-4.28C346.77,147.53,352.11,147.32,357.43,147.32Z" fill="#fefefe" />
        <path d="M375.25,263.73c0,4.16.08,8.31,0,12.47-.09,3.76-2,5.78-5.92,5.75-7.81-.05-15.62-.13-23.43,0-4.32.09-6.16-2.19-6.23-6q-.22-12.47,0-24.93c.07-3.85,2-6.16,6.24-6.12,7.64.09,15.29.06,22.93,0,4.31,0,6.47,2,6.44,6.33C375.23,255.42,375.25,259.58,375.25,263.73Z" fill="#fefefe" />
        <path d="M96.21,201.84a18.12,18.12,0,0,1-.28,2.87c-.13.52-.7.93-1.08,1.39a2.29,2.29,0,0,1-.29-2.31Z" fill="#8aaa9d" />
      </g>
    </g>
  </svg>
)
export default FabWinSvg
