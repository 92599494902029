import React from 'react'

const CloseAlt = () => (
  <svg viewBox="0 0 50 50">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m36.44 16.64-3.08-3.08L25 21.92l-8.36-8.36-3.08 3.08L21.92 25l-8.36 8.36 3.08 3.08L25 28.08l8.36 8.36 3.08-3.08L28.08 25l8.36-8.36Z"
      fill="#fff"
    />
  </svg>
)

export default CloseAlt
