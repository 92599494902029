import React from 'react'

const CinchSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 476.2 166.3">
    <path
      d="M456 209h-25V76h25v27c0 2 0 2 1 1a61 61 0 0 1 16-4 83 83 0 0 1 17 0c10 1 20 4 29 11a43 43 0 0 1 13 18 60 60 0 0 1 4 22v58h-24l-1-1v-57c0-6-1-11-4-16-2-4-6-7-11-9a44 44 0 0 0-17-1c-6 0-12 2-16 6a17 17 0 0 0-6 10 60 60 0 0 0-1 10v57Zm-144 0h-24l-1-1v-56l-3-15a19 19 0 0 0-13-11 45 45 0 0 0-20-1 26 26 0 0 0-12 5 19 19 0 0 0-6 11 45 45 0 0 0-1 11v56l-2 1h-23v-58a72 72 0 0 1 2-17c3-14 11-25 26-30 9-4 18-5 28-4a56 56 0 0 1 25 6 43 43 0 0 1 19 22 61 61 0 0 1 5 24v56Zm-151-89-19 16-3-4a31 31 0 0 0-27-9 32 32 0 1 0 29 51h2l17 15 1 1a57 57 0 0 1-24 17 54 54 0 0 1-16 4 48 48 0 0 1-5 0 63 63 0 0 1-14-2 56 56 0 0 1-19-9 56 56 0 0 1-20-28 55 55 0 0 1-3-20 57 57 0 0 1 35-50 55 55 0 0 1 25-4c17 1 30 8 41 21a2 2 0 0 1 0 1Zm242 53 6 6 12 9v2a57 57 0 0 1-23 17 54 54 0 0 1-16 4 45 45 0 0 1-6 0 69 69 0 0 1-14-2 57 57 0 0 1-39-37 54 54 0 0 1-2-20c1-16 7-29 19-40a55 55 0 0 1 22-12 56 56 0 0 1 23-2c15 2 27 9 36 21v1l-18 15a4 4 0 0 1 0 1c-8-10-17-14-29-13a31 31 0 0 0-20 10 32 32 0 0 0 0 43 31 31 0 0 0 25 10c10 0 18-5 24-13Zm-232-15v-50l1-1h22l2 1v100l-2 1h-22l-1-1v-50Zm-13-88a25 25 0 1 1 25 25c-13 0-25-11-25-25Zm42 0a17 17 0 1 0-16 17 17 17 0 0 0 16-17Z"
      transform="translate(-60 -45)"
      style={{
        fill: '#fff',
      }}
    />
  </svg>
)

export default CinchSvg
