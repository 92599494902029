import React from 'react'
import PropTypes from 'prop-types'
import format from 'format-number'
import classNames from 'classnames'

const pad = format({ padLeft: 2 })

const getCountdownUnits = (currentTime, targetTime) => {
  currentTime = currentTime || new Date()

  let seconds = 0
  if (currentTime.getTime() < targetTime.getTime()) {
    seconds = Math.round((targetTime.getTime() - currentTime.getTime()) / 1000)
  }

  const days = Math.floor(seconds / 86400)
  seconds -= days * 86400
  const hours = Math.floor(seconds / 3600) % 24
  seconds -= hours * 3600
  const minutes = Math.floor(seconds / 60) % 60
  seconds -= minutes * 60
  return { days, hours, minutes, seconds }
}

const Countdown = ({
  currentTime,
  inverse,
  startTime,
  text,
  modifiers,
  tbc,
}) => {
  const { days, hours, minutes } = getCountdownUnits(currentTime, startTime)
  const modifiersArray = modifiers ? modifiers.split(',') : []
  const countdownClassNames = classNames(
    'countdown',
    'js-countdown',
    { 'countdown--inverse': inverse },
    modifiersArray &&
      modifiersArray.map((modifierClass) => `countdown--${modifierClass}`)
  )

  return (
    <div
      className={countdownClassNames}
      data-tbc={tbc || null}
      data-start-time={startTime}
      data-text={text}
      data-modifiers={modifiers}
    >
      <div className="countdown__timer">
        {text && (
          <div className="js-countdown-text countdown__text">{text}</div>
        )}
        <div className="countdown__block">
          <div className="countdown__number">{!tbc ? pad(days) : 'T'}</div>
          {!tbc && <div className="countdown__label">Days</div>}
        </div>
        <div className="countdown__block">
          <div className="countdown__number">{!tbc ? pad(hours) : 'B'}</div>
          {!tbc && <div className="countdown__label">Hours</div>}
        </div>
        <div className="countdown__block">
          <div className="countdown__number">{!tbc ? pad(minutes) : 'C'}</div>
          {!tbc && <div className="countdown__label">Mins</div>}
        </div>
      </div>
    </div>
  )
}

Countdown.propTypes = {
  currentTime: PropTypes.instanceOf(Date),
  startTime: PropTypes.instanceOf(Date).isRequired,
  text: PropTypes.string,
  modifiers: PropTypes.string,
  tbc: PropTypes.any,
  inverse: PropTypes.bool,
}

export default Countdown
