import debug from 'debug'
const log = debug('jump-menu:log')

export default () => {
  log('init')

  const jumpMenu = document.querySelector('.widget--jump-menu')
  if (!jumpMenu) return false
  let activeSet = false

  const jumpMenuItems = [ ...jumpMenu.querySelectorAll('.action-list__item') ]

  jumpMenuItems.forEach((item) => {
    const anchor = item.firstChild.getAttribute('href').slice(1)

    if (!document.querySelector(`a[name="${anchor}"]`))
      item.classList.add('is-hidden')

    if (!activeSet && !item.classList.contains('is-hidden')) {
      item.classList.add('is-active')
      activeSet = true
    }

    item.addEventListener('click', () => {
      ;[ ...jumpMenu.querySelectorAll('.is-active') ].forEach((i) => {
        i.classList.remove('is-active')
      })
      item.classList.add('is-active')
    })
  })
}
