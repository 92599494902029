import Swiper from 'swiper/dist/js/swiper.js'
const debug = require('debug')('carousel')

const init = () => {
  debug('Init')
  document.addEventListener('DOMContentLoaded', initSwipers)
  document.addEventListener('deviceready', initSwipers)
}

const initSwipers = () => {
  const carousels = [
    ...document.querySelectorAll('.js-carousel:not(.is-initialised)'),
  ]
  if (carousels.length < 1) return false

  carousels.forEach((carousel) => {
    const prev = carousel.querySelector('.js-carousel-prev')
    const next = carousel.querySelector('.js-carousel-next')
    const container = carousel.querySelector('.js-carousel-container')

    const swiperInstance = new Swiper(container, {
      speed: 500,
      loop: true,
      autoHeight: true,
      autoplay: container.dataset.autoplay && {
        delay: 3000,
      },
      slidesPerView: parseInt(container.dataset.slidesperviewdesktop),
      slidesPerGroup: parseInt(container.dataset.slidesperviewdesktop),
      spaceBetween: 20,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
      breakpoints: {
        549: {
          slidesPerView: 1,
          slidesPerGroup: 1,
        },
        959: {
          slidesPerView: parseInt(container.dataset.slidesperviewtablet),
          slidesPerGroup: parseInt(container.dataset.slidesperviewtablet),
        },
      },
    })

    container.dataset.autoplay && swiperInstance.autoplay.start()

    prev.addEventListener('click', () => {
      swiperInstance.slidePrev()
    })
    next.addEventListener('click', () => {
      swiperInstance.slideNext()
    })

    carousel.classList.add('is-initialised')
  })
}

export { init, initSwipers }
