const statuses = [
  {
    text: 'Fixture',
    value: 'Fixture',
    hasScore: false,
  },
  {
    text: 'Full Time',
    value: 'Result',
    hasScore: true,
  },
  {
    text: 'Abandoned',
    value: 'Abandoned',
    hasScore: false,
  },
  {
    text: 'Cancelled',
    value: 'Cancelled',
    hasScore: false,
  },
  {
    text: 'Postponed',
    value: 'Postponed',
    hasScore: false,
  },
  {
    text: 'Team In',
    value: 'Team In',
    hasScore: false,
  },
  {
    text: 'First Half',
    value: 'First half',
    hasScore: true,
  },
  {
    text: 'Half Time',
    value: 'Halftime',
    hasScore: true,
  },
  {
    text: 'Second Half',
    value: 'Second half',
    hasScore: true,
  },
  {
    text: 'Extra Time First Half',
    value: 'Extra Time First Half',
    hasScore: true,
  },
  {
    text: 'Extra Time Half Time',
    value: 'Extra Time Half Time',
    hasScore: true,
  },
  {
    text: 'Extra Time Second Half',
    value: 'Extra Time Second Half',
    hasScore: true,
  },
  {
    text: 'Shoot Out',
    value: 'Shoot Out',
    hasScore: true,
  },
  {
    text: 'Sudden Death',
    value: 'Sudden Death',
    hasScore: true,
  },
]

module.exports.all = statuses
module.exports.hasScore = statuses.filter((status) => status.hasScore)
