import React from 'react'
import ReactDOM from 'react-dom'
import debug from 'debug'
import JumbotronContainer from '../../../../common/matchday-live/JumbotronContainer'

const log = debug('matchday-live:jumbotron:log')

export default (appNodes, primus, match) => {
  log('init')

  appNodes.forEach((appNode) => {
    ReactDOM.render(
      <JumbotronContainer
        match={match}
        primus={primus}
        commentary={match.__commentary}
      />,
      appNode
    )
  })
}
