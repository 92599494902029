import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Field from '../../primitive/field/component/Field'
import referrerOptions from '../lib/referrer-options'
import ReCAPTCHA from 'react-google-recaptcha'
import crypto from 'crypto'

const StadiumMap = class extends React.PureComponent {
  render() {
    return (
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5327.858476452815!2d-0.9202416578702419!3d52.24102024406159!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x89c47c82e46cd3f1!2sNorthampton+Saints!5e0!3m2!1sen!2suk!4v1526315565428"
        width="100%"
        height="450"
        frameBorder="0"
        style={{ border: 0 }}
      />
    )
  }
}

const FoundationMap = class extends React.PureComponent {
  render() {
    return (
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2443.2626744563554!2d-0.920520383724522!3d52.2386123797619!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48770f66714e2629%3A0xfabed1474ac5dfd3!2sNorthampton%20Saints%20Foundation!5e0!3m2!1sen!2suk!4v1586343517216!5m2!1sen!2suk"
        width="100%"
        height="450"
        frameBorder="0"
        style={{ border: 0 }}
      />
    )
  }
}

const FranklinsGardensMap = class extends React.PureComponent {
  render() {
    return (
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9772.718376724853!2d-0.9193845!3d52.2401215!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc1354511e5b63bd5!2sFranklin&#39;s%20Gardens!5e0!3m2!1sen!2suk!4v1589890207539!5m2!1sen!2suk"
        width="100%"
        height="450"
        frameBorder="0"
        style={{ border: 0 }}
      />
    )
  }
}

class Enquiry extends React.Component {
  constructor(props) {
    super(props)
    this.toEmailAddress = this.props.toEmailAddress
    this.checksum = crypto
      .createHmac('sha256', 'enquiry-salt')
      .update(this.toEmailAddress)
      .digest('hex')
    this.onChange = this.props.onChange
    this.setValues = this.props.setValues
  }

  componentDidMount() {
    const defaults = {
      checksum: this.checksum,
      toEmailAddress: this.toEmailAddress,
    }

    this.setValues(defaults)
  }

  render() {
    const {
      heading = 'Make an enquiry',
      children,
      onSubmit,
      onChange,
      displayMap,
      mapToShow,
      inverse,
      displayReferrer,
      displayNewsletter,
      successMessage,
      error = {},
      errorMessage,
      redirectUrl,
      instanceName
    } = this.props

    // Function to set the reCaptcha value once clicked
    const verifyCallback = (response) => {
      this.setValues({
        captcha: response,
      })
    }

    return (
      <div className={classNames('enquiry', inverse && 'inverse')}>
        {error && Object.keys(error).length > 0 && (
          <div className="content-center--small content-center content-gutter">
            <div className="notification notification--error">
              <h3 className="notification__title">
                There was a problem with your submission.
              </h3>
              {errorMessage && (
                <p dangerouslySetInnerHTML={{ __html: errorMessage }} />
              )}
              {!errorMessage && <p>Please check below and try again.</p>}
            </div>
          </div>
        )}
        {!redirectUrl && successMessage ? (
          <div className="content-center content-center--small content-gutter enquiry__content">
            <h3 className="enquiry__heading">Thank you for your enquiry</h3>
            <p>
              Someone will come back to you as soon as possible – please note,
              it might take up to five working days for our team to respond to
              your enquiry.
            </p>
            <p>
              Please do not submit another enquiry before five working days have
              passed, as this could move your original message back to the
              bottom of our list.
            </p>
            <ul className="list list--spaced list--inline">
              <li>
                <a href="/" className="button button--primary">
                  Back to the Home page
                </a>
              </li>
            </ul>
          </div>
        ) : (
          <div
            className={classNames({
              'content-center content-center--small': !displayMap,
            })}
          >
            <div className="grid">
              {displayMap && (
                <div className="grid__item tablet-one-half">
                  {mapToShow === 'foundationOffice' && <FoundationMap />}
                  {mapToShow === 'franklinsGardens' && <FranklinsGardensMap />}
                  {mapToShow === 'stadium' && <StadiumMap />}
                </div>
              )}
              <div
                className={classNames('grid__item', {
                  'tablet-one-half': displayMap,
                })}
              >
                <div className="content-gutter enquiry__content">
                  <Fragment>
                    <h2 className="enquiry__heading">{heading}</h2>
                    {children}
                    <p>
                      Please ensure you read and agree to our{' '}
                      <a href="/legal/privacy-policy">Privacy Policy</a> and{' '}
                      <a href="/legal/terms">Terms & Conditions</a>.
                    </p>
                    <form
                      className="form"
                      action="/enquiry"
                      method="post"
                      onSubmit={onSubmit}
                    >
                      <input type="hidden" name="form" value="enquiry" />
                      <div className="grid">
                        <div
                          className={classNames(
                            'grid__item grid__item-next-to-label',
                            displayMap ? 'desktop-one-half' : 'tablet-one-half'
                          )}
                        >
                          <Field
                            name="name"
                            type="text"
                            placeholder="Name"
                            onChange={onChange}
                            error={error.name}
                          />
                        </div>
                        <div
                          className={classNames(
                            'grid__item',
                            displayMap ? 'desktop-one-half' : 'tablet-one-half'
                          )}
                        >
                          <label htmlFor="dob">Date of Birth:</label>
                          <Field
                            name="dob"
                            type="date"
                            placeholder="DOB"
                            onChange={onChange}
                          />
                        </div>
                        <div
                          className={classNames(
                            'grid__item',
                            displayMap ? 'desktop-one-half' : 'tablet-one-half'
                          )}
                        >
                          <Field
                            name="email"
                            type="text"
                            placeholder="Email"
                            error={error.email}
                            onChange={onChange}
                          />
                        </div>
                        <div
                          className={classNames(
                            'grid__item',
                            displayMap ? 'desktop-one-half' : 'tablet-one-half'
                          )}
                        >
                          <Field
                            name="telephone"
                            type="text"
                            placeholder="Telephone"
                            onChange={onChange}
                            error={error.telephone}
                          />
                        </div>
                        {instanceName === 'Franklins Gardens' && (
                          <div>
                            <div
                              className={classNames(
                                'grid__item',
                                displayMap
                                  ? 'desktop-one-half'
                                  : 'tablet-one-half'
                              )}
                            >
                              <Field
                                name="eventType"
                                type="text"
                                placeholder="Event Type"
                                error={error.eventType}
                                onChange={onChange}
                              />
                            </div>
                            <div
                              className={classNames(
                                'grid__item',
                                displayMap
                                  ? 'desktop-one-half'
                                  : 'tablet-one-half'
                              )}
                            >
                              <Field
                                name="numOfGuests"
                                type="text"
                                placeholder="Number of Guests"
                                error={error.numOfGuests}
                                onChange={onChange}
                              />
                            </div>
                            <div className="grid__item">
                              <label htmlFor="eventDateField">Date of the Event:</label>
                              <Field
                                id="eventDateField"
                                name="eventDate"
                                type="date"
                                placeholder="Date of the event"
                                error={error.eventDate}
                                onChange={onChange}
                              />
                            </div>
                          </div>
                        )}
                        {displayReferrer && (
                          <div className="grid__item">
                            <Field
                              name="referrer"
                              type="select"
                              options={referrerOptions()}
                              label="Where did you hear about Franklin's Gardens?"
                            />
                          </div>
                        )}
                        <div className="grid__item">
                          <Field
                            name="enquiry"
                            type="textarea"
                            placeholder="Message"
                            onChange={onChange}
                          />
                        </div>
                        {displayNewsletter && (
                          <div className="grid__item">
                            <Field
                              name="nsaintsOptIn"
                              type="checkbox"
                              onChange={onChange}
                              options={[
                                {
                                  text:
                                    'Please tick if you would like to be kept up to date on the latest news, offers and competitions from Northampton Saints.',
                                  value: 'yes',
                                },
                              ]}
                            />
                          </div>
                        )}
                        <div className="grid__item desktop-one-half">
                          <ReCAPTCHA
                            sitekey="6Leas1wUAAAAAAiX2RwgzCybOxsiKK2z5RQqpMtL"
                            onChange={verifyCallback}
                            badge="inline"
                            className="recaptcha"
                          />
                          {/* Use an empty checkbox to display captcha validation. Once captcha selected, set captcha value */}
                          <Field
                            name="captcha"
                            type="checkbox"
                            options={[]}
                            error={error.captcha}
                          />
                        </div>
                        <div className="grid__item desktop-one-half">
                          <button
                            type="submit"
                            className="button button--primary button--float-right"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </Fragment>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

Enquiry.propTypes = {
  heading: PropTypes.string.isRequired,
  toEmailAddress: PropTypes.string.isRequired,
  children: PropTypes.node,
  displayMap: PropTypes.bool,
  mapToShow: PropTypes.string,
  inverse: PropTypes.bool,
  displayReferrer: PropTypes.bool,
  displayNewsletter: PropTypes.bool,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  setValues: PropTypes.func,
  error: PropTypes.object,
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  redirectUrl: PropTypes.string,
  instanceName: PropTypes.string,
}

export default Enquiry
