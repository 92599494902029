import React from 'react'
import PropTypes from 'prop-types'
import BigCalendar from 'react-big-calendar'
import moment from 'moment'

import CalendarEvent from './CalendarEvent'
import CalendarToolbar from './CalendarToolbar'

BigCalendar.momentLocalizer(moment)

const Calendar = ({ events, defaultDate }) => (
  <BigCalendar
    components={{
      event: CalendarEvent,
      toolbar: CalendarToolbar,
    }}
    defaultDate={defaultDate || new Date()}
    events={events || []}
    popup
    views={[ 'month' ]}
  />
)

Calendar.propTypes = {
  events: PropTypes.array,
  defaultDate: PropTypes.instanceOf(Date),
}

export default Calendar
