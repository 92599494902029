import 'whatwg-fetch'
import inViewport from 'in-viewport'
import debug from 'debug'
const log = debug('load-more:log')
const error = debug('load-more:error')

/*
 * Log all of the arguments passed to an error handler function
 */
const logError = (err) => {
  error('Request for widgets failed', err.message)
}

const initLoadMore = (el, i) => {
  if (el.className.indexOf('js-load-more--initialized') > -1) return

  const btn = el.getElementsByClassName('js-load-more-btn')[0]
  const detector = el.getElementsByClassName('js-scroll-detector')[0]
  const target = el.getElementsByClassName('js-load-more-target')[0]
  const loadMoreContainer = el.getElementsByClassName('load-more')[0]
  const ids = el.getAttribute('data-load-more-ids').split(',')
  const url = el.getAttribute('data-load-more-url')
  const widgetJson = el.getAttribute('data-widget')

  const msgPrefix = 'Trying to init load more functionality but '
  const widget = JSON.parse(widgetJson)
  const pageSize = widget.loadMorePageSize
  let currentPage = 0

  el.classList.add('js-load-more--initialized')

  if (!ids.length) return log(msgPrefix + 'no ids to load')
  if (!url) return log(msgPrefix + 'no load more url found')
  if (!target) return log(msgPrefix + 'no load more target found', el)

  const next = () => {
    const start = currentPage * pageSize
    const end = start + pageSize
    const toLoad = ids.slice(start, end)
    const lastItem = target.lastChild

    if (!toLoad.length) log('No more articles to load')
    if (end >= ids.length && (btn || detector))
      loadMoreContainer.style.display = 'none'
    currentPage++

    log('Loading more articles', toLoad)

    const queryIds = toLoad.join(',')
    const fullUrl =
      url + '?ids=' + queryIds + '&widget=' + encodeURIComponent(widgetJson)

    const injectArticles = (articlesHtml) => {
      const el = document.createElement('div')
      el.innerHTML = articlesHtml
      Array.from(el.children).forEach((child) => target.appendChild(child))

      // After the articles have been loaded, wait for the scroll detector element to be scrolled into view again
      if (widget.loadMoreMethod === 'scroll') inViewport(detector, next)

      // Focus the first link in the new content
      lastItem.nextSibling.querySelector('a:not([tabindex="-1"])').focus()
    }

    fetch(fullUrl)
      .then((response) => response.json())
      .then(injectArticles)
      .catch(logError)
  }

  switch (widget.loadMoreMethod) {
    case 'scroll':
      if (!detector)
        return log(msgPrefix + 'no scroll detector element found', el)
      inViewport(detector, next)
      break
    default:
      if (!btn) return log(msgPrefix + 'no load more button found', el)
      btn.addEventListener('click', (e) => {
        e.preventDefault()
        next()
      })
      break
  }
}

export default () => {
  log('Init Load More Component')

  // TODO: Fix when infinite article loading is implemented
  // serviceLocator.hub.on('widgetsLoaded', function () {
  //   $('.js-load-more').each(initLoadMore)
  // })

  Array.from(document.querySelectorAll('.js-load-more')).forEach(initLoadMore)
}
