import React from 'react'
import ReactDOM from 'react-dom'
import EnquirySalesforce from '../../../site/layout/component/enquiry/component/EnquirySalesforce'
import createFormSubmissionContainer from '../../lib/form-submission-salesforce'
import phoneNumber from 'validity-telephone-number'
import required from '@clocklimited/validity-required'
import isEmail from '@clocklimited/validity-email'
import schemata from 'schemata'
import createDebug from 'debug'

const debug = createDebug('preference-centre')
const schema = schemata({
  name: 'Enquiry Sales Force',
  properties: {
    first_name: { name: 'First Name', type: String, validators: [ required ] },
    last_name: { name: 'Last Name', type: String, validators: [ required ] },
    email: { type: String, validators: [ required, isEmail ] },
    '00N4J00000B811j': {
      name: 'Telephone',
      type: String,
      validators: [ required, phoneNumber ],
    },
    '00N4J00000B811V': { name: 'Age', type: String },
    '00N4J00000B811eUAB': {
      name: 'Message',
      type: String,
      validators: [ required ],
    },
    captcha: {
      type: String,
      validators: [ required.setFailureMessage('Captcha must be checked') ],
    },
  },
})

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    const appNode = document.getElementById('enquiry-salesforce')
    if (!appNode) {
      return debug('No enquiry node found. Skipping.')
    }
    const infoHtml = appNode.dataset.html
    const header = appNode.dataset.header
    const displayMap = appNode.dataset.displayMap
    const inverse = appNode.dataset.inverse
    const instanceName = appNode.dataset.instanceName
    const signUpLink = appNode.dataset.signUpLink
    const oid = appNode.dataset.oid
    const returnUrl = appNode.dataset.returnUrl
    const leadSource = appNode.dataset.leadSource
    const recordType = appNode.dataset.recordType
    const salesforceEnquiryUrl = appNode.dataset.salesforceEnquiryUrl

    const Component = (props) => (
      <EnquirySalesforce
        instanceName={instanceName}
        displayMap={displayMap === 'true'}
        heading={header}
        inverse={inverse === 'true'}
        signUpLink={signUpLink}
        oid={oid}
        returnUrl={returnUrl}
        leadSource={leadSource}
        recordType={recordType}
        salesforceEnquiryUrl={salesforceEnquiryUrl}
        {...props}
      >
        <div dangerouslySetInnerHTML={{ __html: infoHtml }} />
      </EnquirySalesforce>
    )

    const FormSubmissionContainer = createFormSubmissionContainer({
      Component: Component,
      schema: schema,
      apiUrl: '/enquiry',
      errorMessage:
        'Please check your details and try again. If the problem persists, please get in touch via our <a href="/club/contact">contact page</a>.',
      successMessage: 'Success',
    })

    ReactDOM.render(<FormSubmissionContainer />, appNode)
  })
}
