import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Field from '../../primitive/field/component/Field'
import Icon from '../../../component/primitive/icon/component/Icon'
import Svgs from '../../../component/primitive/svgs/component/Svgs'

class EmailSignup extends React.Component {
  constructor(props) {
    super(props)
    this.setValues = this.props.setValues
    this.emailSignUpOptions = this.props.emailSignUpOptions || []
  }

  componentDidMount() {
    const defaults = {
      emailSignUpOptions: this.emailSignUpOptions,
    }

    this.setValues(defaults)
  }

  render() {
    const {
      successMessage,
      errorMessage,
      error = {},
      onChange,
      onSubmit,
      flamboyant,
      emailSignUpTitle,
      emailSignUpText,
      emailSignUpOptions = [],
    } = this.props

    return (
      <div
        className={classNames('email-signup', {
          'email-signup--flamboyant': flamboyant,
        })}
      >
        <div className="content-center content-gutter">
          <div className="email-signup__inner">
            <div className="grid grid--valign-middle">
              <div
                className={classNames('grid__item', {
                  'desktop-two-fifths': !flamboyant,
                })}
              >
                <h3 className="email-signup__heading">
                  {emailSignUpTitle || 'Sign Up'}
                </h3>
                {successMessage && Object.keys(error).length === 0 ? (
                  <p className="email-signup__message">{successMessage}</p>
                ) : (
                  <p>{emailSignUpText}</p>
                )}
              </div>
              <div
                className={classNames('grid__item', {
                  'desktop-three-fifths': !flamboyant,
                })}
              >
                {!successMessage && (
                  <form className="form" method="post" onSubmit={onSubmit}>
                    <input
                      type="hidden"
                      name="emailSignUpOptions"
                      value={JSON.stringify(emailSignUpOptions)}
                    />
                    {errorMessage && (
                      <div className="notification notification--error">
                        <p dangerouslySetInnerHTML={{ __html: errorMessage }} />
                      </div>
                    )}
                    <div className="grid grid--valign-middle">
                      <div
                        className={classNames(
                          'grid__item',
                          flamboyant ? 'tablet-one-half' : 'tablet-one-third'
                        )}
                      >
                        <Field
                          name="fullName"
                          type="text"
                          showRequired={false}
                          onChange={onChange}
                          placeholder="Name"
                          error={error.fullName}
                          aria-label="Name"
                          id="Name--Signup"
                        />
                      </div>
                      <div
                        className={classNames(
                          'grid__item',
                          flamboyant ? 'tablet-one-half' : 'tablet-one-third'
                        )}
                      >
                        <Field
                          name="email"
                          type="email"
                          showRequired={false}
                          onChange={onChange}
                          placeholder="Email"
                          error={error.email}
                          aria-label="Email"
                          id="Email--Signup"
                        />
                      </div>
                      <div
                        className={classNames(
                          'grid__item',
                          flamboyant ? '' : 'tablet-one-third'
                        )}
                      >
                        <button
                          className="button button--transparent button--block button--icon email-signup__button"
                          type="submit"
                        >
                          <Icon modifiers={[ 'round' ]} height={10} width={8}>
                            <Svgs.ArrowRight />
                          </Icon>
                          Register
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
            <a className="email-signup__small" href="/legal/privacy-policy">
              Privacy policy
            </a>
            <a className="email-signup__small" href="/legal/terms">
              Terms &amp; conditions
            </a>
            <a className="email-signup__small" href="/legal/cookie-policy">
              Cookie policy
            </a>
          </div>
        </div>
        {flamboyant && <div className="email-signup__background" />}
        <div className="email-signup__shape">
          {flamboyant && <div className="email-signup__image" />}
        </div>
      </div>
    )
  }
}

EmailSignup.propTypes = {
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  emailSignUpTitle: PropTypes.string,
  emailSignUpText: PropTypes.string,
  error: PropTypes.object,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  setValues: PropTypes.func,
  flamboyant: PropTypes.bool,
  emailSignUpOptions: PropTypes.array,
}

export default EmailSignup
