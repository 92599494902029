import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Field from '../../primitive/field/component/Field'
import ReCAPTCHA from 'react-google-recaptcha'

const GoogleMap = class extends React.PureComponent {
  render() {
    return (
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5327.858476452815!2d-0.9202416578702419!3d52.24102024406159!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x89c47c82e46cd3f1!2sNorthampton+Saints!5e0!3m2!1sen!2suk!4v1526315565428"
        width="100%"
        height="450"
        frameBorder="0"
        style={{ border: 0 }}
      />
    )
  }
}

class EnquirySalesforce extends React.Component {
  constructor(props) {
    super(props)
    this.salesforceEnquiryUrl = this.props.salesforceEnquiryUrl
    this.oid = this.props.oid
    this.returnUrl = this.props.returnUrl
    this.leadSource = this.props.leadSource
    this.recordType = this.props.recordType
    this.onChange = this.props.onChange
    this.setValues = this.props.setValues
  }

  render() {
    const {
      heading = 'Make an enquiry',
      children,
      onSubmit,
      onChange,
      displayMap,
      inverse,
      signUpLink,
      successMessage,
      error = {},
      errorMessage,
      instanceName,
    } = this.props

    // Function to set the reCaptcha value once clicked
    const verifyCallback = (response) => {
      if (typeof document !== 'undefined') {
        setInterval(() => {
          const elems = JSON.parse(
            document.getElementsByName('captcha_settings')[0].value
          )
          if (!elems['ts']) {
            elems['ts'] = JSON.stringify(new Date().getTime())
            document.getElementsByName(
              'captcha_settings'
            )[0].value = JSON.stringify(elems)
          }
        }, 500)
      }

      this.setValues({
        captcha: response,
      })
    }

    return (
      <div className={classNames('enquiry-salesforce', inverse && 'inverse')}>
        {error && Object.keys(error).length > 0 && (
          <div className="content-center--small content-center content-gutter">
            <div className="notification notification--error">
              <h3 className="notification__title">
                There was a problem with your submission.
              </h3>
              {errorMessage && (
                <p dangerouslySetInnerHTML={{ __html: errorMessage }} />
              )}
              {!errorMessage && <p>Please check below and try again.</p>}
            </div>
          </div>
        )}
        {successMessage ? (
          <div className="content-center content-center--small content-gutter enquiry__content">
            <h2 className="enquiry__heading">Thank you for your enquiry</h2>
            <p>Someone will come back to you as soon as possible with a response.</p>
            <p>
              Please note that during the COVID-19 pandemic, we are operating with fewer staff than usual,
              and dealing with a huge volume of enquiries. Therefore it could take up to ten working days for
              us to answer your enquiry. Please do not submit another enquiry before ten working days have
              passed, as this could move your original message back to the bottom of our list.
            </p>
            <p>We appreciate your patience during this challenging time.</p>
            <p>Want to here more from {instanceName}?</p>
            <ul className="list list--spaced list--inline">
              <li>
                <a href={signUpLink} className="button button--primary">
                  Sign up to our newsletter
                </a>
              </li>
              <li>
                <a href="/" className="button button--primary">
                  Back to the Home page
                </a>
              </li>
            </ul>
          </div>
        ) : (
          <div
            className={classNames({
              'content-center content-center--small': !displayMap,
            })}
          >
            <div className="grid">
              {displayMap && (
                <div className="grid__item tablet-one-half">
                  <GoogleMap />
                </div>
              )}
              <div
                className={classNames('grid__item', {
                  'tablet-one-half': displayMap,
                })}
              >
                <div className="content-gutter enquiry__content">
                  <Fragment>
                    <h3 className="enquiry__heading">{heading}</h3>
                    {children}
                    <p>
                      Please ensure you read and agree to our{' '}
                      <a href="/legal/privacy-policy">Privacy Policy</a> and{' '}
                      <a href="/legal/terms">Terms & Conditions</a>.
                    </p>
                    <form
                      className="form js-enquiry-salesforce-form"
                      action={this.salesforceEnquiryUrl}
                      method="post"
                      onSubmit={onSubmit}
                    >
                      <input
                        type="hidden"
                        name="captcha_settings"
                        value={`{"keyname":"NorthamptonSaintsHospitality","fallback":"true","orgId":"${this.oid}","ts":""}`}
                      />
                      <input type="hidden" name="oid" value={this.oid} />
                      <input
                        type="hidden"
                        name="retURL"
                        value={this.returnUrl}
                      />
                      <input
                        type="hidden"
                        name="lead_source"
                        value={this.leadSource}
                      />
                      <input
                        type="hidden"
                        name="recordType"
                        id="recordType"
                        value={this.recordType}
                      />

                      {/* Uncomment the below lines to test the form submission is submitting correctly to SF */}
                      {/* <input type="hidden" name="debug" value={1} />
                      <input type="hidden" name="debugEmail" value="adam.duncan@clock.co.uk" /> */}

                      <div className="grid">
                        <div
                          className={classNames(
                            'grid__item',
                            displayMap ? 'desktop-one-half' : 'tablet-one-half'
                          )}
                        >
                          <Field
                            id="first_name"
                            name="first_name"
                            type="text"
                            placeholder="First Name"
                            onChange={onChange}
                            error={error.first_name}
                          />
                        </div>
                        <div
                          className={classNames(
                            'grid__item',
                            displayMap ? 'desktop-one-half' : 'tablet-one-half'
                          )}
                        >
                          <Field
                            id="last_name"
                            name="last_name"
                            type="text"
                            placeholder="Last Name"
                            onChange={onChange}
                            error={error.last_name}
                          />
                        </div>
                        <div
                          className={classNames(
                            'grid__item',
                            displayMap ? 'desktop-one-half' : 'tablet-one-half'
                          )}
                        >
                          <Field
                            id="email"
                            name="email"
                            type="text"
                            placeholder="Email"
                            error={error.email}
                            onChange={onChange}
                          />
                        </div>
                        <div
                          className={classNames(
                            'grid__item',
                            displayMap ? 'desktop-one-half' : 'tablet-one-half'
                          )}
                        >
                          <Field
                            id="00N4J00000B811j"
                            name="00N4J00000B811j"
                            type="text"
                            placeholder="Telephone"
                            onChange={onChange}
                            error={error['00N4J00000B811j']}
                          />
                        </div>
                        <div
                          className={classNames(
                            'grid__item',
                            displayMap ? 'desktop-one-half' : 'tablet-one-half'
                          )}
                        >
                          <Field
                            id="00N4J00000B811V"
                            name="00N4J00000B811V"
                            type="text"
                            placeholder="Age"
                            onChange={onChange}
                          />
                        </div>
                        <div className="grid__item">
                          <Field
                            id="00N4J00000B811eUAB"
                            name="00N4J00000B811eUAB"
                            type="textarea"
                            placeholder="Message"
                            onChange={onChange}
                            error={error['00N4J00000B811eUAB']}
                          />
                        </div>
                        <div className="grid__item desktop-one-half">
                          <ReCAPTCHA
                            sitekey="6LfRJ-AUAAAAAKDlRdcdwqhHMVpyPV5hYMdn3Xot"
                            onChange={verifyCallback}
                            badge="inline"
                            className="recaptcha"
                          />
                          {/* Use an empty checkbox to display captcha validation. Once captcha selected, set captcha value */}
                          <Field
                            name="captcha"
                            type="checkbox"
                            options={[]}
                            error={error.captcha}
                          />
                        </div>
                        <div className="grid__item desktop-one-half">
                          <button
                            name="submit"
                            type="submit"
                            className="button button--primary button--float-right"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </Fragment>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

EnquirySalesforce.propTypes = {
  heading: PropTypes.string.isRequired,
  children: PropTypes.node,
  displayMap: PropTypes.bool,
  inverse: PropTypes.bool,
  signUpLink: PropTypes.string,
  instanceName: PropTypes.string,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  setValues: PropTypes.func,
  error: PropTypes.object,
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  salesforceEnquiryUrl: PropTypes.string.isRequired,
  oid: PropTypes.string.isRequired,
  returnUrl: PropTypes.string.isRequired,
  leadSource: PropTypes.string.isRequired,
  recordType: PropTypes.string.isRequired,
}

export default EnquirySalesforce
