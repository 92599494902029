import React from 'react'
import Summary from '../../../../layout/component/primitive/summary/component/Summary'
import Icon from '../../../../layout/component/primitive/icon/component/Icon'
import Svgs from '../../../../layout/component/primitive/svgs/component/Svgs'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const Thumbnail = (props) => {
  const { video, onClick, active } = props

  const onThumbnailClick = (e) => {
    e.preventDefault()

    onClick(video)
  }

  return (
    <div
      className={classNames('swiper-slide', {
        'js-is-active swiper-slide--active': active,
      })}
      data-video={video && video.videoId}
      onClick={onThumbnailClick}
    >
      <div className="video-playlist__wrapper">
        <Summary
          tag="SaintsTV"
          imgUrl={video && video.thumbnail && video.thumbnail.url}
          modifiers={[ 'video', 'video-playlist__item' ]}
          title={video && video.title}
          meta={video && video.date}
          imageWidth={317}
          imageHeight={237}
        />
      </div>
    </div>
  )
}

Thumbnail.propTypes = {
  video: PropTypes.object,
  onClick: PropTypes.func,
  active: PropTypes.bool,
}

class VideoPlaylist extends React.Component {
  constructor(...props) {
    super(...props)

    this.state = {
      videos: this.props.videos || [],
      activeVideo: this.props.videos[0] || {},
    }
  }

  render() {
    const onClick = (video) => {
      this.setState({
        activeVideo: video,
      })

      this.props.onThumbnailClick(video)
    }

    return (
      <div className="content-center">
        <div className="video-playlist js-video-playlist-wrapper">
          <div className="is-hidden--nojs">
            <div className="video-playlist__button video-playlist-prev js-video-playlist-prev">
              <div className="video-playlist-button__icon">
                <Icon width={10} height={20} modifiers={[ 'round' ]}>
                  <Svgs.ArrowLeft />
                </Icon>
              </div>
            </div>
            <div className="video-playlist__button video-playlist-next js-video-playlist-next">
              <div className="video-playlist-button__icon">
                <Icon width={10} height={20} modifiers={[ 'round' ]}>
                  <Svgs.ArrowRight />
                </Icon>
              </div>
            </div>
            <div className="swiper-container js-video-playlist">
              <div className="swiper-wrapper video-playlist__player">
                {this.state.videos.map((video, index) => (
                  <Thumbnail
                    active={
                      video && video.videoId === this.state.activeVideo.videoId
                    }
                    video={video}
                    onClick={onClick}
                    key={index}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="is-hidden--js">
            <div className="video-playlist__player">
              <div className="grid">
                {this.state.videos.slice(0, 4).map((video, index) => (
                  <div
                    className="grid__item one-half tablet-one-quarter"
                    key={index}
                  >
                    <Summary
                      modifiers={[ 'video', 'video-playlist__item' ]}
                      imgUrl={video && video.thumbnail && video.thumbnail.url}
                      title={video && video.title}
                      imageWidth={480}
                      imageHeight={360}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

VideoPlaylist.propTypes = {
  videos: PropTypes.array,
  onThumbnailClick: PropTypes.func,
}

export default VideoPlaylist
