import React from 'react'

const FabRugbyBall = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 227 262"
    {...props}
  >
    <path
      d="M198.8 18.1L214.2 43.4L224 90.3L210.9 130.9L179.5 187L154.2 215.9L128.6 237.3L89.2 254.2L58.4 254.8C58.4 254.8 31 250 29.2 245.2C27.4 240.3 5.60001 217 5.60001 217L6.8 187L15.8 131.5C15.8 131.5 31.8 93.8 33.1 91.8C34.4 89.8 61.9 59.6 61.9 59.6L80.2 38.3L108.3 22.4C108.3 22.4 127.9 13.2 129.6 12.8C131.3 12.4 160.9 6.29999 160.9 6.29999L177.6 7.89999L198.8 18.1Z"
      fill="white"
    />
    <path
      d="M17.3 233.1C92.1 250.7 213.8 119.3 213.8 119.3C229.2 133.8 176 194.8 176 194.8L147.9 222.2L104.9 242.3L58.6 254.8C58.6 254.8 30.5 246.7 29.4 245.2C28.2 243.6 13.1 232.1 17.3 233.1Z"
      fill="#D7D7D7"
    />
    <path
      d="M3.4 209.4C-1.1 186.4 0.200002 166.1 5.7 146.2C20.9 90.7 52.8 47.6 102.4 18.2C118.8 8.50001 136.6 2.09999 155.9 0.899995C173.5 -0.200005 189.8 3.39999 203.3 15.4C213.9 24.8 219.9 37 223.3 50.5C228.5 71.4 227.5 92.3 222.2 112.8C207.7 169.4 175.6 213.1 125.4 243.3C112.5 251 98.6 256.4 83.8 259.3C70.5 261.8 57.2 262 44.1 257.8C25.9 251.9 14.2 239.2 7.4 221.8C5.5 217 4.2 211.9 3.4 209.4ZM62.4 244.4C78.7 240.7 92.7 232.4 105.6 222.2C125.3 206.8 141.6 188.2 156.3 168.1C173.5 144.4 188.8 119.7 198.2 91.7C203.5 75.6 206.7 59.2 203.7 42.1C202.9 37.8 202.3 32.9 199.8 29.8C192.3 20.2 182.9 14.2 169.6 16.5C153.4 19.3 139.4 26.4 126.3 35.9C107.8 49.3 92.5 65.8 78.6 83.7C60.5 106.9 44.4 131.4 33.3 158.8C26.2 176.2 21.4 194.1 23 213.1C23.5 219.4 25.7 225.5 27.1 231.6C34.1 239.2 41.3 246.3 52.8 245.4C56 245.2 59.2 244.7 62.4 244.4ZM16.9 211.8C17.1 206.9 17.1 203 17.4 199C19 180.6 24.8 163.4 32.7 146.8C48.5 113.5 69.5 83.7 94.7 56.9C112.8 37.7 132.9 21.4 158.7 13.3C157.6 13.2 156.5 13.1 155.4 13.2C140 14.5 125.7 19.5 112.2 26.7C83.2 42.1 60.7 64.4 43 91.7C26.3 117.4 15.2 145.2 13.3 176.2C12.7 187.9 13.3 199.6 16.9 211.8ZM69.8 248.6C72.1 248.4 74.5 248.3 76.8 247.9C94.6 245.2 110.7 238.3 125.7 228.7C157.7 208.1 181.2 180.1 197.5 145.9C209.2 121.4 215.9 95.7 213.8 68.2C213.3 62 212.5 55.7 209.9 49.1C210.6 71.8 205.1 90.4 198.1 106.5C180.7 146.5 155.9 181.5 125.1 212.3C109.4 228.2 91.4 241.2 69.8 248.6Z"
      fill="black"
    />
  </svg>
)

export default FabRugbyBall
