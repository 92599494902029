import React from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import debounce from 'debounce'
import RugbyCampFinder from '../../../site/layout/component/rugby-camp-finder/component/RugbyCampFinder'
import createSchema from '../../../service/rugby-camp/schema'

const debug = require('debug')('rugby-camp-finder')

class RugbyCampFinderContainer extends React.Component {
  constructor(props) {
    super(props)
    this.schema = createSchema()
    this.onChange = this.onChange.bind(this)
    this.onRowClick = this.onRowClick.bind(this)
    const castRugbyCamps =
      props.rugbyCamps &&
      props.rugbyCamps.map((rugbyCamp) => this.schema.cast(rugbyCamp))
    this.state = {
      rugbyCamps: castRugbyCamps,
      postcode: '',
      mapBounds: null,
      waiting: false,
      selected: null,
      ageRanges: props.ageRanges,
      title: props.title,
      finderType: props.finderType
    }
    this.fetchRugbyCamps = debounce(this.fetchRugbyCamps, 800)
  }

  fetchRugbyCamps(postcode, type) {
    if (postcode.length < 3) return false
    this.setState({ waiting: true, error: null })
    fetch('/api/rugby-camp?postcode=' + postcode + '&type=' + type)
      .then((res) => res.json())
      .then((res) => {
        this.setState({ waiting: false })
        if (res.status !== 'OK')
          return this.setState({
            error:
              'Unable to find your location. Check you enter a valid postcode.',
          })
        this.setState({
          rugbyCamps: res.camps.map((rugbyCamp) => this.schema.cast(rugbyCamp)),
          mapBounds: null,
          selected: null,
        })
      })
      .catch((e) =>
        this.setState({
          waiting: false,
          error: 'Unable to order rugby camps by your location',
        })
      )
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.postcode !== this.state.postcode) {
      this.fetchRugbyCamps(nextState.postcode, this.state.finderType)
    }
  }

  onChange(e) {
    this.setState({
      [e.currentTarget.name]: e.currentTarget.value,
      mapBounds: null,
      selected: null,
    })
    debug('onChange', this.state)
  }

  onRowClick(rugbyCamp) {
    this.setState({
      mapBounds: {
        center: { lat: rugbyCamp.latitude, lng: rugbyCamp.longitude },
        zoom: 10,
      },
      selected: rugbyCamp,
    })
  }

  render() {
    return (
      <RugbyCampFinder
        onChange={this.onChange}
        onRowClick={this.onRowClick}
        {...this.state}
      />
    )
  }
}

RugbyCampFinderContainer.propTypes = {
  rugbyCamps: PropTypes.array.isRequired,
  ageRanges: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  finderType: PropTypes.string.isRequired
}

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    document
      .querySelectorAll('.js-rugby-camp-finder')
      .forEach((containerNode) => {
        const { title, finderType, rugbyCamps, ageRanges } = JSON.parse(
          containerNode.querySelector('.js-rugby-camp-finder-props').innerHTML
        )
        ReactDOM.render(
          <RugbyCampFinderContainer
            title={title}
            finderType={finderType}
            rugbyCamps={rugbyCamps}
            ageRanges={ageRanges}
          />,
          containerNode
        )
      })
  })
}
