import React from 'react'

const SubstitutionSvg = () => (
  <svg data-name="Layer 1" width="10" height="12" viewBox="0 0 10 15">
    <path
      vectorEffect="non-scaling-stroke"
      fill="#ffffff"
      d="M8.13 7.35l-.71-.7-6.5 6.5V11.7l-.92.92v2.16h2.16l.91-.92H1.62l6.51-6.51zM7.62 0L6.7.92h1.46l-6.51 6.5.7.71 6.51-6.51v1.45l.92-.91V0H7.62z"
    />
  </svg>
)

export default SubstitutionSvg
