import React from 'react'

const ExternalSvg = () => (
  <svg width="10" height="10" viewBox="0 0 10 10">
    <path
      d="M8.889 8.889H1.11V1.11H5V0H1.111C.494 0 0 .5 0 1.111V8.89A1.11 1.11 0 0 0 1.111 10H8.89C9.5 10 10 9.5 10 8.889V5H8.889v3.889zM6.11 0v1.111h1.995L2.644 6.572l.784.784 5.46-5.462V3.89H10V0H6.111z"
      fill="#FFF"
    />
  </svg>
)

export default ExternalSvg
