import React from 'react'

const PlusAltSvg = () => (
  <svg viewBox="0 0 50 50">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.178 11h-4.356v11.822H11v4.356h11.822V39h4.356V27.178H39v-4.356H27.178V11Z"
      fill="#fff"
    />
  </svg>
)

export default PlusAltSvg
