import React from 'react'

const FiltersSvg = () => (
  <svg width="15" height="10">
    <path
      d="M5.833 10h3.334V8.333H5.833V10zM0 0v1.667h15V0H0zm2.5 5.833h10V4.167h-10v1.666z"
      fill="#FFF"
    />
  </svg>
)

export default FiltersSvg
