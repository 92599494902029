import React from 'react'
import PropTypes from 'prop-types'
import CountdownComponent from '../component/Countdown'

class Countdown extends React.Component {
  constructor(props) {
    super(props)
    this.startTime =
      this.props.startTime instanceof Date
        ? this.props.startTime
        : new Date(this.props.startTime)
    this.state = {
      currentTime: new Date(),
    }

    this.tbc = props.tbc
  }

  componentDidMount() {
    if (!this.tbc) {
      this.timer = setInterval(() => {
        this.setState({
          currentTime: new Date(),
        })
      }, 1000)
    }
  }

  componentWillUnmount() {
    if (!this.tbc) {
      clearInterval(this.timer)
    }
  }

  render() {
    return (
      <CountdownComponent
        {...this.props}
        tbc={this.tbc}
        startTime={this.startTime}
        currentTime={this.state.currentTime}
      />
    )
  }
}

Countdown.propTypes = {
  startTime: PropTypes.oneOfType([ PropTypes.string, PropTypes.instanceOf(Date) ])
    .isRequired,
  tbc: PropTypes.any,
}

export default Countdown
