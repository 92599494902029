import React from 'react'
import ReactDOM from 'react-dom'
import EmbedSocial from '../../../common/social/Embed'

const debug = require('debug')('embedded')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('[data-commentary-type]').forEach((appNode) => {
      if (
        appNode.dataset.commentarySocial &&
        [ 'tweet', 'instagram' ].includes(appNode.dataset.commentaryType)
      ) {
        ReactDOM.render(
          <EmbedSocial
            type={appNode.dataset.commentaryType}
            socialId={appNode.dataset.commentarySocial}
          />,
          appNode
        )
      }
    })
  })
}
