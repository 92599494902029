import React from 'react'
import ReactDOM from 'react-dom'
import debug from 'debug'
import MatchScore from '../../../../site/layout/component/match-score/component/MatchScore'

const log = debug('matchday-live:match-scores:log')

export default (appNodes, primus, match) => {
  log('init')
  class MatchScoreContainer extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        homeScore: match.__scores.home,
        awayScore: match.__scores.away,
      }
    }

    componentDidMount() {
      primus.on('updateScore', (score) => {
        log('updateScore', score)
        this.setState({ homeScore: score.home, awayScore: score.away })
      })
    }

    render() {
      return (
        <MatchScore
          match={match}
          homeScore={this.state.homeScore}
          awayScore={this.state.awayScore}
        />
      )
    }
  }

  appNodes.forEach((appNode) => {
    ReactDOM.render(<MatchScoreContainer />, appNode)
  })
}
