import React from 'react'

const InstagramSvg = () => (
  <svg width="15" height="15" viewBox="0 0 15 15">
    <path
      vectorEffect="non-scaling-stroke"
      d="M7.5 1.3h3c.5 0 .9.1 1.4.3.7.3 1.2.8 1.4 1.4.2.4.3.9.3 1.4v6c0 .5-.1.9-.3 1.4-.3.7-.8 1.2-1.4 1.4-.4.2-.9.3-1.4.3h-6c-.5 0-.9-.1-1.4-.3-.7-.3-1.2-.8-1.4-1.4-.2-.4-.3-.9-.3-1.4v-6c0-.5.1-.9.3-1.4.3-.6.8-1.1 1.4-1.4.4-.1.9-.2 1.4-.2.9 0 1-.1 3-.1m0-1.3H4.4c-.6 0-1.2.1-1.8.3C1.6.7.8 1.5.4 2.5.2 3.2 0 3.8 0 4.4v6.2c0 .6.1 1.2.3 1.8.4 1 1.2 1.8 2.2 2.2.6.2 1.3.4 1.9.4h6.2c.6 0 1.2-.1 1.8-.4 1-.4 1.8-1.2 2.2-2.2.2-.6.4-1.2.4-1.9V4.3c0-.6-.1-1.2-.4-1.8-.4-1-1.2-1.8-2.2-2.2-.5-.1-1.2-.3-1.8-.3H7.5z"
    />
    <path
      vectorEffect="non-scaling-stroke"
      d="M7.5 3.6c-2.1 0-3.8 1.7-3.8 3.9s1.7 3.9 3.8 3.9 3.9-1.7 3.9-3.9-1.8-3.9-3.9-3.9zm0 6.4C6.1 10 5 8.9 5 7.5S6.1 5 7.5 5 10 6.1 10 7.5 8.9 10 7.5 10z"
    />
    <circle vectorEffect="non-scaling-stroke" cx="11.5" cy="3.5" r=".9" />
  </svg>
)

export default InstagramSvg
