import React from 'react'
import ReactDOM from 'react-dom'
import NotifyMe from '../../../site/layout/component/notify-me/component/NotifyMe'
import createFormSubmissionContainer from '../../lib/form-submission'
const debug = require('debug')('notify-me')
const schemata = require('schemata')
const required = require('@clocklimited/validity-required')
const schema = schemata({
  name: 'Notify Me',
  properties: {
    fullName: {
      type: String,
      validators: [ required ],
    },
    email: {
      type: String,
      validators: [ required ],
    },
    notifyMeId: {
      type: String,
    },
    signUp: {
      type: Boolean,
      default: false,
    },
  },
})

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.js-notify-me').forEach((element) => {
      const serverProps = JSON.parse(element.dataset.props)
      const Component = (props) => <NotifyMe {...props} {...serverProps} />
      const FormSubmissionContainer = createFormSubmissionContainer({
        Component: Component,
        schema: schema,
        apiUrl: '/api/notify-me',
        errorMessage:
          'An error has occurred. Please try again. If the problem persists, please get in touch via our <a href="/club/contact">contact page</a>.',
        successMessage:
          'Thank you for registering your interest in this game. We will be in contact when tickets for this game are available to purchase',
        data: {
          notifyMeId: serverProps.notifyMeId,
          displayAlone: serverProps.displayAlone,
        },
      })
      ReactDOM.render(<FormSubmissionContainer />, element)
    })
  })
}
