import React from 'react'
import PropTypes from 'prop-types'
import CommentaryItem from '../../../../layout/component/commentary/component/Commentary'
import Icon from '../../../../layout/component/primitive/icon/component/Icon'
import Svgs from '../../../../layout/component/primitive/svgs/component/Svgs'

const Commentary = ({ isLive, commentary }) => {
  if (!commentary) return null

  return (
    <div className="commentary-wrapper content-center content-center--small">
      {isLive && (
        <p className="commentary-auto-renew">
          <Icon width={18} height={18} className="is-spinning">
            <Svgs.AutoRenew />
          </Icon>
          <span>Commentary will automatically update.</span>
        </p>
      )}
      {commentary.map((item, i) => (
        <CommentaryItem key={`commentary-${i}`} commentary={item} />
      ))}
    </div>
  )
}
Commentary.propTypes = {
  commentary: PropTypes.array,
  isLive: PropTypes.bool,
}

export default Commentary
