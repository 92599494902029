import React from 'react'
import PropTypes from 'prop-types'
import LoadingSpinner from '../../primitive/loading-spinner/component/LoadingSpinner'
import Panel from '../../primitive/panel/component/Panel'
import PollQuestion from './PollQuestion'
import PollResults from './PollResults'
import moment from 'moment-timezone'
import classNames from "classnames";
import Field from "../../primitive/field/component/Field";

const Poll = ({
  onSubmit,
  onEnter,
  onViewResults,
  onChange,
  data = {},
  error = {},
  waiting,
  successMessage,
  errorMessage,
  poll,
  results,
  selected,
  view = 'poll',
  showFullResults = false,
  showNewsletterForm = false,
}) => {
  const { closeDate, questions, ctaText, description } = poll
  const closed = closeDate && closeDate < Date.now()

  const entered = !!selected
  if (entered) view = 'results'

  const onEnterClick = (e) => {
    e.preventDefault()
    onEnter()
  }

  const showNewsletter = showNewsletterForm && Object.values(data).filter(Boolean).length > 0

  return (
    <Panel heading={poll.title} narrow>
      <div className="poll-builder">
        {description && (
          <div
            className="poll-builder__description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
        {errorMessage && (
          <div className="notification notification--error">
            <p>{errorMessage}</p>
          </div>
        )}
        {successMessage && (
          <div
            className="notification notification--success"
            dangerouslySetInnerHTML={{ __html: successMessage }}
          />
        )}
        <form
          className="poll-builder__form"
          action="/api/poll"
          method="post"
          onSubmit={onSubmit}
        >
          {view === 'poll' ? (
            questions &&
            questions.map((question, i) => (
              <PollQuestion
                key={`form-builder-${i}`}
                question={question}
                error={error}
                data={data}
                id={i}
                onChange={onChange}
                results={results}
                selected={selected}
              />
            ))
          ) : (
            <div className="poll-builder__results">
              <div className="field__question">{questions[0].question}</div>
              <PollResults results={results} selected={selected} showFullResults={showFullResults} />
            </div>
          )}
          
          {view === 'poll' && showNewsletter && (
            <div className="grid">
              <div className="grid__item">
                <div className="poll-builder__newsletter-title">
                  Would you like to subscribe to the latest from Northampton Saints?
                </div>
                <p className="poll-builder__newsletter-text">
                  Fill in your details and click “VOTE NOW” Wayto stay up-to-date
                  with all the latest Club news, offers and competitions – or
                  leave fields blank to skip this step and submit your vote.
                </p>
                <p className="poll-builder__newsletter-text">
                  You can unsubscribe at any time or update your opt-in
                  preferences <a href="/legal/cookie-policy">HERE</a>
                </p>
              </div>
              <div className="grid__item poll-builder__newsletter-row">
                <Field
                  name="fullName"
                  type="text"
                  showRequired={false}
                  onChange={onChange}
                  placeholder="Name"
                  error={error.fullName}
                  aria-label="Name"
                  id="Name--Poll"
                />
              </div>
              <div className="grid__item">
                <Field
                  name="email"
                  type="email"
                  showRequired={false}
                  onChange={onChange}
                  placeholder="Email"
                  error={error.email}
                  aria-label="Email"
                  id="Email--Poll"
                />
              </div>
            </div>
          )}

          {closed ? (
            <p className="notification notification--success">
              Poll is now closed
            </p>
          ) : poll.closeDate ? (
            <p className="poll-builder__notification">
              Poll ends {moment().tz('Europe/London').to(poll.closeDate)}
            </p>
          ) : (
            <span />
          )}
          <div className="poll-builder__actions auto-justify auto-justify--valign-middle">
            {view === 'results' && !closed && !entered && (
              <button className="button button--primary" onClick={onEnterClick}>
                Enter Now
              </button>
            )}
            {view === 'poll' &&
              !closed &&
              !entered &&
              (waiting ? (
                <LoadingSpinner />
              ) : (
                !selected && (
                  <button type="submit" className="button button--primary">
                    {ctaText}
                  </button>
                )
              ))}
          </div>
        </form>
      </div>
    </Panel>
  )
}

Poll.propTypes = {
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  data: PropTypes.object,
  waiting: PropTypes.bool,
  onEnter: PropTypes.func,
  onViewResults: PropTypes.func,
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  view: PropTypes.string,
  error: PropTypes.object,
  poll: PropTypes.object,
  results: PropTypes.object,
  selected: PropTypes.bool,
  showNewsletterForm: PropTypes.bool,
  showFullResults: PropTypes.bool,
}

export default Poll
