import React from 'react'

const Close = () => (
  <svg viewBox="0 0 12 12">
    <g>
      <path fill="#FFF" d="M11.6,2.4L8,6l3.6,3.6c0.5,0.5,0.5,1.4,0,2c-0.3,0.3-0.6,0.4-1,0.4c-0.4,0-0.7-0.1-1-0.4L6,8l-3.6,3.6
		c-0.3,0.3-0.6,0.4-1,0.4c-0.4,0-0.7-0.1-1-0.4c-0.5-0.5-0.5-1.4,0-2L4,6L0.4,2.4c-0.5-0.5-0.5-1.4,0-2c0.5-0.5,1.4-0.5,2,0L6,4
		l3.6-3.6c0.5-0.5,1.4-0.5,2,0C12.1,0.9,12.1,1.8,11.6,2.4z" />
    </g>
  </svg>
)

export default Close
