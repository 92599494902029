import React, { Fragment } from 'react'

const debug = require('debug')('form-submission')
const apiPrefix = typeof API_URL === 'undefined' ? '' : API_URL

const create = ({
  Component,
  schema,
  apiUrl,
  errorMessage,
  successMessage,
  data = {},
  props,
  onSuccess,
}) => {
  class Container extends React.Component {
    constructor(props) {
      super(props)
      this.onChange = this.onChange.bind(this)
      this.onSubmit = this.onSubmit.bind(this)
      this.setValues = this.setValues.bind(this)
      this.onSuccess = onSuccess
      this.state = {
        waiting: false,
        error: {},
        data: schema.makeDefault(data),
      }
    }

    getCheckBoxValues(e) {
      let values = this.state.data[e.currentTarget.name]
      if (!values) values = []
      const newValue = e.currentTarget.value
      if (values.includes(newValue)) {
        values.splice(values.indexOf(newValue), 1)
      } else {
        values.push(newValue)
      }
      return values.length > 0 ? values : null
    }

    onChange(e) {
      debug('onchange', {
        ...this.state.data,
        [e.currentTarget.name]: e.currentTarget.value,
      })
      let targetValue
      e.target.type === 'checkbox'
        ? (targetValue = this.getCheckBoxValues(e))
        : (targetValue = e.currentTarget.value)

      this.setState({
        data: { ...this.state.data, [e.currentTarget.name]: targetValue },
      })
    }

    onSubmit(e) {
      e.preventDefault()
      schema.validate(this.state.data, (err, errors) => {
        if (err) return alert(err)
        if (Object.keys(errors).length > 0)
          return this.setState({ error: errors })
        this.setState({ waiting: true, error: {} })
        fetch(apiPrefix + apiUrl, {
          headers: { 'content-type': 'application/json' },
          method: 'POST',
          body: JSON.stringify(this.state.data),
        })
          .then(async (res) => {
            this.setState({ waiting: false })
            if (res.status === 200) {
              this.onSuccess &&
                this.onSuccess(this.state.data, this.setState.bind(this))
              return this.setState({ successMessage })
            }
            const data = await res.json()
            const error = data.errorMessage || errorMessage
            this.setState({ errorMessage: error, error: { error: error } })
          })
          .catch((err) => {
            this.setState({ waiting: false })
            this.setState({
              errorMessage:
                'There was an problem, try again later: ' + err.message,
              error: { error: err.message },
            })
          })
      })
    }

    setValues(values) {
      this.setState({
        data: { ...this.state.data, ...values },
      })
    }

    render() {
      return (
        <Fragment>
          <Component
            onChange={this.onChange}
            onSubmit={this.onSubmit}
            setValues={this.setValues}
            {...props}
            {...this.state}
          />
        </Fragment>
      )
    }
  }

  return Container
}

export default create
