import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../../primitive/icon/component/Icon'
import Svgs from '../../primitive/svgs/component/Svgs'

var { navigate } = require('react-big-calendar/lib/utils/constants')

class CalendarToolbar extends React.Component {
  constructor() {
    super()

    this.handleNavigate = this.handleNavigate.bind(this)
  }

  handleNavigate(action) {
    this.props.onNavigate(action)
  }

  render() {
    return (
      <div className="rbc-toolbar">
        <button
          className="text-button"
          type="button"
          onClick={() => this.handleNavigate(navigate.PREVIOUS)}
        >
          <Icon height={18} width={10} modifiers={[ 'arrow-left' ]}>
            <Svgs.ArrowLeft />
          </Icon>
        </button>

        <div className="rbc-toolbar-label">{this.props.label}</div>

        <button
          className="text-button"
          type="button"
          onClick={() => this.handleNavigate(navigate.NEXT)}
        >
          <Icon height={18} width={10} modifiers={[ 'arrow-right' ]}>
            <Svgs.ArrowRight />
          </Icon>
        </button>
      </div>
    )
  }
}

CalendarToolbar.propTypes = {
  onNavigate: PropTypes.func,
  label: PropTypes.string,
}

export default CalendarToolbar
