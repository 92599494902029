import React from 'react'
import ReactDOM from 'react-dom'
import ScorePredictor from '../../../site/layout/component/score-predictor/component/ScorePredictor'
import createFormSubmissionContainer from '../../lib/form-submission'

const required = require('@clocklimited/validity-required')
const email = require('@clocklimited/validity-email')
const schemata = require('schemata')
const debug = require('debug')('score-predictor')

const schema = schemata({
  name: 'Score Predictor',
  propeties: {
    homeScore: { type: String, validators: [ required ] },
    awayScore: { type: String, validators: [ required ] },
    firstName: { type: String, validators: [ required ] },
    lastName: { type: String, validators: [ required ] },
    telephone: { type: String },
    email: { type: String, validators: [ required, email ] },
    dateOfBirth: { type: String },
    optIn: { type: String },
    eventDate: { type: Date },
  },
})

export default () => {
  debug('init')

  document.addEventListener('DOMContentLoaded', () => {
    const appNode = document.getElementById('score-predictor')
    if (!appNode) {
      return debug('No score predictor node found. Skipping.')
    }
    const serverProps = JSON.parse(appNode.dataset.props)
    const Component = (props) => <ScorePredictor {...serverProps} {...props} />

    const FormSubmissionContainer = createFormSubmissionContainer({
      Component,
      schema,
      apiUrl: '/api/score-predictor',
      errorMessage:
        'Please check your details and try again. If the problem persists, please get in touch via our <a href="/club/contact">contact page</a>.',
      successMessage: 'Thank you for submitting your score prediction.',
      data: { eventDate: serverProps.match.dateTime },
    })

    ReactDOM.render(<FormSubmissionContainer />, appNode)
  })
}
