import React from 'react'

const FabSignUpSvg = () => (
  <svg data-name="Layer 1" viewBox="0 0 266 213" fill="#fff">
    <g data-name="1AkuQD">
      <path d="M187.67 0c1.22.14 2.44.25 3.65.42A23.47 23.47 0 0 1 204 6c4.91 4.38 7.34 10 8.41 16.37a31 31 0 0 1 .41 4.91c.05 5.4 0 10.81 0 16.21a2.18 2.18 0 0 1-.5 1.35Q199.65 57.57 187 70.22l-.48.44v-44h-160v159.7h159.9v-25.81a2.14 2.14 0 0 1 .51-1.35q12.6-12.67 25.25-25.29c.14-.14.29-.26.61-.55v1.17c0 18 .1 36.09-.06 54.13a22.94 22.94 0 0 1-6.57 16.34 26.72 26.72 0 0 1-13.7 7.21c-1.58.35-3.19.56-4.79.84H23.94c-1.27-.21-2.54-.39-3.8-.62A22.3 22.3 0 0 1 3.6 200.31 31.66 31.66 0 0 1 0 185.43q.06-79 0-158a37.31 37.31 0 0 1 .64-7.89A22.62 22.62 0 0 1 6.9 7.87c4.44-4.35 9.93-6.54 16-7.45C23.93.26 25 .14 26 0zM266 53.41c-.28.24-.58.48-.84.74L248.52 70.8 231 53.3l17.48-17.49c.18.17.46.41.72.67l16 16c.24.24.51.46.76.7z" />
      <path d="M168.6 150.52l-17.41-17.41 70.56-70.58 17.41 17.41zM53.28 62.08V44.54h106.28v17.54zm106.31 18v17.48H53.31V80.08zM53.27 133v-17.39H133V133zm.02 35.48v-17.36h53v17.36zm79.71.05v-12c0-1.87-.47-3.94.19-5.55s2.44-2.66 3.73-4l4.77-4.79 17.67 17.66-5.8 5.8c-.93.93-1.72 2.21-2.84 2.67s-2.59.14-3.9.14H133z" />
    </g>
  </svg>
)

export default FabSignUpSvg
