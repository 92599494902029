import debug from 'debug'
const log = debug('table-filter:log')

export default () => {
  log('Init table filter')

  const tableFilter = document.querySelector('.js-standing--form')

  if (!tableFilter) return false

  const competitionSelect = getSelect(tableFilter, 'competition')
  const seasonSelect = getSelect(tableFilter, 'season')
  const seasonOptions = [ ...seasonSelect.querySelectorAll('option') ]
  const currentPath = window.location.pathname.split('/')
  const parentSection = `${currentPath[1]}/${currentPath[2]}`
  const currentCompetition = currentPath[3]
  const query = window.location.search

  let currentSeason = query.substring(query.indexOf('=') + 1)

  const seasonElement = document.querySelector('[data-season]')
  if (seasonElement) {
    currentSeason = seasonElement.dataset.season
  }
  const validSeasons = []

  seasonOptions.forEach((option) => validSeasons.push(option.value))

  if (validSeasons.indexOf(currentSeason) < 0) {
    seasonSelect.value = validSeasons[0]
  } else {
    seasonSelect.value = currentSeason
  }

  competitionSelect.value = currentPath[3]

  competitionSelect.addEventListener('change', () => {
    window.location.href = `/${parentSection}/${competitionSelect.value}`
  })

  seasonSelect.addEventListener('change', () => {
    window.location.href = `/${parentSection}/${currentCompetition}?season=${seasonSelect.value}`
  })
}

const getSelect = (form, id) => {
  return form.querySelector(`#control--${id}`)
}
