import debug from 'debug'
import qs from 'qs'
const log = debug('article-filter:log')

export default () => {
  log('Init article filter')

  const articleFilter = document.querySelector('.js-article--form')
  if (!articleFilter) return false

  const submitButton = articleFilter.querySelector('.js-article--submit')
  const options = articleFilter.querySelectorAll('input')
  const allOption = articleFilter.querySelectorAll('input[value="All"]')[0]
  const otherOptions = articleFilter.querySelectorAll(
    'input:not([value="All"])'
  )

  setCheckedValues(options, allOption)

  options.forEach((option) => {
    option.addEventListener('change', (e) => {
      const checkboxValue = e.target.value

      if (checkboxValue === 'All') {
        deselectOtherOptions(otherOptions)
      } else {
        deselectAllOption(allOption)
      }
    })
  })

  submitButton.addEventListener('click', () => {
    const optionsArray = [ ...options ]
    const checkedOptions = optionsArray.filter((o) => o.checked)
    const values = {}
    const categories = checkedOptions.map((option) => option.value)

    if (categories[0] !== 'All') values.categories = categories
    const query = qs.stringify(values, { arrayFormat: 'brackets' })
    const url = getCurrentUrl()
    redirectTo(url, query)
  })
}

const setCheckedValues = (options, allOption) => {
  const url = getCurrentUrl()
  const selectedCategories = url.query.categories
  if (
    !selectedCategories ||
    !selectedCategories.length ||
    selectedCategories[0] === 'All'
  )
    return false

  options.forEach((option) => {
    const optionValue = option.value
    if (selectedCategories.indexOf(optionValue) > -1) {
      option.checked = true
      deselectAllOption(allOption)
    }
  })
}

const deselectOtherOptions = (options) => {
  options.forEach((o) => {
    o.checked = false
  })
}

const deselectAllOption = (option) => {
  option.checked = false
}

// build url path and redirect page
const redirectTo = (url, query) => {
  let path = url.path.join('/')
  if (query) path = path.concat('?', query)
  window.location.href = path
}

// get the current pages url, used to build a location object
const getCurrentUrl = () => {
  const currentUrl = {}
  const search = window.location.search
  currentUrl.path = window.location.pathname.split('/')
  currentUrl.query = qs.parse(search.substring(1))

  return currentUrl
}
