import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const RangeControl = ({ className, placeholder, ...other }) => {
  const controlClassNames = classNames('custom-range', 'js-range', className)

  return <div className={controlClassNames} />
}

RangeControl.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
}

export default RangeControl
