import throttle from 'lodash.throttle'
const debug = require('debug')('mobile-navigation')

export default (serviceLocator) => {
  debug('init')

  const bm = serviceLocator.get('breakpointManager')
  const primaryNav = document.querySelector('.js-navigation')
  if (!primaryNav) return false
  const navMenuButton = primaryNav.querySelector('.js-open-menu')
  const returnButton = primaryNav.querySelector('.js-return-button')
  const subItemToggles = primaryNav.querySelectorAll('.js-subitem-toggle')
  const breadcrumb = document.querySelector('.js-breadcrumb')
  const stickPosition = document.querySelector('.pre-header').clientHeight
  const handleClick = (e) => {
    e.preventDefault()
    const isActive = document
      .querySelector('html')
      .classList.contains('has-open-nav')
    isActive ? closeNav() : openNav()
  }

  const handleKeydown = (e) => {
    if (e.keyCode !== 27) return
    closeNav()
  }

  const handleToggle = (e) => {
    e.stopPropagation()
    const currentTarget = e.currentTarget

    currentTarget.querySelector('.js-subitems').classList.toggle('is-hidden')
    currentTarget.classList.toggle('is-open')

    if (currentTarget.classList.contains('js-secondary')) {
      const currentTargetTitle = currentTarget.querySelector(
        '.js-secondary-title'
      ).innerHTML
      breadcrumb.innerHTML = ` > ${currentTargetTitle}`
      document.querySelector('html').classList.toggle('has-open-subnav')
    }
  }

  const openNav = () => {
    document.querySelector('html').classList.add('has-open-nav')
    document.addEventListener('keydown', handleKeydown)
  }

  const closeNav = () => {
    document.querySelector('html').classList.remove('has-open-nav')
    document.removeEventListener('keydown', handleKeydown)
  }

  const handleReturn = () => {
    const subItems = document.querySelectorAll('.js-subitems')
    document.querySelector('html').classList.remove('has-open-subnav')
    subItems.forEach((item) => {
      item.classList.add('is-hidden')
    })
    subItemToggles.forEach((toggle) => {
      toggle.classList.remove('is-open')
    })
    breadcrumb.innerHTML = ''
  }

  returnButton.addEventListener('click', handleReturn)
  navMenuButton.addEventListener('click', handleClick)
  subItemToggles.forEach((toggle) => {
    toggle.addEventListener('click', handleToggle)
  })

  const changeLogo = () => {
    const scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop
    const isSticky = scrollTop > stickPosition
    if (isSticky) {
      document.body.classList.add('has-sticky-nav')
    } else {
      document.body.classList.remove('has-sticky-nav')
    }
  }

  bm.on('enter:stickyNav', () =>
    window.addEventListener('scroll', throttle(changeLogo, 160))
  )
  bm.on('exit:stickyNav', () =>
    window.removeEventListener('scroll', throttle(changeLogo, 160))
  )
}
