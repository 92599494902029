import React from 'react'
import Summary from '../../../../layout/component/primitive/summary/component/Summary'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import platforms from '../../../../../common/video/platforms'

const Thumbnail = (props) => {
  const { video, onClick, active } = props

  const onThumbnailClick = (e) => {
    e.preventDefault()
    onClick(video, true)
  }

  return (
    <div
      className={classNames('video-thumbnail', {
        'js-is-active is-active': active,
      })}
      data-video={video.videoId}
      onClick={onThumbnailClick}
    >
      <div className="video-playlist__wrapper">
        <div
          className="video-playlist__thumbnail"
          style={{ backgroundImage: `url(${video.thumbnail.url})` }}
        />
        <Summary
          href={platforms[video.platform].link(video.videoId)}
          modifiers={[ 'video', 'video-playlist__item' ]}
          title={video.title}
          meta={video.date}
        />
      </div>
    </div>
  )
}

Thumbnail.propTypes = {
  video: PropTypes.object,
  onClick: PropTypes.func,
  active: PropTypes.bool,
}

class VideoGrid extends React.Component {
  constructor(...props) {
    super(...props)

    this.limit =
      this.props.limit === 0 ? this.props.videos.length : this.props.limit

    this.state = {
      videos: this.props.videos,
      activeVideo: this.props.videos[0],
      hasOverflow: this.props.videos.length > this.limit,
      showOverflow: false,
    }
    this.handleShowOverflow = this.handleShowOverflow.bind(this)
  }

  handleShowOverflow() {
    this.setState({ showOverflow: true })
  }

  render() {
    const onClick = (video) => {
      this.setState({
        activeVideo: video,
      })

      this.props.onThumbnailClick(video)
    }

    return (
      <div className="content-center">
        <div className="video-playlist__grid">
          <div className="grid grid--flex">
            {this.state.videos.slice(0, this.limit).map((video, index) => (
              <div
                className="grid__item one-half tablet-one-third desktop-one-quarter"
                key={index}
              >
                <Thumbnail
                  active={video.videoId === this.state.activeVideo.videoId}
                  video={video}
                  onClick={onClick}
                  key={index}
                />
              </div>
            ))}

            {this.state.hasOverflow && !this.state.showOverflow && (
              <div className="grid__item">
                <div className="load-more load-more--no-bg">
                  <button
                    className="button button--primary"
                    onClick={this.handleShowOverflow}
                  >
                    Show all
                  </button>
                </div>
              </div>
            )}

            {this.state.hasOverflow &&
              this.state.showOverflow &&
              this.state.videos
                .slice(this.limit, this.state.videos.length)
                .map((video, index) => (
                  <div
                    className="grid__item one-half tablet-one-third desktop-one-quarter"
                    key={index}
                  >
                    <Thumbnail
                      active={video.videoId === this.state.activeVideo.videoId}
                      video={video}
                      onClick={onClick}
                      key={index}
                    />
                  </div>
                ))}
          </div>
        </div>
      </div>
    )
  }
}

VideoGrid.propTypes = {
  videos: PropTypes.array,
  onThumbnailClick: PropTypes.func,
  limit: PropTypes.number,
}

export default VideoGrid
