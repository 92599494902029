import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'

import Field from '../../primitive/field/component/Field'

const EmailPreferences = ({ onChange }) => {
  const [ showDropdowns, setShowDropdowns ] = useState(true)

  const handleDropdowns = () => {
    setShowDropdowns(prev => !prev)
  }

  return (
    <Fragment>
      <div className="email_preferences_question">
        <Field
          name="maxFrequency"
          type="checkbox"
          onChange={e => { onChange(e); handleDropdowns() }}
          options={[ { text: 'Please tick if you would you like to receive ALL updates from Northampton Saints, or use the options below.', value: true } ]}
          className="preference-centre__checkbox--important"
      />
      </div>
      {showDropdowns && 
        <div>
          <div className="email_preferences_question">
            <label className="field__question">
              How often would you like to hear about the Saints Store?
            </label>
            <Field
              name="storeFrequency"
              type="select"
              onChange={onChange}
              options={[
                {
                  text: 'Please select',
                  disabled: true,
                  selected: true,
                },
                {
                  text:
                    'New arrivals, sales, offers and inspiration. Keep it all coming!',
                  value: 'often',
                },
                {
                  text: 'Once a week, just enough to stay in the know',
                  value: 'regularly',
                },
                {
                  text:
                    'I need a break. Only to tell me when products are launched or sales have started',
                  value: 'rarely',
                },
                {
                  text:
                    'I don’t wish to be contacted about this',
                  value: 'never'
                }
              ]}
              className="preference-centre__select"
            />
          </div>
          <div className="email_preferences_question">
            <label className="field__question">
              How often would you like to hear about Hospitality?
            </label>
            <Field
              name="hospitalityFrequency"
              type="select"
              onChange={onChange}
              options={[
                {
                  text: 'Please select',
                  disabled: true,
                  selected: true,
                },
                {
                  text:
                    'Event launches, availability, the latest offerings. Keep it all coming!',
                  value: 'often',
                },
                {
                  text: 'Bi-weekly, just enough to stay in the know',
                  value: 'regularly',
                },
                {
                  text:
                    'I need a break. Only to tell me when products are launched, or offers are available',
                  value: 'rarely',
                },
                {
                  text:
                    'I don’t wish to be contacted about this',
                  value: 'never'
                }
              ]}
              className="preference-centre__select"
            />
          </div>
          <div className="email_preferences_question">
            <label className="field__question">
              How often would you like to hear about the Events at Northampton Saints?
            </label>
            <Field
              name="eventsFrequency"
              type="select"
              onChange={onChange}
              options={[
                {
                  text: 'Please select',
                  disabled: true,
                  selected: true,
                },
                {
                  text:
                    'On sale dates, availability, offers and the latest news. Keep it all coming!',
                  value: 'often',
                },
                {
                  text: 'Bi-weekly, just enough to stay in the know',
                  value: 'regularly',
                },
                {
                  text:
                    'I need a break. Only to tell me when events are first announced',
                  value: 'rarely',
                },
                {
                  text:
                    'I don’t wish to be contacted about this',
                  value: 'never'
                }
              ]}
              className="preference-centre__select"
            />
          </div>
          <div className="email_preferences_question">
            <label className="field__question">
              How often would you like to hear about Saints rugby camps?
            </label>
            <Field
              name="campsFrequency"
              type="select"
              onChange={onChange}
              options={[
                {
                  text: 'Please select',
                  disabled: true,
                  selected: true,
                },
                {
                  text:
                    'On sale dates, availability, offers and the latest news. Keep it all coming!',
                  value: 'often',
                },
                {
                  text: 'Bi-weekly, just enough to stay in the know',
                  value: 'regularly',
                },
                {
                  text:
                    'I need a break. Only to tell me when events are first announced',
                  value: 'rarely',
                },
                {
                  text:
                    'I don’t wish to be contacted about this',
                  value: 'never'
                }
              ]}
              className="preference-centre__select"
            />
          </div>
          <div className="email_preferences_question">
            <label className="field__question">
              How often would you like to hear about Northampton Saints’ Partners?
            </label>
            <Field
              name="partnerFrequency"
              type="select"
              onChange={onChange}
              options={[
                {
                  text: 'Please select',
                  disabled: true,
                  selected: true,
                },
                {
                  text:
                    'Competitions, offers, updates – keep it all coming!',
                  value: 'often',
                },
                {
                  text: 'Once a week, just enough to stay in the know',
                  value: 'regularly',
                },
                {
                  text:
                    'I need a break. Only to tell me when products are launched or sales have started',
                  value: 'rarely',
                },
                {
                  text:
                    'I don’t wish to be contacted about this',
                  value: 'never'
                }
              ]}
              className="preference-centre__select"
            />
          </div>
          <div className="email_preferences_question">
            <label className="field__question">
              How often would you like to hear about Loughborough Lightning?
            </label>
            <Field
              name="lightningFrequency"
              type="select"
              onChange={onChange}
              options={[
                {
                  text: 'Please select',
                  disabled: true,
                  selected: true,
                },
                {
                  text:
                    'Team news, on sale dates, fixtures and the latest offers. Keep it all coming!',
                  value: 'often',
                },
                {
                  text: 'Once a week, just enough to stay in the know',
                  value: 'regularly',
                },
                {
                  text:
                    'I need a break. Only tell me about important updates or when fixtures are first announced',
                  value: 'rarely',
                },
                {
                  text:
                    'I don’t wish to be contacted about this',
                  value: 'never'
                }
              ]}
              className="preference-centre__select"
            />
          </div>
        </div>
      }
    </Fragment>
  )
}
EmailPreferences.propTypes = {
  onChange: PropTypes.func,
}

export default EmailPreferences
