import debug from 'debug'
const log = debug('click-tracker')

export default (serviceLocator) => {
  if (!window.ga) return
  const clickTrackerLinks = document.querySelectorAll('.js-click-tracker')
  clickTrackerLinks.forEach((linkEl) => {
    linkEl.addEventListener(
      'click',
      (e) => {
        log('link clicked', linkEl.dataset.linkType, linkEl.href)
        window.ga(
          'gtm1.send',
          'event',
          linkEl.dataset.linkType || 'ClickTracker',
          'click',
          linkEl.href
        )
      },
      false
    )
  })
}
