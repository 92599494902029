import debug from 'debug'
import qs from 'qs'
const log = debug('events-filter:log')

export default () => {
  log('Init fixtures and events filter')

  const eventFilter = document.querySelector('.js-fixtures-and-events--form')
  if (!eventFilter) return false

  const typeSelect = getSelect(eventFilter, 'fixturesAndEvents')

  let search = window.location.search.substring(1) // remove leading question mark
  const query = qs.parse(search) // create query object from query params

  // Populate all selects
  typeSelect.value = query.display || 'all'
  typeSelect.addEventListener('change', () => {
    const url = updateQueryParam('display', typeSelect.value)
    redirectTo(url)
  })
}

const getSelect = (form, id) => {
  return form.querySelector(`#control--${id}`)
}

// Update the query param values, if 'all' is selected, remove from query param
const updateQueryParam = (property, value) => {
  const url = getCurrentUrl()
  if (value === 'all') {
    delete url.query[property]
  } else {
    url.query[property] = value
  }
  return url
}

// build url path and redirect page
const redirectTo = (url) => {
  let path = url.path.join('/')
  const query = qs.stringify(url.query)
  if (query) path = path.concat('?', query)
  window.location.href = path
}

// get the current pages url, used to build a location object
const getCurrentUrl = () => {
  const currentUrl = {}
  const search = window.location.search
  currentUrl.path = window.location.pathname.split('/')
  currentUrl.query = qs.parse(search.substring(1))

  return currentUrl
}
