import React from 'react'

const TicketSvg = () => (
  <svg width="13" height="15">
    <g fill="none" fillRule="evenodd">
      <path
        d="M10.75 11.042h-8.5V9.625h8.5v1.417zm0-2.834h-8.5V6.792h8.5v1.416zm0-2.833h-8.5V3.958h8.5v1.417zM.125 14.583l1.063-1.062 1.062 1.062 1.063-1.062 1.062 1.062 1.063-1.062L6.5 14.583l1.063-1.062 1.062 1.062 1.063-1.062 1.062 1.062 1.063-1.062 1.062 1.062V.417l-1.063 1.062L10.75.417 9.687 1.479 8.626.417 7.562 1.479 6.5.417 5.437 1.479 4.375.417 3.312 1.479 2.25.417 1.187 1.479.125.417v14.166z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default TicketSvg
