import React from 'react'
import PropTypes from 'prop-types'
import Field from '../../primitive/field/component/Field'
import SectionHeading from '../../primitive/section-heading/component/SectionHeading'
import Svgs from '../../primitive/svgs/component/Svgs'
import Icon from '../../primitive/icon/component/Icon'
import EmailPreferences from './EmailPreferences'

const PreferenceCentre = ({
  children,
  items,
  onSubmit,
  onChange,
  successMessage,
  error = {},
  errorMessage,
}) => (
  <div className="preference-centre">
    <div className="content-center content-center--medium">
      <div className="preference-centre__content">{children}</div>
      <div className="preference-centre__benefits">
        {items.map((item, i) => (
          <div className="preference-centre__benefit" key={`benefit-${i}`}>
            <Icon
              modifiers={[ 'round' ]}
              className="preference-centre__icon"
              height={8}
              width={15}
            >
              <Svgs.Tick />
            </Icon>
            {item.title}
          </div>
        ))}
      </div>
      <SectionHeading bottomMargin element="h2">
        Your Details
      </SectionHeading>
      {error && Object.keys(error).length > 0 && (
        <div className="notification notification--error">
          <h3 className="notification__title">
            There was a problem with your submission.
          </h3>
          {errorMessage && (
            <p dangerouslySetInnerHTML={{ __html: errorMessage }} />
          )}
          {!errorMessage && <p>Please check below and try again.</p>}
        </div>
      )}
      {successMessage ? (
        <p className="email-signup__message">{successMessage}</p>
      ) : (
        <form className="form" method="post" onSubmit={onSubmit}>
          <input type="hidden" name="form" value="enquiry" />
          <div className="grid preference-centre__grid">
            <div className="grid__item tablet-one-half">
              <Field
                name="fullName"
                type="text"
                placeholder="Full Name"
                onChange={onChange}
                error={error.fullName}
                id="Name--PreferenceCentre"
              />
            </div>
            <div className="grid__item tablet-one-half">
              <Field
                name="email"
                type="text"
                placeholder="Email"
                onChange={onChange}
                error={error.email}
                id="Email--PreferenceCentre"
              />
            </div>
            <div className="grid__item tablet-one-half">
              <Field
                name="telephone"
                type="text"
                placeholder="Telephone"
                onChange={onChange}
                error={error.telephone}
              />
            </div>
            <div className="grid__item tablet-one-half">
              <Field
                name="dateOfBirth"
                type="datePicker"
                onChange={onChange}
                placeholder="Date of birth"
                error={error.dateOfBirth}
              />
            </div>
            <div className="grid__item tablet-one-half">
              <Field
                name="address1"
                type="text"
                placeholder="Address Line 1"
                onChange={onChange}
                error={error.address1}
              />
            </div>
            <div className="grid__item tablet-one-half">
              <Field
                name="addressLine2"
                type="text"
                placeholder="Address Line 2"
                onChange={onChange}
                error={error.addressLine2}
              />
            </div>
            <div className="grid__item tablet-one-half">
              <Field
                name="postcode"
                type="text"
                placeholder="Postcode"
                onChange={onChange}
                error={error.postcode}
              />
            </div>
            <div className="grid__item tablet-one-half">
              <span />
            </div>
            <div className="grid__item">
              <SectionHeading bottomMargin element="h2">
                Your Preferences
              </SectionHeading>
            </div>
            <div className="grid__item">
              <EmailPreferences onChange={onChange} />
            </div>
            <div className="grid__item">
              <p>
                Please ensure you read and agree to our{' '}
                <a href="/legal/terms">terms and conditions</a> and{' '}
                <a href="/legal/privacy-policy">privacy policy</a> before
                submitting your request. You can also view a list of our{' '}
                <a href="club/our-sponsors">partners</a>.
              </p>
            </div>
          </div>
          <button
            type="submit"
            className="button button--primary button--float-right"
          >
            Submit
          </button>
        </form>
      )}
    </div>
  </div>
)

PreferenceCentre.propTypes = {
  children: PropTypes.node,
  items: PropTypes.array,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  error: PropTypes.object,
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
}

export default PreferenceCentre
