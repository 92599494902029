import React from 'react'
import PropTypes from 'prop-types'

import CheckControl from './check/Check'
import DateControl from './date/Date'
import TextControl from './text/Text'
import SearchControl from './search/Search'
import RangeControl from './range/Range'
import SelectControl from './select/Select'
import TextareaControl from './textarea/Textarea'

const Control = (props) => {
  const { type } = props
  const componentMap = {
    checkbox: () => <CheckControl {...props} />,
    datePicker: () => <DateControl {...props} />,
    radio: () => <CheckControl {...props} />,
    range: () => <RangeControl {...props} />,
    search: () => <SearchControl {...props} />,
    select: () => <SelectControl {...props} />,
    textarea: () => <TextareaControl {...props} />,
  }

  return componentMap[type] ? componentMap[type]() : <TextControl {...props} />
}

Control.propTypes = {
  type: PropTypes.string.isRequired,
}

export default Control
