import React from 'react'

const ConversionSvg = () => (
  <svg data-name="Layer 1" width="22" height="22" viewBox="0 0 22 22">
    <path
      vectorEffect="non-scaling-stroke"
      d="M21.22 1.38c-.32-.12-.63-.24-.93-.37l.1-.21c.29.18.56.38.83.58zM18.56.67a5.7 5.7 0 0 0-1.7.16l-.15-.77a5.35 5.35 0 0 1 1.93 0zm-3.27.8a9.88 9.88 0 0 0-1.41 1l-.62-.76a8.32 8.32 0 0 1 1.61-1zm-2.68 2.18c-.38.45-.75.91-1.14 1.35l-.74-.54c.37-.5.77-1 1.16-1.47zm-2.2 2.8c-.31.51-.63 1-1 1.52l-.51-.25c.25-.56.52-1.12.8-1.67zm-1.83 3.1c-.13.3-.27.6-.42.9.06-.33.12-.66.2-1zm-6.42-.7a9.06 9.06 0 0 0-2.16 6 9.06 9.06 0 0 0 2.57 5.83 3.18 3.18 0 0 0 2.26 1c1.13 0 2.24-1 3.12-2.55a9.9 9.9 0 0 0 1.21-4.57 8.51 8.51 0 0 0-2.48-5.77 3.34 3.34 0 0 0-2.36-1.06 3.25 3.25 0 0 0-2.16 1.12zm1.69 4.37a13.11 13.11 0 0 1 .22-3.85 1.24 1.24 0 0 1 .7-1 2.87 2.87 0 0 1 1.27.75 6.87 6.87 0 0 1 1.5 2.2 7.31 7.31 0 0 1 .65 2.84 7.55 7.55 0 0 1-1.24 3.9c-.52.85-1.12 1.29-1.34 1.18a2.21 2.21 0 0 1-.56-.86 24.91 24.91 0 0 1-1.2-5.17zm-.94-4a3.89 3.89 0 0 1 .3-.29s.05 0 0 0a23.84 23.84 0 0 0-.33 5.41 34.88 34.88 0 0 0 .46 4.2c.07.28-.07.53-.34.37a4 4 0 0 1-.87-.9l-.07-.09A8.06 8.06 0 0 1 1 14.38a8 8 0 0 1 1.91-5.15z"
      fill="#ffffff"
    />
  </svg>
)

export default ConversionSvg
