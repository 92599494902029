import React from 'react'
import ReactDOM from 'react-dom'
import Video from '../../../site/layout/component/video/component/Video'

const debug = require('debug')('poll')

const render = (containerNode, index) => {
  const props = containerNode.dataset
  const {
    videos,
    header,
    width,
    position,
    background,
    playlist,
    provider,
    showDetails,
    layout,
    limit,
    name,
  } = props
  const videoWrapper = containerNode.querySelector('.widget__content')

  return ReactDOM.render(
    <Video
      key={index}
      header={header}
      width={width}
      position={position}
      backgroundShape={background}
      showDetails={showDetails === 'true'}
      isPlaylist={!!playlist}
      provider={provider}
      layout={layout}
      limit={limit && Number(limit)}
      name={name}
      videos={JSON.parse(videos)}
    />,
    videoWrapper
  )
}

const initReact = () => {
  debug('Looking for Video Playlists')
  const containers = [
    ...document.querySelectorAll('.js-video-playlist-container'),
  ]
  containers.forEach((containerNode, index) => {
    render(containerNode, index)
  })
}

const init = () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', initReact, false)
  document.addEventListener('app:NewDOMContent', initReact, false)
}

export { init, initReact }
