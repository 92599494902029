import classNames from 'classnames'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Icon from '../primitive/icon/component/Icon'
import Svgs from '../primitive/svgs/component/Svgs'

const ActionButton = ({ text, iconType, href, style, className }) => {
  const widthMap = {
    Shirt: 45,
    SignUp: 40,
    Tickets: 70,
    Win: 60,
    Memberships: 100,
    RugbyBall: 40,
  }
  const ActionIcon = Svgs[`Fab${iconType}`]
  const baseClass = 'floating-action-button__button'
  return (
    <a
      href={href}
      style={style}
      className={classNames(
        baseClass,
        `${baseClass}--${iconType.toLowerCase()}`,
        className
      )}
      aria-label={text}
      target="_blank"
      rel="noreferrer"
    >
      <div className="floating-action-button__button-label">{text}</div>
      <div className="floating-action-button__button-icon">
        <div className="floating-action-button__button-label--desktop">
          {text}
        </div>
        <Icon width={widthMap[iconType]}>
          <ActionIcon />
        </Icon>
      </div>
    </a>
  )
}

ActionButton.propTypes = {
  text: PropTypes.string,
  iconType: PropTypes.string,
  href: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
}

const FloatingActionButton = (props) => {
  const { items } = props
  const [ isOpen, setIsOpen ] = useState(false)
  if (!items || items.length === 0) {
    return null
  }

  const baseClass = 'floating-action-button'

  return (
    <div
      data-props={JSON.stringify(props)}
      className={classNames(baseClass, 'js-fab')}
    >
      <div
        className={classNames(
          'floating-action-button__actions',
          isOpen && 'floating-action-button__actions--active'
        )}
      >
        {items.map((actionProps, i) => (
          <ActionButton
            key={actionProps.fabLabel}
            className={isOpen ? 'open' : ''}
            text={actionProps.fabLabel}
            iconType={actionProps.fabIcon}
            href={actionProps.fabUrl}
            style={{
              animationDelay: `${(items.length - i - 1) * 80}ms`,
            }}
          />
        ))}
      </div>
      <button
        className={classNames(
          'floating-action-button__toggle',
          isOpen && 'floating-action-button__toggle--rotated'
        )}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Icon width={35} height={35}>
          <Svgs.Plus />
        </Icon>
      </button>
    </div>
  )
}

FloatingActionButton.propTypes = {
  items: PropTypes.arrayOf({
    fabLabel: PropTypes.string,
    fabIcon: PropTypes.string,
    fabUrl: PropTypes.string,
  }),
}

export default FloatingActionButton
