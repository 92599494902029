import React from 'react'
import PropTypes from 'prop-types'

const MarkerSvg = ({ style }) => (
  <svg width="56" height="63" viewBox="0 0 56 63" style={style}>
    <path
      d="M52,48.85C57.12,50,56.46,53,50.59,55.58S20.13,63,20.13,63s6.22-8.84,12.1-11.45S47,47.74,52,48.85Z"
      fill="#141414"
      opacity="0.5"
    />
    <path
      d="M21,0A21,21,0,0,1,42,21c0,11.6-21,42-21,42S0,32.6,0,21A21,21,0,0,1,21,0Z"
      fill="#006342"
    />
  </svg>
)

MarkerSvg.propTypes = {
  style: PropTypes.object,
}

export default MarkerSvg
