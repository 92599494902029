import React from 'react'

const BtSportSvg = () => (
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="508.86" height="209.45" viewBox="0 0 860.000000 778.000000"
    preserveAspectRatio="xMidYMid meet">

    <g transform="translate(0.000000,778.000000) scale(0.100000,-0.100000)"
      fill="#000000" stroke="none">
      <path d="M4071 7650 c-600 -58 -1146 -314 -1566 -735 -196 -196 -349 -406
      -473 -652 -56 -112 -162 -365 -162 -388 0 -3 474 -5 1053 -5 703 0 1065 -4
      1090 -11 21 -5 53 -24 72 -40 19 -17 159 -224 310 -460 152 -236 281 -429 286
      -429 5 0 9 192 9 470 l0 470 1021 0 1020 0 -6 23 c-45 148 -140 357 -235 518
      -251 423 -625 769 -1065 984 -252 124 -481 196 -748 236 -153 23 -464 32 -606
      19z" />
      <path d="M1787 5496 c-3 -8 -11 -72 -18 -142 -14 -155 -6 -477 16 -619 127
      -817 636 -1519 1370 -1891 405 -204 866 -298 1308 -266 366 27 643 101 963
      258 261 127 470 278 684 493 395 397 646 913 715 1471 18 148 19 483 2 615
      l-12 90 -277 3 -278 2 -2 -752 -3 -753 -252 -3 -253 -2 0 755 0 755 -290 0
      -290 0 0 -755 0 -755 -187 0 c-104 0 -208 5 -233 11 -93 21 -97 26 -472 586
      l-353 528 -5 -560 -5 -560 -245 0 -245 0 -3 753 -2 752 -285 0 -285 0 -2 -752
      -3 -753 -257 -3 -258 -2 0 755 0 755 -269 0 c-225 0 -270 -2 -274 -14z" />
      <path d="M615 1634 c-286 -45 -452 -254 -415 -523 29 -211 138 -293 525 -396
      117 -31 212 -73 240 -105 15 -16 20 -36 20 -75 0 -46 -4 -58 -30 -85 -92 -96
      -334 -73 -526 51 -53 34 -112 38 -146 11 -28 -22 -143 -161 -143 -173 0 -18
      126 -102 216 -146 122 -58 224 -83 364 -90 387 -19 626 186 596 510 -19 206
      -147 315 -477 406 -271 75 -314 101 -314 191 0 64 36 104 114 126 89 26 240
      -4 358 -72 45 -26 95 -30 130 -11 21 11 143 178 143 195 0 12 -119 87 -185
      117 -100 44 -201 65 -325 70 -63 2 -128 1 -145 -1z" />
      <path d="M3508 1630 c-152 -24 -303 -103 -419 -219 -210 -210 -280 -519 -181
      -800 41 -114 86 -184 182 -282 96 -97 185 -152 315 -196 71 -23 96 -26 220
      -27 162 -1 224 12 350 74 191 94 331 260 397 470 20 65 23 95 23 225 -1 138
      -3 157 -28 230 -74 211 -207 363 -402 456 -44 21 -107 46 -140 54 -74 19 -240
      27 -317 15z m215 -315 c212 -45 366 -231 367 -445 0 -120 -43 -223 -132 -316
      -198 -206 -523 -184 -693 47 -179 243 -65 606 217 698 106 34 145 37 241 16z" />
      <path d="M7765 1634 c-286 -45 -455 -257 -416 -523 15 -97 50 -170 109 -222
      78 -69 171 -108 417 -175 119 -32 212 -73 240 -104 15 -16 20 -36 20 -77 0
      -50 -3 -57 -36 -88 -99 -90 -331 -65 -520 56 -54 34 -115 39 -147 12 -38 -33
      -143 -166 -139 -177 14 -44 254 -174 382 -207 113 -29 287 -36 394 -16 266 51
      418 241 397 496 -17 209 -143 317 -478 410 -197 55 -249 75 -284 112 -24 25
      -29 39 -29 79 0 64 36 104 114 126 89 26 240 -4 358 -72 51 -29 102 -31 136
      -4 25 20 137 174 137 188 0 11 -91 69 -166 107 -99 48 -217 75 -349 80 -60 2
      -123 1 -140 -1z" />
      <path d="M1570 870 l0 -750 165 0 165 0 0 224 0 224 203 5 c214 5 256 12 363
      61 130 60 224 164 264 293 22 73 27 234 9 312 -30 132 -124 248 -253 311 -124
      62 -184 69 -578 70 l-338 0 0 -750z m740 414 c103 -59 139 -181 85 -290 -51
      -102 -110 -124 -331 -124 l-164 0 0 226 0 226 178 -4 177 -3 55 -31z" />
      <path d="M4620 871 l0 -751 165 0 165 0 0 240 0 240 128 0 127 0 140 -211 c76
      -116 152 -221 167 -234 38 -33 68 -36 245 -33 l152 3 -181 264 c-104 152 -176
      267 -169 269 6 2 32 15 57 28 164 87 247 232 248 429 0 208 -80 352 -243 434
      -123 63 -136 64 -588 69 l-413 4 0 -751z m795 422 c79 -42 115 -103 115 -192
      0 -90 -59 -166 -149 -191 -20 -5 -125 -10 -233 -10 l-198 0 0 211 0 210 213
      -3 c195 -3 215 -5 252 -25z" />
      <path d="M5990 1470 l0 -150 230 0 230 0 0 -600 0 -600 165 0 165 0 0 599 0
      600 196 3 196 3 29 33 c28 32 29 34 29 147 l0 115 -620 0 -620 0 0 -150z" />
    </g>
  </svg>

)

export default BtSportSvg
