import React from 'react'
import ReactDOM from 'react-dom'
import Calendar from '../../../site/layout/component/calendar/component/Calendar'
const debug = require('debug')('calendar')

const getEncoded = (node) => {
  const encodedFormNode = node || debug('No encoded data')
  if (!encodedFormNode) return false
  const formJson = encodedFormNode.innerHTML
  return JSON.parse(formJson)
}

const render = (containerNode) => {
  const events = getEncoded(containerNode.querySelector('.js-encoded-events'))

  return ReactDOM.render(<Calendar events={events} />, containerNode)
}

const initReact = () => {
  debug('Looking for calendars')
  document.querySelectorAll('.js-calendar').forEach((containerNode) => {
    containerNode.classList.remove('js-calendar')
    render(containerNode)
  })
}

const init = () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', initReact, false)
}

export { init, initReact }
