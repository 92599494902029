import debug from 'debug'
import qs from 'qs'
import updateErrorMessage from '../schedule-filter/lib/error-message-updater'
const log = debug('fixtures-results-filter:log')

export default () => {
  log('Init fixtures and results filter')

  const scheduleFilter = document.querySelector('.js-schedule--form') // Schedule filter form
  if (!scheduleFilter) return false

  // Form selects
  const squadLevelSelect = getSelect(scheduleFilter, 'squadLevel')
  const competitionSelect = getSelect(scheduleFilter, 'competition')
  const seasonSelect = getSelect(scheduleFilter, 'season')
  const coverageSelect = getSelect(scheduleFilter, 'coverage')
  const venueSelect = getSelect(scheduleFilter, 'venue')

  // Seasons
  let seasonOptions = []
  if (seasonSelect) seasonOptions = [ ...seasonSelect.querySelectorAll('option') ]

  // Get current path
  const currentPath = window.location.pathname.split('/')
  const rootLocation = `${currentPath[1]}/${currentPath[2]}`
  let search = window.location.search.substring(1) // remove leading question mark
  const query = qs.parse(search) // create query object from query params

  // Get current season (if present)
  const currentSeason = query.season
  let activeSeason = currentSeason

  if (!activeSeason) activeSeason = seasonSelect ? seasonSelect.value : null

  const validSeasons = [] // Used to verify if season can be selected

  // Used to populate the season select with either selected season or top option
  // without, the season select does not show text
  seasonOptions.forEach((option) => validSeasons.push(option.value))

  if (validSeasons.indexOf(currentSeason) < 0 && seasonSelect) {
    seasonSelect.value = validSeasons[0]
  } else if (seasonSelect) {
    seasonSelect.value = currentSeason
  }

  // Populate all other selects
  competitionSelect.value = currentPath[4] || 'all'
  const currentCompetition = competitionSelect.value

  // Apply a specific error message for Anglo Welsh Cup season 2015/2016
  if (
    currentCompetition === 'anglo-welsh-cup' &&
    activeSeason === '2015-2016'
  ) {
    const angloWelshErrorMessage =
      'In 2015/2016 there was no Anglo-Welsh Cup Competition due to the Rugby World Cup.'
    updateErrorMessage(angloWelshErrorMessage)
  }

  if (coverageSelect) coverageSelect.value = query.coverage || 'all'
  venueSelect.value = query.venue || 'all'
  squadLevelSelect.value = currentPath[3]

  // Add event listeners to listen for when selects change
  squadLevelSelect.addEventListener('change', () => {
    const url = updatePath(rootLocation, squadLevelSelect.value)
    window.location.href = url
  })

  competitionSelect.addEventListener('change', () => {
    const selectedOption =
      competitionSelect.options[competitionSelect.selectedIndex]
    const squadLevel = selectedOption.getAttribute('data-squad-level')
    const url = updatePath(rootLocation, squadLevel, competitionSelect.value)
    window.location.href = url
  })

  if (seasonSelect) {
    seasonSelect.addEventListener('change', () => {
      const url = updateQueryParam('season', seasonSelect.value)
      redirectTo(url)
    })
  }

  venueSelect.addEventListener('change', () => {
    const url = updateQueryParam('venue', venueSelect.value)
    redirectTo(url)
  })

  if (coverageSelect) {
    coverageSelect.addEventListener('change', () => {
      const url = updateQueryParam('coverage', coverageSelect.value)
      redirectTo(url)
    })
  }
}

const getSelect = (form, id) => {
  return form.querySelector(`#control--${id}`)
}

// Create a valid destination url from squad level and competition select values
const updatePath = (rootLocation, squadLevel, competition) => {
  const pathArray = [ squadLevel ]
  if (competition && competition !== 'all') pathArray.push(competition)
  const path = pathArray.join('/')

  let destinationUrl = `/${rootLocation}/${path}`
  return destinationUrl
}

// Update the query param values, if 'all' is selected, remove from query param
const updateQueryParam = (property, value) => {
  const url = getCurrentUrl()
  if (value === 'all') {
    delete url.query[property]
  } else {
    url.query[property] = value
  }
  return url
}

// build url path and redirect page
const redirectTo = (url) => {
  let path = url.path.join('/')
  const query = qs.stringify(url.query)
  if (query) path = path.concat('?', query)
  window.location.href = path
}

// get the current pages url, used to build a location object
const getCurrentUrl = () => {
  const currentUrl = {}
  const search = window.location.search
  currentUrl.path = window.location.pathname.split('/')
  currentUrl.query = qs.parse(search.substring(1))

  return currentUrl
}
