import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Timeline from '../../timeline/component/Timeline'
import TeamCrest from '../../primitive/team-crest/component/TeamCrest'
import classNames from 'classnames'

import getMatchSummary from '../../../../../service/match/lib/match-summary-helper'

const matchStatuses = require('../../../../../service/match/match-statuses')

const Jumbotron = ({ match, commentary, homeScore, awayScore }) => {
  const statusesWithScores = matchStatuses.hasScore.map(({ value }) => value)
  const homeClub = match.__homeClub
  const awayClub = match.__awayClub

  // Only show jumbotron if team data is available and the match has a status with a score to show
  if (
    !match.isLive &&
    (!homeClub || !awayClub || statusesWithScores.indexOf(match.status) < 0)
  )
    return null
  const { period, kickOffTime } = getMatchSummary(match)

  return (
    <Fragment>
      <a name="match-jumbotron" className="offset-anchor" />
      <div className="content-center content-center--small content-gutter">
        <div className="jumbotron">
          <div
            className={classNames('jumbotron__score', {
              'jumbotron--short': match.status === 'Result',
            })}
          >
            <div className="jumbotron__team">
              <div className="jumbotron__crest">
                {match.__homeClub.crestId && (
                  <TeamCrest hasDarkBG team={match.__homeClub.crestId} />
                )}
              </div>
              <div className="jumbotron__team-score-wrapper">
                <span className="jumbotron__team-name">
                  {match.__homeClub.name}
                </span>
                <div className="jumbotron__team-score">{homeScore}</div>
              </div>
            </div>
            <div className="jumbotron__score-block is-hidden-mobile">
              <div className="jumbotron__clock-wrapper">
                <div className="jumbotron__clock">
                  <p className="jumbotron__status">
                    {match.isLive ? 'LIVE' : 'Summary'}
                  </p>
                  <p className="jumbotron__status jumbotron__status--large">
                    {kickOffTime || period}
                  </p>
                </div>
              </div>
            </div>
            <div className="jumbotron__team">
              <div className="jumbotron__crest">
                {match.__awayClub.crestId && (
                  <TeamCrest hasDarkBG team={match.__awayClub.crestId} />
                )}
              </div>
              <div className="jumbotron__team-score-wrapper">
                <span className="jumbotron__team-name">
                  {match.__awayClub.name}
                </span>
                <div className="jumbotron__team-score">{awayScore}</div>
              </div>
            </div>
          </div>
          {commentary && commentary.length > 0 && (
            <div className="jumbotron__timeline">
              <Timeline match={match} commentary={commentary} />
            </div>
          )}
        </div>
      </div>
    </Fragment>
  )
}

Jumbotron.propTypes = {
  match: PropTypes.object.isRequired,
  commentary: PropTypes.array,
  homeScore: PropTypes.number,
  awayScore: PropTypes.number,
}

export default Jumbotron
