import React from 'react'
import ReactDOM from 'react-dom'
import FloatingActionButton from '../../../site/layout/component/floating-action-button/FloatingActionButton'
const debug = require('debug')('countdown')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.js-fab').forEach((appNode) => {
      const props = JSON.parse(appNode.getAttribute('data-props'))
      ReactDOM.render(
        <FloatingActionButton {...props} />,
        appNode
      )
    })
  })
}
