import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const baseClass = 'button'

const Button = ({
  ctaText,
  ctaLink,
  isExternal,
  modifiers,
  isDisabled,
  children,
}) => {
  const modifierClassNames = modifiers
    ? modifiers.map((modifierClass) => `${baseClass}--${modifierClass}`)
    : []
  return (
    <a
      disabled={isDisabled}
      href={ctaLink}
      target={isExternal && '_blank'}
      rel={isExternal && 'noopener'}
      className={classNames(baseClass, modifierClassNames)}
    >
      {ctaText}
      {children}
    </a>
  )
}

Button.propTypes = {
  ctaText: PropTypes.string.isRequired,
  ctaLink: PropTypes.string,
  modifiers: PropTypes.array,
  isDisabled: PropTypes.bool,
  isExternal: PropTypes.bool,
  children: PropTypes.node,
}

export default Button
