import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Field from '../../primitive/field/component/Field'
import { isEmpty } from 'lodash'
const baseClass = 'pop-promotion'

class PopPromotion extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      // initially `true` so it doesn't flash for users with it dismissed
      isDismissed: true,
    }
    this.setValues = this.props.setValues
    this.dismissPopup = this.dismissPopup.bind(this)
    this.emailSignUpOptions = this.props.emailSignUpOptions || []
  }

  componentDidMount() {
    const defaults = {
      emailSignUpOptions: this.emailSignUpOptions,
    }

    if (typeof window !== 'undefined') {
      const hasDismissed = window.localStorage.getItem(
        `pop-promotion--${this.props.id}`
      )
      this.setState({
        isDismissed: !!hasDismissed,
      })
    } else {
      this.setState({
        isDismissed: false,
      })
    }

    this.setValues(defaults)
  }

  componentDidUpdate() {
    const { isDismissed } = this.state
    const { error, successMessage, ctaText, ctaLink } = this.props

    if (
      !isDismissed &&
      isEmpty(error) &&
      successMessage &&
      ctaText &&
      ctaLink
    ) {
      this.dismissPopup()
      location.href = ctaLink
    }
  }

  dismissPopup() {
    if (typeof window !== 'undefined') {
      window.localStorage.setItem(`pop-promotion--${this.props.id}`, true)
    }
    this.setState({
      isDismissed: !this.state.isDismissed,
    })
  }

  render() {
    const { isDismissed } = this.state
    const {
      error,
      title,
      imgUrl,
      ctaLink,
      ctaText,
      onChange,
      onSubmit,
      dismissText,
      description,
      captureData,
      promotionUrl,
      errorMessage,
      successMessage,
      emailSignUpOptions,
    } = this.props
    return (
      <div>
        {!isDismissed && (
          <div>
            <div
              style={imgUrl ? { backgroundImage: `url(${imgUrl})` } : null}
              className={baseClass}
            >
              <a className="pop-promotion__dismiss" onClick={this.dismissPopup}>
                Close
              </a>
              <div className="content-center content-center--medium pop-promotion__inner">
                <div className="grid">
                  <div className="grid__item mobile-one-whole">
                    {promotionUrl && (
                      <img
                        className="pop-promotion__image"
                        src={promotionUrl}
                        alt="Promotion image"
                        loading="lazy"
                      />
                    )}
                    {(title || description) && (
                      <div className="pop-promotion__text">
                        <div className="prose">
                          {title && <h3>{title}</h3>}
                          {description && <p>{description}</p>}
                        </div>
                      </div>
                    )}

                    {captureData && (
                      <div className="pop-promotion__form">
                        {!successMessage && (
                          <form
                            className="form"
                            method="post"
                            id="sign-up"
                            onSubmit={onSubmit}
                          >
                            <input
                              type="hidden"
                              name="emailSignUpOptions"
                              value={JSON.stringify(emailSignUpOptions)}
                            />
                            {errorMessage && (
                              <div className="notification notification--error">
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: errorMessage,
                                  }}
                                />
                              </div>
                            )}
                            <div className="grid">
                              <div className="grid__item tablet-one-half desktop-one-third">
                                <Field
                                  name="fullName"
                                  type="text"
                                  showRequired={false}
                                  onChange={onChange}
                                  placeholder="Name"
                                  error={error.fullName}
                                  aria-label="Name"
                                  id="Name--PopPromotion"
                                />
                              </div>
                              <div className="grid__item tablet-one-half desktop-one-third">
                                <Field
                                  name="email"
                                  type="email"
                                  showRequired={false}
                                  onChange={onChange}
                                  placeholder="Email"
                                  error={error.email}
                                  aria-label="Email"
                                  id="Email--PopPromotion"
                                />
                              </div>
                              <div className="grid__item desktop-one-third">
                                <button
                                  form="sign-up"
                                  type="submit"
                                  className="button button--primary button--block"
                                >
                                  {ctaText || 'Submit'}
                                </button>
                              </div>
                            </div>
                          </form>
                        )}
                      </div>
                    )}
                  </div>
                  {ctaText && ctaLink && !captureData && (
                    <div className="grid__item mobile-one-whole tablet-one-half desktop-one-half">
                      <a
                        className="button button--primary"
                        href={ctaLink}
                        onClick={this.dismissPopup}
                      >
                        {ctaText}
                      </a>
                    </div>
                  )}
                  <div
                    className={classNames(
                      'grid__item mobile-one-whole',
                      !captureData && 'tablet-one-half desktop-one-half'
                    )}
                  >
                    {dismissText && (
                      <a
                        onClick={this.dismissPopup}
                        className="button button--primary"
                      >
                        {dismissText}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <a className="pop-promotion__dimmer" onClick={this.dismissPopup} />
          </div>
        )}
      </div>
    )
  }
}

PopPromotion.propTypes = {
  imgUrl: PropTypes.string,
  promotionUrl: PropTypes.string,
  ctaText: PropTypes.string,
  ctaLink: PropTypes.string,
  dismissText: PropTypes.string,
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  captureData: PropTypes.bool,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  setValues: PropTypes.func,
  error: PropTypes.object,
  errorMessage: PropTypes.string,
  successMessage: PropTypes.string,
  emailSignUpOptions: PropTypes.array,
}

export default PopPromotion
