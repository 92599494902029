const teamNames = require('./squad-names')()
const slug = require('slugg')

export const getAbbreviation = (name) => {
  const team = teamNames[slug(name)]
  if (!name) return ' '
  if (!team) return name.substring(0, 3).toUpperCase()
  return team.abbreviation
}
export const getDisplayName = (name) => {
  const team = teamNames[slug(name)]
  if (!team) return name
  return team.displayName
}

export const getNickname = (name) => {
  const team = teamNames[slug(name)]
  if (!team) return name
  return team.nickname
}
