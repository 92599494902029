export default (serviceLocator) => {
  const bm = serviceLocator.get('breakpointManager')
  const wrappers = document.querySelectorAll('.js-mobile-match-row-actions')

  bm.on('enter:mobile', () => {
    wrappers.forEach((wrapper) => {
      wrapper.classList.add('is-hidden')
    })
  })

  bm.on('enter:tablet', () => {
    wrappers.forEach((wrapper) => {
      wrapper.classList.remove('is-hidden')
    })
  })
}
