import React from 'react'

const YellowCardSvg = () => (
  <svg width="15.76" height="18.56" viewBox="0 0 15.76 18.56">
    <path
      data-name="Rectangle 1066 copy"
      fill="#fff200"
      fillRule="evenodd"
      d="M4.15 0l11.6 3.11-4.15 15.45L.01 15.45z"
    />
  </svg>
)

export default YellowCardSvg
