import React from 'react'
import ReactDOM from 'react-dom'
import EventRowGroupDaysContainer from '../../../site/layout/component/event-row-actions/component/EventRowGroupDaysContainer'
const debug = require('debug')('group-days')

const init = () => {
  debug('Init')
  document.addEventListener('DOMContentLoaded', initGroupDays)
}

const initGroupDays = (prefixUrl) => {
  const appNodes = document.querySelectorAll('.js-group-day-form')

  if (appNodes.length === 0) {
    return debug('No group days forms found. Skipping.')
  }

  appNodes.forEach((appNode) => {
    const {
      matchId,
      checksum,
      toEmailAddress,
      groupDayTitle,
      groupDayBody,
      groupDayImage,
    } = appNode.dataset

    ReactDOM.render(
      <EventRowGroupDaysContainer
        title={groupDayTitle}
        body={groupDayBody}
        image={groupDayImage}
        matchId={matchId}
        checksum={checksum}
        toEmailAddress={toEmailAddress}
      />,
      appNode
    )
  })
}

export { init, initGroupDays }
