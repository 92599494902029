import React from 'react'
import ReactDOM from 'react-dom'
import FrequentlyAsked from '../../../site/layout/component/frequently-asked'
const debug = require('debug')('frequently-asked')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    debug('init FrequentlyAsked')
    const wrappers = Array.from(
      document.querySelectorAll('.js-frequently-asked')
    )

    if (wrappers.length < 1) return

    wrappers.forEach((wrapper) => {
      const props = JSON.parse(wrapper.getAttribute('data-props'))
      ReactDOM.render(<FrequentlyAsked {...props} />, wrapper)
    })
  })
}
