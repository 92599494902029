let commentaryTypes = [
  {
    type: 'text',
    text: 'Text',
    modifiers: [],
    requiresScoreUpdate: false,
    requiresSquad: false,
  },
  {
    type: 'conversion',
    text: 'Conversion',
    icon: 'Conversion',
    modifiers: [ 'score' ],
    requiresScoreUpdate: true,
    requiresSquad: true,
  },
  {
    type: 'halfTime',
    text: 'Half Time',
    icon: 'Whistle',
    modifiers: [ 'emphasis' ],
    requiresScoreUpdate: false,
    requiresSquad: false,
  },
  {
    type: 'try',
    text: 'Try',
    icon: 'Ball',
    modifiers: [ 'score' ],
    requiresScoreUpdate: true,
    requiresSquad: true,
  },
  {
    type: 'kickOff',
    text: 'Kick Off',
    icon: 'Whistle',
    modifiers: [ 'emphasis' ],
    requiresScoreUpdate: false,
    requiresSquad: false,
  },
  {
    type: 'redCard',
    text: 'Red Card',
    icon: 'SentOff',
    modifiers: [ 'red-card' ],
    requiresScoreUpdate: false,
    requiresSquad: true,
  },
  {
    type: 'substitution',
    text: 'Substitution',
    icon: 'Substitution',
    modifiers: [],
    requiresScoreUpdate: false,
    requiresSquad: true,
  },
  {
    type: 'yellowCard',
    text: 'Yellow Card',
    icon: 'SinBin',
    modifiers: [ 'yellow-card' ],
    requiresScoreUpdate: false,
    requiresSquad: true,
  },
  {
    type: 'dropGoal',
    text: 'Drop Goal',
    icon: 'DropGoal',
    modifiers: [ 'score' ],
    requiresScoreUpdate: true,
    requiresSquad: true,
  },
  {
    type: 'tweet',
    text: 'Tweet',
    icon: 'Twitter',
    modifiers: [ 'twitter' ],
    requiresScoreUpdate: false,
    requiresSquad: false,
    requiresSocialId: true,
  },
  {
    type: 'penalty',
    text: 'Penalty',
    icon: 'Penalty',
    modifiers: [ 'score' ],
    requiresScoreUpdate: true,
    requiresSquad: true,
  },
  {
    type: 'fullTime',
    text: 'Full Time',
    icon: 'Whistle',
    modifiers: [ 'emphasis' ],
    requiresScoreUpdate: false,
    requiresSquad: false,
  },
]

// Reorder into columns
const tmp = []
const len = commentaryTypes.length
const columnCount = 3
const rowCount = Math.floor(len / columnCount)
for (let x = 0; x < columnCount; x += 1) {
  for (let y = 0; y < rowCount; y += 1) {
    tmp.push(commentaryTypes[x + y * columnCount])
  }
}

commentaryTypes = tmp

module.exports.all = () => commentaryTypes
module.exports.getCommentaryByType = (type) =>
  commentaryTypes.find((commentary) => commentary.type === type)
module.exports.commentaryRequiringScoreUpdates = () =>
  commentaryTypes.filter((commentary) => commentary.requiresScoreUpdate)
module.exports.commentaryRequiringSquad = () =>
  commentaryTypes.filter((commentary) => commentary.requiresSquad)
module.exports.requiresSocialId = () =>
  commentaryTypes.filter((commentary) => commentary.requiresSocialId)
