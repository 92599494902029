import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Icon from '../../primitive/icon/component/Icon'
import Svgs from '../../primitive/svgs/component/Svgs'
import versionPath from '../../../../../../site/lib/version-path'

const formatSeason = (season) => {
  const seasons = season.split('-')
  const startSeason = seasons[0].substring(2)
  const endSeason = seasons[1].substring(2)
  return `'${startSeason}-'${endSeason}`
}

const initialiseMatchExpander = (length) => {
  const state = {}
  for (let i = 0; i < length; i++) state[i] = false
  return state
}

const logoMap = {
  'Anglo-Welsh Cup': Svgs.AngloWelsh,
  'Club Friendly': null,
  'English Premiership': '/assets/img/content/gallagher.png',
  'Premiership Rugby Cup': '/assets/img/content/premiership-rugby-cup.png',
  'English Premiership A League': '/assets/img/content/premiership-rugby-shield.png',
  'European Cup': '/assets/img/content/champions-cup.svg',
  'Challenge Cup': '/assets/img/content/challenge-cup.png',
  "Women's Premier 15s Cup": '/assets/img/content/allianz-cup.png',
      "Women's Premier 15s": '/assets/img/content/PWR.jpg'
}

const insertCompetitionBadge = (name) => {
  const Badge = logoMap[name]
  if (typeof Badge === "string") {
    return (
      <div className="person-stats__competition-logo">
        <img
          src={versionPath(Badge)}
          alt={name}
          loading="lazy"
        />
      </div>  
    )
  }

  if (!Badge) {
    return name
  } else {
    return (
      Badge && (
        <div className="person-stats__competition-logo">
          <Badge />
        </div>
      )
    )
  }
}

class PersonStats extends React.Component {
  constructor(props) {
    super(props)
    this.handleShowHide = this.handleShowHide.bind(this)

    this.state = {
      matchExpander: this.props.stats
        ? initialiseMatchExpander(Object.keys(this.props.stats).length)
        : {},
    }
  }

  handleShowHide(i) {
    this.setState((prevState) => ({
      matchExpander: {
        ...prevState.matchExpander,
        [i]: !prevState.matchExpander[i],
      },
    }))
  }

  render() {
    const { stats } = this.props
    if (!stats || Object.keys(stats).length === 0) return null
    const seasons = Object.keys(stats).sort().reverse()
    var total = {}
    return (
      <Fragment>
        <h3 className="person-stats__title">Player Stats</h3>
        <div className="person-stats">
          <table>
            <thead>
              <tr>
                <th>Season</th>
                <th>Apps</th>
                <th>T</th>
                <th>C</th>
                <th>DG</th>
                <th>P</th>
                <th>PTS</th>
              </tr>
            </thead>
          </table>
          {seasons.map((season, i) => {
            Object.keys(stats[season]).map((key) => {
              const stat = stats[season][key]
              total[key] = (total[key] || 0) + stat
            })
            return (
              <Fragment key={`person-year-stat-${i}`}>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        {formatSeason(season)}
                        {stats[season].matches && (
                          <button
                            type="button"
                            className="person-stats__expand-btn"
                            onClick={() => this.handleShowHide(i)}
                          >
                            <Icon width={18} height={12}>
                              {this.state.matchExpander[i] ? (
                                <Svgs.ArrowUp />
                              ) : (
                                <Svgs.ArrowDown />
                              )}
                            </Icon>
                          </button>
                        )}
                      </td>
                      <td>
                        {stats[season].started} (
                        {stats[season].appeared - stats[season].started})
                      </td>
                      <td>{stats[season].tries}</td>
                      <td>{stats[season].conversions}</td>
                      <td>{stats[season].dropGoals}</td>
                      <td>{stats[season].penalties}</td>
                      <td>{stats[season].points}</td>
                    </tr>
                  </tbody>
                </table>
                {this.state.matchExpander[i] && (
                  <div className="person-stats__expanded">
                    <table>
                      <thead>
                        <tr>
                          <th className="shrink">Match Date</th>
                          <th className="shrink">Competition</th>
                          <th>Opposition</th>
                          <th className="shrink">Venue</th>
                          <th className="shrink">Position</th>
                          <th className="no-wrap shrink">Result</th>
                          <th className="shrink">T</th>
                          <th className="shrink">C</th>
                          <th className="shrink">P</th>
                          <th className="shrink">G</th>
                          <th className="shrink">PTS</th>
                        </tr>
                      </thead>
                      <tbody>
                        {stats[season].matches.map((match, index) => (
                          <tr key={`person-match-stat-${index}`}>
                            <td className="shrink">{match.date}</td>
                            <td className="shrink">{insertCompetitionBadge(match.competition)}</td>
                            <td>{match.against}</td>
                            <td className="shrink capitalise">{match.venue}</td>
                            <td className="shrink">{match.position}</td>
                            <td className="no-wrap shrink">{match.result}</td>
                            <td className="shrink">{match.tries}</td>
                            <td className="shrink">{match.conversions}</td>
                            <td className="shrink">{match.penalties}</td>
                            <td className="shrink">{match.goals}</td>
                            <td className="shrink">{match.points}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </Fragment>
            )
          })}
          <table key={`person-year-stat-total`}>
            <tbody>
              <tr className="person-stats__total">
                <td>Total</td>
                <td>
                  {total.started} ({total.appeared - total.started})
                </td>
                <td>{total.tries}</td>
                <td>{total.conversions}</td>
                <td>{total.dropGoals}</td>
                <td>{total.penalties}</td>
                <td>{total.points}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Fragment>
    )
  }
}

PersonStats.propTypes = {
  stats: PropTypes.object,
}

export default PersonStats
