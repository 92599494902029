import React from 'react'
import PropTypes from 'prop-types'
import TeamCrest from '../../primitive/team-crest/component/TeamCrest'
import getMatchSummary from '../../../../../service/match/lib/match-summary-helper'

const MatchScore = ({ match, homeScore, awayScore }) => {
  if (!match) return null
  const homeClub = match.__homeClub
  const awayClub = match.__awayClub
  if (!match || !homeClub || !awayClub) return null

  const { time, period, kickOffTime } = getMatchSummary(match)
  const data = [
    {
      team: homeClub,
      score: homeScore,
    },
    {
      team: awayClub,
      score: awayScore,
    },
  ]

  return (
    <div className="match-score">
      <div className="match-score__status">
        <div className="auto-justify">
          {(time || kickOffTime) && <span>{time || kickOffTime}</span>}
          {!kickOffTime && <span>{period}</span>}
        </div>
      </div>
      <div className="match-score__teams">
        {data.map((item, i) => (
          <div key={`match-score-${i}`} className="match-score__team">
            <div className="match-score__crest">
              <TeamCrest hasDarkBG team={item.team.crestId} />
            </div>
            <div className="match-score__team-score-wrapper">
              <span className="match-score__team-name">{item.team.name}</span>
              <div className="match-score__team-score">{item.score}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
MatchScore.propTypes = {
  match: PropTypes.object.isRequired,
  homeScore: PropTypes.number.isRequired,
  awayScore: PropTypes.number.isRequired,
}

export default MatchScore
