import React from 'react'

const BallSvg = () => (
  <svg width="17" height="17" viewBox="0 0 17 17">
    <path
      vectorEffect="non-scaling-stroke"
      d="M15.6 1.4C14.6.5 13.4.1 12.1.2 9 .3 6 1.6 3.8 3.9 2.3 5.6 1.2 7.7.6 9.9c-.7 2.5-.4 4.6.8 5.7.9.8 2.1 1.3 3.4 1.2 3.1-.1 6.1-1.4 8.4-3.6 2.2-2.3 3.5-5.2 3.6-8.4 0-1.2-.4-2.4-1.2-3.4zM4.4 12s-1 .5-1.4.3-.5-1.2-.2-2.6c.3-2 1.2-3.8 2.5-5.3 1-1 2.2-1.8 3.5-2.3 1.2-.5 2.5-.8 3.8-.8.7 0 1.4.1 2.1.5.2.1.2.9-.3 1.8-1 1.6-2.2 3-3.6 4.3-2.1 1.5-4.2 2.9-6.4 4.1zm8-.2C11.1 13 9.5 14 7.8 14.5h-.1c-.6.1-1.2.2-1.8.1-.4-.1-.3-.5 0-.7 1.6-1.2 3.2-2.6 4.6-4 1.4-1.4 4.2-4.4 5-6h.1v.6c-.2 2.7-1.3 5.3-3.2 7.3z"
    />
  </svg>
)

export default BallSvg
