import React from 'react'
import PropTypes from 'prop-types'
import BaseControl from '../BaseControl'
import classNames from 'classnames'
import fecha from 'fecha'

const hasWindow = typeof window !== 'undefined'
let Pikaday = hasWindow ? require('pikaday') : {}

class DateControl extends React.Component {
  componentDidMount() {
    if (!hasWindow) return false
    this.picker = new Pikaday({
      field: this.input,
      yearRange: [ 1920, new Date().getFullYear() ],
      onSelect: (date) => {
        this.props.onChange({
          currentTarget: {
            name: this.props.name,
            value: fecha.format(date, this.props.format),
          },
          target: {},
        })
      },
    })
  }

  render() {
    return (
      <BaseControl
        {...this.props}
        className={classNames(this.props.className, 'control--date')}
        element="input"
        controlRef={(input) => {
          this.input = input
        }}
        placeholder={this.props.placeholder}
      />
    )
  }
}

DateControl.defaultProps = {
  format: 'DD MMMM YYYY',
}

DateControl.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  format: PropTypes.string,
}

export default DateControl
