import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
// import classNames from 'classnames'
import versionPath from '../../../../../../site/lib/version-path.js'
import SectionHeading from '../../primitive/section-heading/component/SectionHeading'
import Field from '../../primitive/field/component/Field'
import TeamCrest from '../../primitive/team-crest/component/TeamCrest'

const style = {
  backgroundImage: `url(${versionPath('/assets/img/content/big-hero.png')})`,
}

const ScorePredictor = ({
  isLive,
  match,
  onSubmit,
  onChange,
  successMessage,
  error = {},
  errorMessage,
}) => {
  return (
    <form className="form" method="post" onSubmit={onSubmit}>
      <div className="score-predictor-hero" style={style}>
        <div className="grid">
          <div className="grid__item desktop-one-half">
            <div className="score-predictor-hero__textblock">
              <h1 className="score-predictor-hero__title">
                <span className="score-predictor-hero__club">
                  Northampton Saints&nbsp;
                </span>
                <span className="score-predictor-hero__line-wrapper">
                  Score&nbsp;
                </span>
                Predictor
              </h1>
              <p>
                Be in with a chance to win a Saints shirt every week by guessing
                as close to the correct score as possible. Winners will be
                selected at random from the nearest correct entry.
              </p>
              <p>* Terms and Conditions Apply</p>
            </div>
          </div>
          <div className="grid__item desktop-one-half">
            <div className="score-predictor-hero__image">
              <img
                src={versionPath('/assets/img/content/2018-Shirt-small.png')}
                alt="hero-image"
                loading="lazy"
              />
              <p className="score-predictor-hero__text-bubble">
                <span className="win-text">WIN</span> a Saints{' '}
                <span className="shirt-text">home shirt</span>
              </p>
            </div>
          </div>
        </div>
      </div>

      {!isLive ? (
        <div className="score-predictor-scoreboard">
          <div className="score-predictor-scoreboard__panel">
            <div className="score-predictor-scoreboard__panel-inner">
              <p className="live-message">
                Score prediction is currently closed, please try again later!
              </p>
            </div>
          </div>
        </div>
      ) : (
        <Fragment>
          <div className="score-predictor-scoreboard">
            <p className="score-predictor-scoreboard__text-instruction">
              Enter your prediction<span> into the scoreboard below</span>
            </p>
            <div className="score-predictor-scoreboard__panel">
              <div className="score-predictor-scoreboard__panel-inner">
                <div className="score-predictor-scoreboard__left-content">
                  {match.__homeClub.crestId && (
                    <div className="score-predictor-scoreboard__left-badge">
                      <TeamCrest hasDarkBG team={match.__homeClub.crestId} />
                    </div>
                  )}
                  <p className="score-predictor-scoreboard__left-team">
                    {match.__homeClub.shortName}
                  </p>
                  <div className="score-predictor-scoreboard__left-score">
                    <Field
                      name="homeScore"
                      type="number"
                      min="0"
                      step="1"
                      onChange={onChange}
                      error={error.homeScore}
                    />
                  </div>
                </div>
                <div className="score-predictor-scoreboard__versus">V</div>
                <div className="score-predictor-scoreboard__right-content">
                  {match.__awayClub.crestId && (
                    <div className="score-predictor-scoreboard__right-badge">
                      <TeamCrest hasDarkBG team={match.__awayClub.crestId} />
                    </div>
                  )}
                  <p className="score-predictor-scoreboard__right-team">
                    {match.__awayClub.shortName}
                  </p>
                  <div className="score-predictor-scoreboard__right-score">
                    <Field
                      name="awayScore"
                      type="number"
                      min="0"
                      step="1"
                      onChange={onChange}
                      error={error.awayScore}
                    />
                  </div>
                </div>
              </div>
              <p className="score-predictor-scoreboard__summary">
                Score Predictor Competition will close at match kick-off
              </p>
            </div>
            <div className="score-predictor-form">
              <div className="content-center content-gutter content-center--medium">
                <SectionHeading bottomMargin element="h2">
                  Your Details
                </SectionHeading>
                {error && Object.keys(error).length > 0 && (
                  <div className="notification notification--error">
                    <h3 className="notification__title">
                      There was a problem with your submission.
                    </h3>
                    {errorMessage && (
                      <p dangerouslySetInnerHTML={{ __html: errorMessage }} />
                    )}
                    {!errorMessage && <p>Please check below and try again.</p>}
                  </div>
                )}
                {successMessage ? (
                  <p className="email-signup__message">{successMessage}</p>
                ) : (
                  <Fragment>
                    <input
                      type="hidden"
                      name="eventDate"
                      value={match.dateTime}
                    />
                    <div className="score-predictor-form__grid">
                      <div className="grid">
                        <div className="grid__item tablet-one-half">
                          <Field
                            name="firstName"
                            type="text"
                            placeholder="First Name*"
                            onChange={onChange}
                            error={error.firstName}
                          />
                        </div>
                        <div className="grid__item tablet-one-half">
                          <Field
                            name="lastName"
                            type="text"
                            placeholder="Last Name*"
                            onChange={onChange}
                            error={error.lastName}
                          />
                        </div>
                        <div className="grid__item tablet-one-half">
                          <Field
                            name="email"
                            type="email"
                            placeholder="Email*"
                            onChange={onChange}
                            error={error.email}
                          />
                        </div>
                        <div className="grid__item tablet-one-half">
                          <Field
                            name="telephone"
                            type="text"
                            placeholder="Telephone"
                            onChange={onChange}
                            error={error.telephone}
                          />
                        </div>
                        <div className="grid__item tablet-one-half">
                          <Field
                            name="dateOfBirth"
                            type="datePicker"
                            format="YYYY-MM-DD"
                            onChange={onChange}
                            placeholder="Date of birth"
                            error={error.dateOfBirth}
                          />
                        </div>
                        <div className="grid__item tablet-one-half">
                          <span />
                        </div>
                        <div className="grid__item tablet-one-whole">
                          <SectionHeading bottomMargin element="h2">
                            Newsletter Preferences
                          </SectionHeading>
                          <Field
                            name="nsaintsOptIn"
                            type="checkbox"
                            onChange={onChange}
                            options={[
                              {
                                text:
                                  'Please tick if you would you like to receive updates from Northampton Saints.',
                                value: 'yes',
                              },
                            ]}
                            className="preference-centre__checkbox"
                          />
                        </div>
                      </div>
                      <hr />
                    </div>
                    <p>* Required fields</p>
                    <button
                      type="submit"
                      className="button button--primary button--float-right"
                    >
                      Submit
                    </button>
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        </Fragment>
      )}
      <div className="content-center content-gutter content-center--medium">
        <p className="terms-and-conditions">
          <span> * Terms and Conditions</span>
          <span>Each correct entry will win a 2018/19 replica shirt. </span>
          <span>
            If no outright winner then the nearest score prediction will win the
            shirt. If more than one person is nearest, then winner picked at
            random. Criteria: must predict correct match result, score
            prediction refers to match score rather than points difference.
          </span>
        </p>
      </div>
    </form>
  )
}

ScorePredictor.propTypes = {
  match: PropTypes.object,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  error: PropTypes.object,
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  isLive: PropTypes.bool,
}

export default ScorePredictor
