import React from 'react'
import ReactDOM from 'react-dom'
import Countdown from '../../../site/layout/component/countdown/container/Countdown'
const debug = require('debug')('countdown')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.js-countdown').forEach((appNode) => {
      const startTime = new Date(appNode.dataset.startTime)
      const text = appNode.dataset.text
      const modifiers = appNode.dataset.modifiers
      const tbc = appNode.dataset.tbc

      ReactDOM.render(
        <Countdown
          startTime={startTime}
          text={text}
          modifiers={modifiers}
          tbc={tbc}
        />,
        appNode
      )
    })
  })
}
