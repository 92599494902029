import React from 'react'

const FabTickets = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 428 428"
    {...props}
  >
    <path
      fill="#2D6245"
      stroke="#E7B548"
      strokeMiterlimit={10}
      strokeWidth={22}
      d="M213.74 416.54c111.937 0 202.68-90.743 202.68-202.68 0-111.937-90.743-202.68-202.68-202.68-111.937 0-202.68 90.743-202.68 202.68 0 111.937 90.743 202.68 202.68 202.68Z"
    />
    <path
      fill="#fff"
      d="M233.69 81.56c-.12 1.07-.28 2.15-.5 3.22-3.88 18.98-22.41 31.21-41.39 27.33-18.98-3.88-31.21-22.41-27.33-41.39.22-1.08.49-2.13.81-3.16l-34.89-7.14-44.95 219.67 34.87 7.14c.12-1.01.27-2.02.48-3.03 3.88-18.98 22.41-31.21 41.39-27.33 18.98 3.88 31.21 22.41 27.33 41.39-.21 1.01-.46 2-.75 2.98l34.87 7.14 44.95-219.67-34.89-7.15Z"
    />
    <path
      fill="#2D6245"
      d="m142.141 115.264-15.205-3.112-1.036 5.065 15.205 3.112 1.036-5.065Zm21.86 4.472-15.204-3.112-1.037 5.065 15.205 3.112 1.036-5.065Zm21.851 4.47-15.205-3.112-1.037 5.065 15.205 3.112 1.037-5.065Zm21.86 4.471-15.205-3.111-1.037 5.065 15.205 3.111 1.037-5.065Zm21.848 4.48-15.205-3.112-1.036 5.065 15.205 3.112 1.036-5.065Zm21.86 4.472-15.205-3.112-1.036 5.065 15.205 3.112 1.036-5.065Z"
    />
    <path
      fill="#E7B447"
      d="M343.06 166.63c-.44.98-.93 1.96-1.47 2.91-9.54 16.86-30.94 22.78-47.8 13.24-16.86-9.54-22.78-30.94-13.24-47.8.54-.96 1.13-1.87 1.74-2.76l-30.99-17.54-110.45 195.14 30.98 17.54c.42-.92.88-1.84 1.39-2.74 9.54-16.86 30.94-22.78 47.8-13.24 16.86 9.54 22.78 30.94 13.24 47.8-.51.9-1.06 1.76-1.63 2.6l30.98 17.54L374.07 184.2l-31.01-17.57Z"
    />
    <path
      fill="#2D6245"
      d="m245.601 170.453-13.505-7.645-2.547 4.499 13.505 7.645 2.547-4.499Zm19.41 10.988-13.506-7.645-2.546 4.499 13.505 7.645 2.547-4.499Zm19.41 10.987-13.506-7.645-2.547 4.499 13.506 7.645 2.547-4.499Zm19.418 10.993-13.506-7.645-2.546 4.499 13.505 7.645 2.547-4.499Zm19.41 10.988-13.506-7.646-2.546 4.499 13.505 7.646 2.547-4.499Zm19.41 10.987-13.506-7.645-2.547 4.499 13.506 7.645 2.547-4.499Z"
    />
    <path
      fill="#000"
      d="M221.08 279.79s-7.42 4.89-11.34 5.84c-6.56 1.6-11.2-1.86-11.2-1.86l-16.57-9.38 14.35-25.34 17.21 9.74s4.59 2.67 6.45 8.22c1.74 5.23 1.1 12.78 1.1 12.78Z"
    />
    <path
      fill="#2D6245"
      d="M149.8 223.5s-5.55 6.94-8.98 9.05c-5.75 3.54-11.23 1.68-11.23 1.68l-18.66-3.82 5.84-28.53 19.37 3.96s5.19 1.12 8.67 5.83c3.28 4.45 4.99 11.83 4.99 11.83Z"
    />
    <path
      fill="#000"
      d="m230.826 269.22-13.994 24.723 2.192 1.241 13.995-24.722-2.193-1.242Zm6.252 3.541-13.995 24.722 2.193 1.242 13.994-24.723-2.192-1.241Z"
    />
    <path
      fill="#2C6438"
      d="m155.509 211.406-5.697 27.833 2.469.505 5.696-27.833-2.468-.505Zm7.038 1.437-5.696 27.834 2.469.505 5.696-27.833-2.469-.506Z"
    />
  </svg>
)

export default FabTickets
