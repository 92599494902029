export default (serviceLocator) => {
  const actionList = document.querySelector('.action-list')
  if (!actionList) return
  const toggle = actionList.querySelector('.js-action-list-toggle')
  const getColumnWidth = () => actionList.closest('.grid__item').clientWidth
  const bm = serviceLocator.get('breakpointManager')

  const handleToggle = () => {
    actionList.classList.toggle('is-open')

    if (actionList.classList.contains('is-open')) {
      actionList.closest('.sticky').classList.add('overflow-visible')
    } else {
      setTimeout(() => {
        actionList.closest('.sticky').classList.remove('overflow-visible')
      }, 400)
    }

    // set distance to pop out
    // 320 is action list width + gutter
    const width = actionList.classList.contains('is-open')
      ? 320 - getColumnWidth()
      : 0

    actionList.style.right = `${width}px`
  }

  bm.on('enter:actionList', () => {
    toggle.addEventListener('click', handleToggle)
  })

  bm.on('exit:actionList', () => {
    toggle.removeEventListener('click', handleToggle)
  })

  window.addEventListener('resize', () => {
    actionList.classList.remove('is-open')
    actionList.style.right = '0'
  })
}
