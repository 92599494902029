import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

import breakpoints from './breakpoints'
import createBreakpointManager from 'break'
import regg from 'regg'

import 'classlist-polyfill'
import 'element-closest'
import 'nodelist-foreach-polyfill'
import elementDataSet from 'element-dataset'

const config = JSON.parse(
  document.querySelector('body').getAttribute('data-browser-config')
)

const debug = require('debug')('base')
const env = process.env.NODE_ENV || 'development'

debug('Init')
debug('NODE_ENV', env)
elementDataSet()
const bm = createBreakpointManager()
const serviceLocator = regg()

Sentry.init({
  dsn: config.sentry.site.dsn,
  environment: env,
  integrations: [
    new BrowserTracing()
  ],
  ignoreErrors: [
    'document.getElementsByClassName.ToString', // ignore a samsung internet only issue
  ],
  tracesSampleRate: 0.01,
})

// Look for an encoded article
const encodedEntityNode = document.querySelector('#encoded-entity')
if (encodedEntityNode) {
  const entityJson = encodedEntityNode.innerHTML
  try {
    window.__entity = JSON.parse(entityJson)
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
  }
}

const components = [
  require('./sticky/init').default,
  require('./carousel/init').init,
  require('./mobile-navigation/init').default,
  require('./load-more/init').default,
  require('./table-filter/init').default,
  require('./show-results/init').default,
  require('./schedule-filter/init').default,
  require('./auction-filter/init').default,
  require('./countdown/init').default,
  require('./nouislider/init').default,
  require('./action-list/init').default,
  require('./jump-menu/init').default,
  require('./frequently-asked/init').default,
  require('./toggler/init').default,
  require('./unique-switcher/init').default,
  require('./rugby-camp-finder/init').default,
  require('./event-filter/init').default,
  require('./article-filter/init').default,
  require('./email-signup/init').default,
  require('./enquiry/init').default,
  require('./enquiry-salesforce/init').default,
  require('./email-signup-flamboyant/init').default,
  require('./preference-centre/init').default,
  require('./event-filter/init').default,
  require('./fixture-event-filter/init').default,
  require('./group-days/init').init,
  require('./match-day-live/init').default,
  require('./form-builder/init').init,
  require('./embedded-social/init').default,
  require('./click-tracker/init').default,
  require('./disqus/init').default,
  require('./polls/init').init,
  require('./videos/init').init,
  require('./calendar/init').init,
  require('./video-preview/init').init,
  require('./calendar/init').init,
  require('./mobile-match-row-actions/init').default,
  require('./score-predictor/init').default,
  require('./notify-me/init').default,
  require('./pop-promotion/init').default,
  require('./search/init').default,
  require('./person-stats/init').default,
  require('./bidding-form/init').default,
  require('./floating-action-button/init').default
]

process.nextTick(() => {
  bm.add('desktop', breakpoints.desktopMq)
  bm.add('desktopNav', breakpoints.desktopNavMq)
  bm.add('tablet', breakpoints.tabletMq)
  bm.add('tabletNav', breakpoints.tabletNavMq)
  bm.add('mobile', breakpoints.mobileMq)
  bm.add('stickyNav', breakpoints.stickyNavMq)
  bm.add('actionList', breakpoints.actionListMq)
})

serviceLocator.register('breakpointManager', bm)

components.forEach((component) => component(serviceLocator, window))
