import React from 'react'

const Tick = () => (
  <svg viewBox="0 0 15.5 17.48">
    <path
      d="M5.46 17.48L0 12.32l1.37-1.46 3.67 3.47L13.79 0l1.71 1.04L5.46 17.48z"
      data-name="Layer 2"
    />
  </svg>
)

export default Tick
