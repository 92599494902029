import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Button from '../../button/component/Button'
const baseClass = 'summary'

const Summary = ({
  modifiers,
  imgUrl,
  mobileImgUrl,
  imageWidth,
  imageHeight,
  indicator,
  tag,
  title,
  text,
  href,
  ctaText,
  ctaLink,
  meta,
  comments,
  isExternal,
  callToActionText,
  additionalClassName,
  displayDate,
  displayStats,
  apps,
  pts
}) => {
  const classList = modifiers
    ? modifiers.map((modifierClass) => `${baseClass}--${modifierClass}`)
    : []
  const coverStyles = {
    backgroundImage: `url(${imgUrl})`,
  }
  const Element = href ? 'a' : 'div'

  return (
    <Element
      href={href}
      target={isExternal && '_blank'}
      rel={isExternal && 'noopener'}
      className={classNames(baseClass, [ ...classList ], additionalClassName)}
      {...(modifiers.includes('cover') && { style: coverStyles })}
    >
      {!modifiers.includes('cover') && (
        <img
          src={imgUrl}
          srcSet={mobileImgUrl && `${mobileImgUrl} 280w, ${imgUrl} 241w`}
          sizes={
            mobileImgUrl &&
            '(max-width: 549px) calc(100vw - 2rem), (max-width: 959px) calc(50vw - 7.33333rem), (max-width: 1365px) calc(25vw - 100px), 241px'
          }
          alt=""
          loading="lazy"
          width={imageWidth}
          height={imageHeight}
        />
      )}
      <div className={classNames("summary__inner", modifiers.includes('split') ? 'summary__inner__news' : modifiers.includes('player') ? 'summary__tag__player' : '' )}>
        <div className={classNames("summary__wrapper", modifiers.includes('position') && 'summary__wrapper_position')}>
          {tag && <div className="summary__tag">{tag}</div>}
          <h3 className="h2 summary__title">{title}</h3>
          {text && <p className="summary__copy">{text}</p>}
          {meta && <div className="summary__meta">{meta}</div>}
          {displayDate && <time className="summary__date">{displayDate}</time>}
          {ctaText && ctaLink && (
            <Button
              ctaText={ctaText}
              ctaLink={ctaLink}
              modifiers={[ 'primary' ]}
            />
          )}
        </div>
        {displayStats && (
          <div className="summary__stats">
            <div className="summary__stat summary__stat--apps">{apps} Apps</div>
            <div className="summary__stat summary__stat--pts">{pts} Pts</div>
          </div>
        )}
      </div>
    </Element>
  )
}

Summary.defaultProps = {
  modifiers: [],
  apps: 0,
  pts: 0,
}

Summary.propTypes = {
  callToActionText: PropTypes.string,
  tag: PropTypes.string,
  imgUrl: PropTypes.string,
  mobileImgUrl: PropTypes.string,
  imageWidth: PropTypes.number,
  imageHeight: PropTypes.number,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  href: PropTypes.string,
  modifiers: PropTypes.array,
  indicator: PropTypes.string,
  meta: PropTypes.string,
  ctaText: PropTypes.string,
  ctaLink: PropTypes.string,
  isExternal: PropTypes.bool,
  comments: PropTypes.string,
  additionalClassName: PropTypes.string,
  displayDate: PropTypes.string,
  displayStats: PropTypes.bool,
  apps: PropTypes.number,
  pts: PropTypes.number
}

export default Summary
