import React from 'react'
import ReactDOM from 'react-dom'
import PersonStats from '../../../site/layout/component/person-stats/component/PersonStats'

const debug = require('debug')('pop-promotion')

export default (serviceLocator) => {
  debug('init')

  const PersonStatsWrappers = Array.from(document.querySelectorAll('.js-person-stats'))

  if (PersonStatsWrappers.length < 1) return
  PersonStatsWrappers.forEach((wrapper) => {
    const props = JSON.parse(wrapper.getAttribute('data-props'))
    ReactDOM.render(<PersonStats {...props} />, wrapper)
  })
}
