export default () => {
  const showResultsButton = document.querySelector('.js-show-results')
  if (!showResultsButton) return false
  const resultsWrapper = document.querySelector('.js-results')
  const buttonHeight = showResultsButton.clientHeight
  const navOffset = 52

  showResultsButton.addEventListener('click', () => {
    const buttonY = showResultsButton.getBoundingClientRect().top
    const existingScrollY = document.body.scrollTop
    resultsWrapper.classList.add('is-showing-all')
    const scrollTo = resultsWrapper
      .querySelector('div:last-child > .match-row')
      .getBoundingClientRect().top
    document.body.scrollTop =
      scrollTo - buttonY - buttonHeight - navOffset + existingScrollY

    showResultsButton.remove()
  })
}
