import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import TextControl from '../text/Text'
import Icon from '../../icon/component/Icon'
import Svgs from '../../svgs/component/Svgs'

const CustomSearchControl = (props) => {
  const { error, modifiers } = props
  const customSearchClassNames = classNames(
    'custom-search',
    modifiers &&
      modifiers.map((modifierClass) => `custom-search--${modifierClass}`),
    { 'custom-search--error': error }
  )

  return (
    <span className={customSearchClassNames}>
      <button type="submit" className="text-button custom-search__button">
        <Icon type="calendar" width={11} height={11}>
          <Svgs.Search />
        </Icon>
      </button>
      <TextControl {...props} />
    </span>
  )
}

CustomSearchControl.propTypes = {
  error: PropTypes.string,
  modifiers: PropTypes.arrayOf(PropTypes.string),
}

export default CustomSearchControl
