import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const Panel = ({ heading, children, narrow }) => {
  const widthClassNames = { 'content-center content-center--narrow': narrow }

  return (
    <div className={classNames('panel', widthClassNames)}>
      {heading && (
        <div className="panel__heading">
          <h2 className="panel__title">{heading}</h2>
        </div>
      )}
      <div className="panel__body">{children}</div>
    </div>
  )
}

Panel.propTypes = {
  heading: PropTypes.string,
  children: PropTypes.node.isRequired,
  narrow: PropTypes.bool,
}

export default Panel
