import React from 'react'
import ReactDOM from 'react-dom'
import Enquiry from '../../../site/layout/component/enquiry/component/Enquiry'
import createFormSubmissionContainer from '../../lib/form-submission'
import required from '@clocklimited/validity-required'
import isEmail from '@clocklimited/validity-email'
import schemata from 'schemata'
import createDebug from 'debug'
import phoneNumber from 'validity-telephone-number'
const validateIfSet = require('@clocklimited/validity-validate-if-set')

const debug = createDebug('preference-centre')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    const appNode = document.getElementById('enquiry')
    if (!appNode) {
      return debug('No enquiry node found. Skipping.')
    }
    const validateEventFields = appNode.dataset.validateEventFields
    const schema = schemata({
      name: 'Base Enquiry',
      properties: {
        name: { type: String, validators: [ required ] },
        email: { type: String, validators: [ required, isEmail ] },
        ...(validateEventFields === 'true'
          ? {
              eventType: { type: String, validators: [ required ] },
              numOfGuests: { type: Number, validators: [ required ] },
              eventDate: { type: Date, validators: [ required ] },
            }
          : {}),
        telephone: { type: String, validators: [ validateIfSet(phoneNumber) ] },
        dob: { type: Date },
        enquiry: { type: String },
        referrer: { type: String },
        toEmailAddress: { type: String },
        captcha: {
          type: String,
          validators: [ required.setFailureMessage('Captcha must be checked') ],
        },
        checksum: { type: String },
        redirectUrl: { type: String },
      },
    })
    const infoHtml = appNode.dataset.html
    const header = appNode.dataset.header
    const toEmailAddress = appNode.dataset.email
    const instanceName = appNode.dataset.instanceName
    const displayMap = appNode.dataset.displayMap
    const mapToShow = appNode.dataset.mapToShow
    const inverse = appNode.dataset.inverse
    const displayReferrer = appNode.dataset.displayReferrer
    const displayNewsletter = appNode.dataset.displayNewsletter
    const redirectUrl = appNode.dataset.redirectUrl

    const Component = (props) => (
      <Enquiry
        displayReferrer={displayReferrer === 'true'}
        displayNewsletter={displayNewsletter === 'true'}
        displayMap={displayMap === 'true'}
        mapToShow={mapToShow}
        instanceName={instanceName}
        inverse={inverse === 'true'}
        heading={header}
        toEmailAddress={toEmailAddress}
        redirectUrl={redirectUrl}
        {...props}
      >
        <div dangerouslySetInnerHTML={{ __html: infoHtml }} />
      </Enquiry>
    )

    const FormSubmissionContainer = createFormSubmissionContainer({
      Component: Component,
      schema: schema,
      apiUrl: '/enquiry',
      errorMessage:
        'Please check your details and try again. If the problem persists, please get in touch via our <a href="/club/contact">contact page</a>.',
      successMessage: 'Success',
      data: {
        redirectUrl: redirectUrl,
      },
      onSuccess: () => {
        if (redirectUrl) {
          window.open(redirectUrl, '_self')
        }
      },
    })

    ReactDOM.render(<FormSubmissionContainer />, appNode)
  })
}
