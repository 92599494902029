module.exports = () => [
  { text: '-- Select a Referrer --', value: '' },
  { text: 'Word of mouth', value: 'Word of mouth' },
  {
    text: 'Social media (Facebook, Twitter, LinkedIn)',
    value: 'Social Media (Facebook, Twitter, LinkedIn)',
  },
  { text: 'Email marketing', value: 'Email marketing' },
  { text: 'Returning customer', value: 'Returning customer' },
  { text: 'Recommendation', value: 'Recommendation' },
  { text: 'Flyer/Poster', value: 'Flyer/Poster' },
  { text: 'Website – Please state the website below', value: 'Website' },
  {
    text: 'Magazine/newspaper article – Please state the article below',
    value: 'Magazine/newspaper article',
  },
  { text: 'Northampton Saints Fan', value: 'Northampton Saints Fan' },
  { text: 'Networking event', value: 'Networking event' },
  {
    text: 'Member of staff – Please name the staff member below',
    value: 'Member of staff',
  },
  { text: 'Outdoor banner', value: 'Outdoor banner' },
  { text: 'Other – Please state referral method below', value: 'Other' },
]
