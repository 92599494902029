import AngloWelsh from './svg/AngloWelsh'
import ArrowDown from './svg/ArrowDown'
import ArrowLeft from './svg/ArrowLeft'
import ArrowRight from './svg/ArrowRight'
import ArrowUp from './svg/ArrowUp'
import AutoRenew from './svg/AutoRenew'
import Aviva from './svg/Aviva'
import Ball from './svg/Ball'
import BallWhite from './svg/BallWhite'
import TNTSport from './svg/TNTSport'
import BtSport1 from './svg/BtSport1'
import BtSport2 from './svg/BtSport2'
import BtSportExtra1 from './svg/BtSportExtra1'
import BtSportExtra2 from './svg/BtSportExtra2'
import BtSportExtra3 from './svg/BtSportExtra3'
import BtSportExtra4 from './svg/BtSportExtra4'
import BtSportExtra5 from './svg/BtSportExtra5'
import Cinch from './svg/Cinch'
import CinchDark from './svg/CinchDark'
import Close from './svg/Close'
import CloseAlt from './svg/CloseAlt'
import Comment from './svg/Comment'
import Conversion from './svg/Conversion'
import ChampionsCup from './svg/ChampionsCup'
import Channel5 from './svg/Channel5'
import DropGoal from './svg/DropGoal'
import External from './svg/External'
import FabShirt from './svg/FabShirt'
import FabMemberships from './svg/FabMemberships'
import FabSignUp from './svg/FabSignUp'
import FabTickets from './svg/FabTickets'
import FabRugbyBall from "./svg/FabRugbyBall"
import FabWin from './svg/FabWin'
import Facebook from './svg/Facebook'
import Falling from './svg/Falling'
import Filters from './svg/Filters'
import Hamburger from './svg/Hamburger'
import Hammer from './svg/Hammer'
import Instagram from './svg/Instagram'
import Itv from './svg/Itv.js'
import LinkedIn from './svg/LinkedIn'
import Marker from './svg/Marker'
import Minus from './svg/Minus'
import Penalty from './svg/Penalty'
import Plus from './svg/Plus'
import PlusAlt from './svg/PlusAlt'
import Phone from './svg/Phone'
import PrtvLive from './svg/PrtvLive.js'
import Quote from './svg/Quote'
import RedCard from './svg/RedCard'
import Rising from './svg/Rising'
import Search from './svg/Search'
import SentOff from './svg/SentOff'
import SinBin from './svg/SinBin'
import Shop from './svg/Shop'
import SkySports from './svg/SkySports'
import PremierSports from './svg/PremierSports.js'
import StubHub from './svg/StubHub'
import Substitution from './svg/Substitution'
import Tick from './svg/Tick'
import Ticket from './svg/Ticket'
import TikTok from './svg/TikTok'
import TriangleRight from './svg/TriangleRight'
import Twitter from './svg/Twitter'
import Whistle from './svg/Whistle'
import YellowCard from './svg/YellowCard'
import Youtube from './svg/Youtube'


const svgs = {
  AngloWelsh,
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  AutoRenew,
  Aviva,
  Ball,
  BallWhite,
  TNTSport,
  BtSport1,
  BtSport2,
  BtSportExtra1,
  BtSportExtra2,
  BtSportExtra3,
  BtSportExtra4,
  BtSportExtra5,
  ChampionsCup,
  Channel5,
  Cinch,
  CinchDark,
  Close,
  CloseAlt,
  Comment,
  Conversion,
  DropGoal,
  External,
  FabShirt,
  FabMemberships,
  FabSignUp,
  FabTickets,
  FabRugbyBall,
  FabWin,
  Facebook,
  Falling,
  Filters,
  Hamburger,
  Hammer,
  Instagram,
  Itv,
  LinkedIn,
  Marker,
  Minus,
  Penalty,
  Phone,
  Plus,
  PlusAlt,
  PrtvLive,
  Quote,
  RedCard,
  Rising,
  Search,
  SentOff,
  SinBin,
  Shop,
  SkySports,
  StubHub,
  Substitution,
  Tick,
  Ticket,
  TikTok,
  TriangleRight,
  Twitter,
  Whistle,
  YellowCard,
  Youtube,
  PremierSports,

}

export default svgs
