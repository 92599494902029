import React from 'react'

const PhoneSvg = () => (
  <svg width="14" height="18">
    <g fill="none" fillRule="evenodd">
      <path
        d="M11.033 7.322a11.993 11.993 0 0 1-4.231 6.044l-2.018-1.413a.787.787 0 0 0-.828-.047 9.03 9.03 0 0 1-2.705.936.794.794 0 0 0-.642.917l.48 2.72a.794.794 0 0 0 .916.643C9.326 15.83 14.213 8.852 12.922 1.53a.794.794 0 0 0-.917-.642l-2.729.481a.794.794 0 0 0-.642.917c.172.975.181 1.938.047 2.862a.794.794 0 0 0 .335.76l2.017 1.413z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default PhoneSvg
