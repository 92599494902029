import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../../primitive/icon/component/Icon'
import Svgs from '../../primitive/svgs/component/Svgs'

const Share = (props) => {
  const { heading, shareUrls } = props
  return (
    <div className="share-container">
      <div className="share-wrapper content-gutter">
        <div className="share">
          <h3 className="share__heading">{heading}</h3>
          <ul className="list--unstyled share__icons">
            <li>
              <a target="_blank" href={shareUrls.facebook} rel="noopener">
                <Icon a11yText="Share on Facebook" width={25} height={25}>
                  <Svgs.Facebook />
                </Icon>
              </a>
            </li>
            <li>
              <a target="_blank" href={shareUrls.twitter} rel="noopener">
                <Icon a11yText="Share on Twitter" width={25} height={25}>
                  <Svgs.Twitter />
                </Icon>
              </a>
            </li>
            <li>
              <a target="_blank" href={shareUrls.linkedIn} rel="noopener">
                <Icon a11yText="Share on LinkedIn" width={25} height={25}>
                  <Svgs.LinkedIn />
                </Icon>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

Share.propTypes = {
  heading: PropTypes.string.isRequired,
  shareUrls: PropTypes.object.isRequired,
}

export default Share
