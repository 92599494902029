import React from 'react'

const Channel5Svg = () => (
  <svg version="1" height="30.667" viewBox="0 0 709 1028">
    <path d="M129.5 5.1c-4.5 1.3-10.7 7-13.7 12.5-1.4 2.7-22.7 65.9-47.3 140.4-24.7 74.5-45 136.1-45.3 136.7-.3 1 21.2 1.3 105.6 1.3h106.1l48-144.9C309.4 71.4 331 5.7 331 5.1c0-1.4-196.9-1.4-201.5 0zm234 .1c-2.4 6.5-63.5 191.9-63.5 192.7 0 .8 44 1.1 161.3.9 161.1-.3 161.2-.3 165.7-2.4 5.8-2.6 10.8-7.6 13.4-13.4 2.1-4.4 2.1-5.5 2.1-82 0-76.7 0-77.5-2.1-82-2.7-5.8-6.7-9.7-12.9-12.6l-5-2.4H493.2c-102.5 0-129.4.3-129.7 1.2zM23 326.4c0 .3 49.5 101.8 79.6 163.1 7.9 16 18.2 25.5 31.8 29.1 4.2 1.1 24.8 1.4 113.1 1.4 59.4 0 115.6.5 124.9 1 70.8 4.1 131.9 28.9 182.7 74.3 50 44.6 86.7 108.1 100.3 173.5 4.3 21.1 5.1 28.8 5.1 52.5 0 12.5.1 22.7.2 22.7.9 0 15-30 19.6-41.5 16.1-40.8 23.4-77.7 24.4-122.7.8-35.9-1.4-57-9.3-88.7-14.9-60.1-46.7-115.4-92.8-161.7-60-60-134.7-95.3-217.1-102.3-11.5-1-362.5-1.6-362.5-.7z" />
    <path d="M423.4 561.2c35.9 24.5 56.1 52.7 63.3 88.5 2.4 12 2.3 37.4-.1 49.8-11.4 57.8-52.9 97.9-111.8 108l-3.8.6v216.8l4.8-.5c40.8-3.9 64.2-8.3 91.7-17.1 78.3-25.1 132-76.3 152-144.9 4.9-17 6.7-29.5 7.2-51.1 1.1-40-6.2-71.6-24.8-108.5-35.6-70.3-104.2-125-178.4-142.4-1.9-.4-1.9-.4-.1.8zM20.7 649.5C14 651.8 8.9 657.2 5.9 665c-2 5.2-2.1 6.7-1.6 25.5 1.3 46.9 12.6 91.7 34.6 137 19 39.1 37.3 64.8 68 95.6 16.7 16.6 27.9 26.2 43.6 37.5 46 33 105.4 55.4 164 61.9 7.7.8 15.9 1.7 18.3 2l4.2.5V808l-2.7-.6c-38.9-8.6-69.2-26.8-89.4-53.7-15.5-20.7-23.3-42.2-26.3-72.9-1.2-12.6-1.8-15.4-4.2-19.9-1.6-2.8-4.2-6.2-5.9-7.5-7.4-5.6-3.1-5.4-97.2-5.4-70.9.1-87.5.3-90.6 1.5z" />
  </svg>
)

export default Channel5Svg
