import delegate from 'delegate'
const debug = require('debug')('unique-switcher')

export default (sl, window) => {
  debug('Init')

  delegate(window.document.body, '.js-unique-switch', 'click', (e) => {
    // The DOM element that was clicked
    let unique = e.target

    // Check if the element that was clicked was the intended target
    if (!unique.classList.contains('js-unique-switch')) {
      // If it was a child of the trigger, climb up the DOM until we find the parent
      // This is the element we need
      unique = unique.closest('.js-unique-switch')
    }

    // Get the data atrribute unique, this is used to determine the content panel element.
    const context = unique.getAttribute('data-unique')

    // Build a selector based of the context. If its not setup, assume that the trigger's parent contains the content elemnt
    const selector = context
      ? `.js-unique-content[data-unique=${context}]`
      : '.js-unique-content'

    // Get the content element with the defined selectored above `selector`
    const content = unique.parentNode.querySelector(selector)

    // Get the attribute data-collapse if this is set (The value doesn't matter as long as it = true)
    // This flag is used to determine whether we should collapse content elements if their trigger is clicked again
    const shouldCollapse = content.getAttribute('data-collapse')

    // A flag to check if the unique item that was clicked was already active
    const uniqueWasAlreadyActive = unique.classList.contains('is-active')

    // Remove is-active and is-hidden classes from all targeted elements
    // Including the one that was just clicked
    unique.parentNode
      .querySelectorAll('.js-unique-switch')
      .forEach((el) => el.classList.remove('is-active'))
    unique.parentNode
      .querySelectorAll('.js-unique-content')
      .forEach((el) => el.classList.add('is-hidden'))

    // If the item clicked wasn't already active or if the `shouldCollapse` flag is false,
    // add the is-active class to the switch element and
    // remove is-hidden class from the content element
    if (!shouldCollapse || !uniqueWasAlreadyActive) {
      unique.classList.add('is-active')
      content.classList.remove('is-hidden')
    }
  })
}
