import React from 'react'

const WhistleSvg = () => (
  <svg data-name="Layer 1" width="18" height="10" viewBox="0 0 18 10">
    <path
      vectorEffect="non-scaling-stroke"
      d="M7 1.5A3.54 3.54 0 0 0 3.5 5 3.54 3.54 0 0 0 7 8.5 3.54 3.54 0 0 0 10.5 5 3.54 3.54 0 0 0 7 1.5zm0 6.4a2.9 2.9 0 0 1 0-5.8A3 3 0 0 1 10 5a3 3 0 0 1-3 2.9z"
    />
    <path
      vectorEffect="non-scaling-stroke"
      d="M17.7 0H6.9a5 5 0 0 0-4.8 4.4 1.14 1.14 0 0 0-.8-.3A1.24 1.24 0 0 0 0 5.3a1.32 1.32 0 0 0 1.3 1.3 1.27 1.27 0 0 0 .9-.4A4.93 4.93 0 0 0 7 10a5.08 5.08 0 0 0 5-4.6v-.1c.6-2.7 5.7-3.3 5.8-3.3a.22.22 0 0 0 .2-.2V.3a.27.27 0 0 0-.3-.3zm-5.6.6h.4v1h-.4v-1zM1.3 6a.77.77 0 0 1-.8-.7.68.68 0 0 1 .7-.7.71.71 0 0 1 .8.7.68.68 0 0 1-.7.7zM7 9.5a4.5 4.5 0 0 1-.1-9h.4a4.51 4.51 0 0 1-.3 9zm10.5-8c-.9.1-4.2.7-5.5 2.6A5 5 0 0 0 9.3.5h2.2v1.2a.27.27 0 0 0 .3.3h1a.27.27 0 0 0 .3-.3V.6h4.5v.9h-.1z"
    />
  </svg>
)

export default WhistleSvg
