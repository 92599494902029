import React from 'react'
import ReactDOM from 'react-dom'
import PopPromotion from '../../../site/layout/component/pop-promotion/component/PopPromotion'
import createFormSubmissionContainer from '../../lib/form-submission'

const debug = require('debug')('pop-promotion')
const schemata = require('schemata')
const required = require('@clocklimited/validity-required')
const schema = schemata({
  name: 'Pop Promotion',
  properties: {
    fullName: {
      type: String,
      validators: [ required ],
    },
    email: {
      type: String,
      validators: [ required ],
    },
    emailSignUpOptions: {
      type: String,
    },
    addressBookId: {
      type: String,
      defaultValue: '15543166',
      validators: [ required ],
    },
  },
})

export default (serviceLocator) => {
  debug('init')

  document.addEventListener('DOMContentLoaded', () => {
    const PopPromotionWrappers = Array.from(
      document.querySelectorAll('.js-pop-promotion-wrapper')
    )

    if (PopPromotionWrappers.length < 1) return
    PopPromotionWrappers.forEach((wrapper) => {
      const props = JSON.parse(wrapper.getAttribute('data-props'))
      const FormSubmissionContainer = createFormSubmissionContainer({
        Component: PopPromotion,
        props: props,
        schema: schema,
        apiUrl: '/api/email-signup',
        errorMessage:
          'An error has occurred. Please try again. If the problem persists, please get in touch via our <a href="/club/contact">contact page</a>.',
        successMessage: 'Thank you for signing up.',
      })
      ReactDOM.render(<FormSubmissionContainer {...props} />, wrapper)
    })
  })
}
