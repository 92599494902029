import qs from 'qs'

export default (property, value) => {
  const url = getCurrentUrl()
  if (value === 'all') {
    delete url.query[property]
  } else {
    url.query[property] = value
  }
  return url
}

const getCurrentUrl = () => {
  const currentUrl = {}
  const search = window.location.search
  currentUrl.path = window.location.pathname.split('/')
  currentUrl.query = qs.parse(search.substring(1))

  return currentUrl
}
