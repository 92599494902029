import noUiSlider from 'nouislider'
import debug from 'debug'
const log = debug('nouislider:log')

export default () => {
  log('init')

  const rangeElement = document.querySelector('.js-range')
  if (!rangeElement) return false

  noUiSlider.create(rangeElement, {
    start: [ 30, 70 ],
    connect: true,
    range: {
      min: 10,
      max: 100,
    },
    tooltips: true,
    step: 5,
    format: {
      to: function (value) {
        return '£' + Math.round(value)
      },
      from: function (value) {
        return Math.round(value)
      },
    },
  })

  rangeElement.noUiSlider.on('end', () => {
    let vals = rangeElement.noUiSlider.get()
    vals = vals.map((val) => Number(val.substring(1)))
    rangeElement.dataset.values = vals
  })
}
