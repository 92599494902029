import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Field from '../../primitive/field/component/Field'

class NotifyMe extends React.Component {
  constructor(props) {
    super(props)
    this.setValues = this.props.setValues
  }

  componentDidMount() {
    const defaults = {}
    this.setValues(defaults)
  }

  render() {
    const {
      displayAlone,
      successMessage,
      errorMessage,
      error = {},
      onChange,
      onSubmit = [],
    } = this.props

    return (
      <div className={classNames('notify-me', displayAlone && 'displayAlone')}>
        <div className="content-center content-gutter">
          <div className="notify-me__inner">
            <form className="form" method="post" onSubmit={onSubmit}>
              {errorMessage && (
                <div className="notification notification--error">
                  <p dangerouslySetInnerHTML={{ __html: errorMessage }} />
                </div>
              )}
              <div className="grid grid--valign-middle">
                <div className="grid__item tablet-one-half desktop-one-third">
                  <p className="notify-me__field-title">Your name</p>
                  <Field
                    name="fullName"
                    type="text"
                    showRequired={false}
                    onChange={onChange}
                    placeholder="Name"
                    error={error.fullName}
                    id="Name--NotifyMe"
                  />
                </div>
                <div className="grid__item tablet-one-half desktop-one-third">
                  <p className="notify-me__field-title">Email</p>
                  <Field
                    name="email"
                    type="email"
                    showRequired={false}
                    onChange={onChange}
                    placeholder="Email"
                    error={error.email}
                    id="Email--NotifyMe"
                  />
                </div>
                <div className="grid__item desktop-one-third">
                  <div className="grid grid--gutterless grid--valign-middle">
                    <div className="grid__item desktop-two-thirds">
                      <Field
                        name="signUp"
                        type="checkbox"
                        onChange={onChange}
                        options={[
                          {
                            text:
                              'Tick here to receive updates from Northampton Saints',
                            value: 'yes',
                          },
                        ]}
                        className="preference-centre__checkbox notify-me-checkbox"
                        error={error.signUp}
                      />
                    </div>
                    <div className="grid__item desktop-one-third">
                      <button className="button button--primary button--block">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {successMessage && Object.keys(error).length === 0 && (
                <p className="notify-me__message">{successMessage}</p>
              )}
            </form>
          </div>
        </div>
      </div>
    )
  }
}

NotifyMe.propTypes = {
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  error: PropTypes.object,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  setValues: PropTypes.func,
  displayAlone: PropTypes.bool,
}

export default NotifyMe
