import React from 'react'
import ReactDOM from 'react-dom'
import debug from 'debug'
import CommentaryContainer from '../../../../common/matchday-live/CommentaryContainer'

const log = debug('matchday-live:commentary:log')

export default (appNodes, primus, commentary) => {
  log('init')

  appNodes.forEach((appNode) => {
    ReactDOM.render(
      <CommentaryContainer primus={primus} commentary={commentary} />,
      appNode
    )
  })
}
