import Swiper from 'swiper/dist/js/swiper.js'
const debug = require('debug')('carousel')

const init = () => {
  debug('Init')
  document.addEventListener('DOMContentLoaded', initSwipers)
  document.addEventListener('deviceready', initSwipers)
}

const initSwipers = (serviceLocator) => {
  const carouselContainers = [
    ...document.querySelectorAll('.js-video-playlist-wrapper'),
  ]
  if (carouselContainers.length < 1) return false

  carouselContainers.forEach((carousel) => {
    const prev = carousel.querySelector('.js-video-playlist-prev')
    const next = carousel.querySelector('.js-video-playlist-next')
    const carouselElements = carousel.querySelector(
      '.js-video-playlist:not(.is-initialised)'
    )

    const swiperInstance = new Swiper(carouselElements, {
      speed: 200,
      spaceBetween: 20,
      slidesPerView: 4,
      slidesPerGroup: 4,
      nextButton: '.js-video-playlist-next',
      prevButton: '.js-video-playlist-prev',
      breakpoints: {
        799: {
          slidesPerView: 3,
          slidesPerGroup: 3,
        },
      },
    })

    prev.addEventListener('click', () => swiperInstance.slidePrev())
    next.addEventListener('click', () => swiperInstance.slideNext())
    carousel.classList.add('is-initialised')
  })
}

export { init, initSwipers }
