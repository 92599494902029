const schemata = require('schemata')
const required = require('@clocklimited/validity-required')
const isUrl = require('@clocklimited/validity-url')
const isNumber = require('validity-number')
const validateIfSet = require('@clocklimited/validity-validate-if-set')
const validateIfPropertyEquals = require('@clocklimited/validity-validate-if-property-equals')
const dateBeforeEndDate = require('validity-date-before-property')(
  'endDate',
  'End Date'
)
const threeDays = 1000 * 60 * 60 * 24 * 3

module.exports = (serviceLocator) =>
  schemata({
    name: 'Rugby Camp',
    properties: {
      _id: { type: String },
      location: { type: String, validators: [ required ], tag: [ 'public' ] },
      ageRange: { type: String, validators: [ required ], tag: [ 'public' ] },
      startDate: {
        type: Date,
        validators: [
          validateIfPropertyEquals('type', 'camp', required),
          dateBeforeEndDate,
        ],
        tag: [ 'public' ],
      },
      endDate: {
        type: Date,
        validators: [ validateIfPropertyEquals('type', 'camp', required) ],
        tag: [ 'public' ],
      },
      addressLine1: { type: String, validators: [ required ], tag: [ 'public' ] },
      addressLine2: { type: String, tag: [ 'public' ] },
      addressLine3: { type: String, tag: [ 'public' ] },
      town: { type: String, validators: [ required ], tag: [ 'public' ] },
      county: { type: String, validators: [ required ], tag: [ 'public' ] },
      postcode: { type: String, validators: [ required ], tag: [ 'public' ] },
      days: {
        type: String,
        validators: [ validateIfPropertyEquals('type', 'camp', required) ],
        tag: [ 'public' ],
      },
      cost: {
        type: String,
        validators: [ validateIfPropertyEquals('type', 'camp', required) ],
        tag: [ 'public' ],
      },
      latitude: {
        type: Number,
        validators: [ validateIfSet(isNumber) ],
        tag: [ 'public' ],
      },
      longitude: {
        type: Number,
        validators: [ validateIfSet(isNumber) ],
        tag: [ 'public' ],
      },
      ctaLabel: {
        type: String,
        tag: [ 'public' ],
      },
      bookingLink: {
        type: String,
        validators: [ validateIfSet(isUrl) ],
        tag: [ 'public' ],
      },
      createdDate: {
        type: Date,
        defaultValue: () => new Date(),
        tag: [ 'public' ],
      },
      loc: {
        type: schemata({
          name: 'loc',
          properties: {
            type: {
              type: String,
              validators: [ required ],
              defaultValue: 'Point',
            },
            coordinates: { type: Array, validators: [ required ] },
          },
        }),
      },
      description: { type: String, tag: [ 'public' ] },
      campType: { type: String, tag: [ 'public' ] },
      type: { type: String, validators: [ required ] },
    },
  })
