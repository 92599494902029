if (typeof SITE_URL === 'undefined') {
  const staticMap = require('../static-file-map.json')
  const versionator = require('versionator')
  const mappedVersion = versionator.createMapped(staticMap)
  module.exports = mappedVersion.versionPath
} else {
  // This is here for the mobile app
  module.exports = (url) => {
    return SITE_URL + url
  }
}
