import React from 'react'

import { Tweet } from 'react-twitter-widgets'

const onLoad = () => {
  document.querySelectorAll('iframe.twitter-tweet').forEach((iframe) => {
    iframe.contentDocument.querySelectorAll('a').forEach((a) => {
      a.addEventListener(
        'click',
        (e) => {
          e.preventDefault()
          window.open(a.href, '_system')
        },
        true
      )
    })
  })
}

const createVideoIframe = (srcPrefix, videoId, query) => (
  <div className="media-iframe-parent">
    <div className="media-iframe-ratio">
      <iframe
        className="media-iframe-child"
        src={srcPrefix + videoId + (query || '')}
        frameBorder="0"
        allowFullScreen
      />
    </div>
  </div>
)

export const embedVideo = (provider, videoId, showRelatedVideos) => {
  switch (provider) {
    case 'youtube':
      const rel = showRelatedVideos ? 1 : 0
      return createVideoIframe(
        'https://www.youtube.com/embed/',
        videoId,
        `?showinfo=0&playsinline=1&rel=${rel}`
      )
    case 'vimeo':
      return createVideoIframe('https://player.vimeo.com/video/', videoId)
    default:
      return ''
  }
}

const socialComponents = {
  tweet: (socialId) => <Tweet tweetId={socialId} onLoad={onLoad} />,
  youtube: (socialId) => embedVideo('youtube', socialId, 0),
  vimeo: (socialId) => embedVideo('vimeo', socialId, 0),
}

export default ({ type, socialId }) => {
  const socialComponent = socialComponents[type]
  return socialComponent ? socialComponent(socialId) : null
}
