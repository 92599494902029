import React from 'react'

const DropGoalSvg = () => (
  <svg data-name="Layer 1" width="24" height="19" viewBox="0 0 22 19">
    <path
      d="M.2 2.43a9.06 9.06 0 0 0 1 6.27 9.06 9.06 0 0 0 5.06 3.87 3.18 3.18 0 0 0 2.46-.21c1-.59 1.51-1.91 1.51-3.74A9.9 9.9 0 0 0 9.1 4.03 8.51 8.51 0 0 0 4.15.16a3.34 3.34 0 0 0-2.58.2A3.25 3.25 0 0 0 .2 2.43zm3.58 3a13.11 13.11 0 0 1-1.66-3.48A1.24 1.24 0 0 1 2.25.73a2.87 2.87 0 0 1 1.48.05 6.87 6.87 0 0 1 2.38 1.21 7.31 7.31 0 0 1 1.93 2.18 7.55 7.55 0 0 1 .78 4c0 1-.36 1.67-.61 1.68a2.21 2.21 0 0 1-.91-.48 24.91 24.91 0 0 1-3.52-3.93zm-2.74-3a3.89 3.89 0 0 1 .12-.4h.05a23.84 23.84 0 0 0 2.31 4.9 34.88 34.88 0 0 0 2.42 3.46c.19.22.2.5-.12.49a4 4 0 0 1-1.2-.37h-.1a8.06 8.06 0 0 1-2.63-2.59 8 8 0 0 1-.85-5.52z"
      fill="#ffffff"
    />
    <path
      fill="#ffffff"
      d="M7.18 11.02l.69.72-.13.11-.56-.83zm1.96 2.25c.4.53.76 1.08 1.15 1.63l-.53.31c-.32-.56-.66-1.12-1-1.7zm2.12 3.4l.19.48.1.24c0 .08 0 0-.05-.08a.27.27 0 0 0-.21-.08.28.28 0 0 0-.22.16v-.24l.1-.48.86.19-.12.49-.06.24a.62.62 0 0 1-.54.48.57.57 0 0 1-.43-.16.65.65 0 0 1-.16-.29l-.07-.22-.14-.45zm.35-1.93l.46-1.93.96.26-.49 1.89-.93-.22zm1.02-3.84l.66-1.89.9.34-.62 1.85-.94-.3zm1.47-3.72a17.32 17.32 0 0 1 1-1.76l.67.38q-.44.86-.82 1.75zm2.1-3.42a11.44 11.44 0 0 1 1.36-1.48l.33.26a17 17 0 0 0-1.17 1.54zm2.96-2.68a8.12 8.12 0 0 1 .9-.46 9.78 9.78 0 0 0-.8.6z"
    />
  </svg>
)

export default DropGoalSvg
