import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
const baseClass = 'figure'

const Figure = ({
  imgUrl,
  imageWidth,
  imageHeight,
  alt,
  caption,
  credits,
  modifiers,
  additionalClassName,
  loading,
}) => {
  const modifierClassNames = modifiers
    ? modifiers.map((modifierClass) => `${baseClass}--${modifierClass}`)
    : null

  return (
    <figure
      className={classNames(baseClass, modifierClassNames, additionalClassName)}
    >
      <div className="figure__image">
        <img
          src={imgUrl}
          alt={alt || ''}
          loading={loading}
          width={imageWidth}
          height={imageHeight}
        />
        {caption && (
          <figcaption className="figure__caption">{caption}</figcaption>
        )}
      </div>
      {credits && (
        <figcaption className="figure__credits">{credits}</figcaption>
      )}
    </figure>
  )
}

Figure.propTypes = {
  imgUrl: PropTypes.string.isRequired,
  imageWidth: PropTypes.number,
  imageHeight: PropTypes.number,
  alt: PropTypes.string,
  additionalClassName: PropTypes.string,
  caption: PropTypes.string,
  credits: PropTypes.string,
  modifiers: PropTypes.array,
  loading: PropTypes.oneOf([ 'auto', 'eager', 'lazy' ]),
}

export default Figure
