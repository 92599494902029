import React from 'react'
import ReactDOM from 'react-dom'
import PreferenceCentre from '../../../site/layout/component/preference-centre/component/PreferenceCentre'
import createFormSubmissionContainer from '../../lib/form-submission'
const required = require('@clocklimited/validity-required')
const validateIfPropertySet = require('@clocklimited/validity-validate-if-property-set')
const debug = require('debug')('preference-centre')
const schemata = require('schemata')
const schema = schemata({
  name: 'Preference Centre',
  properties: {
    fullName: {
      type: String,
      validators: [ validateIfPropertySet('directMailOptIn', required) ],
    },
    email: { type: String, validators: [ required ] },
    telephone: {
      type: String,
      validators: [
        validateIfPropertySet('telephoneOptIn', required),
        validateIfPropertySet('textOptIn', required),
      ],
    },
    dateOfBirth: { type: String },
    address1: {
      type: String,
      validators: [ validateIfPropertySet('directMailOptIn', required) ],
    },
    addressLine2: {
      type: String,
      validators: [ validateIfPropertySet('directMailOptIn', required) ],
    },
    postcode: {
      type: String,
      validators: [ validateIfPropertySet('directMailOptIn', required) ],
    },
    maxFrequency: { type: Boolean },
    storeFrequency: { type: String },
    hospitalityFrequency: { type: String },
    eventsFrequency: { type: String },
    campsFrequency: { type: String },
    partnerFrequency: { type: String },
    lightningFrequency: { type: String }
  },
})

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    const appNode = document.getElementById('preference-centre')
    if (!appNode) {
      return debug('No pref centre node found. Skipping.')
    }
    const infoHtml = appNode.dataset.html
    const items = JSON.parse(appNode.dataset.items)

    const Component = (props) => (
      <PreferenceCentre items={items} {...props}>
        <div className="prose" dangerouslySetInnerHTML={{ __html: infoHtml }} />
      </PreferenceCentre>
    )

    const FormSubmissionContainer = createFormSubmissionContainer({
      Component: Component,
      schema: schema,
      apiUrl: '/api/email-preferences',
      errorMessage:
        'Please check your details and try again. If the problem persists, please get in touch via our <a href="/club/contact">contact page</a>.',
      successMessage: 'Thank you for updating your preferences.',
    })

    ReactDOM.render(<FormSubmissionContainer />, appNode)
  })
}
