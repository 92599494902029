import React from 'react'

const FabShirtSvg = (props) => (
  <svg viewBox="0 0 595.3 841.9">
    <circle cx="297.7" cy="420.9" r="202.7" fill="#026443" stroke="#efad2a" strokeWidth="22" strokeMiterlimit="10" />
    <path className="st1" fill="#F2BA7C" d="M244.4 376.3l-39 16.8-21.9 20.9-10.7 16.8L171 452l-.1 21.7 5.5 35.2 12.7 27 50.3 17.8 8.9 3.6 22.9-25.5 11.4-31.6-.3-33.4 7.8 10.4s.2-2.2 5.8 2.1c5.6 4.3 11.3 5.5 15 3 3.7-2.4 7.6-6.5 7.9-8 .4-1.5 17-8.6 17-8.6l7.8-9.3s-7.6-16.3-7.1-17.1c.5-.8-27.5-43.1-27.5-43.1L248.7 361l-4.3 15.3z" />
    <path className="st2" fill="none" stroke="#010202" strokeLinecap="round" strokeWidth="12" strokeMiterlimit="10" d="M232.9 383c-13.6-5.4-52 38.7-52 38.7C173.6 430 170 441 171 452l9.4 77.8 67.8 27.5 24.1-32.8c10-13.6 14.2-30.6 11.5-47.3l-1.7-10.4c-1.2-7.8.4-15.8 4.7-22.5l14.2-22.1c4.3-6.7 2.4-15.7-4.3-20.1-5.5-3.6-12.9-2.4-17 2.8l-32 43.9-22-16.3c-3.9-3.1-4.9-8.5-2.5-12.8 5.8-10.2 18.6-33.1 9.7-36.7zm104 81.9l-6.1 4c-4.3 2.9-10.1 1.7-13-2.6L303.7 445c-2.9-4.3-1.7-10.1 2.6-13l6.1-4c4.3-2.9 10.1-1.7 13 2.6l14.2 21.3c2.8 4.3 1.6 10.1-2.7 13z" />
    <path className="st2" fill="none" stroke="#010202" strokeLinecap="round" strokeWidth="12" strokeMiterlimit="10" d="M315.6 479l-6.1 4c-4.3 2.9-10.1 1.7-13-2.6l-14.2-21.3c-2.9-4.3-1.7-10.1 2.6-13l6.1-4c4.3-2.9 10.1-1.7 13 2.6l14.2 21.3c2.9 4.4 1.7 10.2-2.6 13z" />
    <path d="M408.1 444.6H239.8c-10.5 0-19.1-8.5-19.1-19.1V314.8c0-10.5 8.5-19.1 19.1-19.1h168.3c10.5 0 19.1 8.5 19.1 19.1v110.7c0 10.6-8.6 19.1-19.1 19.1z" fill="#fff" />
    <path className="st1" fill="#F2BA7C" d="M280.9 472.2l6.6-18.6 6.6-20.6 7-10.8 2.3-16.8L290 400l-14.8 3.9-10.1 21-10.2 34.3z" />
    <path d="M250.6 565.7L174 534.6l-10-82.1c-1.1-12.8 3.2-25.8 11.6-35.4.4-.5 10.8-12.4 22.9-23.2 2.8-2.6 7.2-2.3 9.8.5 2.6 2.8 2.3 7.2-.5 9.8-11.4 10.3-21.6 21.9-21.7 22-6 6.9-9 16-8.3 25.1l8.9 73.6 59 24 20.9-28.4c8.9-12 12.6-27.4 10.3-42.1l-1.7-10.4c-1.5-9.5.5-19.2 5.7-27.3l14.2-22.1c2.3-3.5 1.3-8.3-2.3-10.6-2.5-1.7-5.9-1.1-7.8 1.2l-31.9 43.7c-2.3 3.1-6.6 3.8-9.7 1.5-3.1-2.2-3.8-6.6-1.5-9.7l32.2-44.1c6.4-8 17.6-9.9 26.2-4.3 9.9 6.4 12.7 19.7 6.4 29.6l-14 22.1c-3.3 5.2-4.7 11.5-3.7 17.7l1.7 10.4c2.9 18.4-1.7 37.5-12.8 52.5l-27.3 37.1z" fill="#010202" />
    <path className="st2" fill="none" stroke="#010202" strokeLinecap="round" strokeWidth="12" strokeMiterlimit="10" d="M242.6 505.3s40-2.5 41.2-28.1" />
    <path fill="#026443" d="M254.9 439.3l-21 28.7" />
    <path d="M233.8 474.3c-1.3 0-2.6-.4-3.7-1.2-2.8-2.1-3.4-6-1.4-8.9l17.3-25c2.1-2.8 9.8-7.2 12.6-5.1 2.8 2.1-3.3 15.8-5.3 18.6l-14.3 19c-1.3 1.7-3.2 2.6-5.2 2.6z" fill="#060707" />
    <ellipse className="st8" fill="none" stroke="#000000" strokeWidth="10" strokeMiterlimit="10" cx="366.3" cy="340.8" rx="24.9" ry="24.6" />
    <path className="st8" fill="none" stroke="#000000" strokeWidth="10" strokeMiterlimit="10" d="M401.1 419.7h-69.5v-24.2c0-10.3 8.4-18.7 18.7-18.7h30.6c11.2 0 20.3 9.1 20.3 20.3v22.6z" />
    <path d="M270.3 366.4s-24-10.7-24-24V315h44.3v27.5c0 11.3-20.3 23.9-20.3 23.9z" />
  </svg>

)

export default FabShirtSvg